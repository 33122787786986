import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';

@Injectable()
export class LoginService {
  constructor(private router: Router, private auth: AuthService) {}

  public redirect(path: string) {
    const redirectUrl = this.auth.redirectUrl ? this.auth.redirectUrl : path;
    this.router.navigate([redirectUrl]);
  }
}
