import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { Subscription } from 'rxjs/Subscription';

@Directive({
  selector: '[i18nKey]',
})
export class I18nDirective implements OnInit, OnDestroy {
  @Input('i18nKey') public translationKey: string;
  private languageChangedSubscription: Subscription;

  constructor(private el: ElementRef, @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    this.languageChangedSubscription = this.i18NextService.events.languageChanged.subscribe(e => {
      if (e) {
        this.updateContent();
      }
    });
  }

  public ngOnInit(): void {
    this.updateContent();
  }

  public ngOnDestroy(): void {
    if (this.languageChangedSubscription) {
      this.languageChangedSubscription.unsubscribe();
    }
  }

  private updateContent() {
    this.el.nativeElement.innerHTML = this.i18NextService.t(this.translationKey);
  }
}
