import { Pipe, PipeTransform } from '@angular/core';
import { DEFAULT_PAGE_LIMIT } from '@app/shared/config';
import { Pickup } from '@app/shared/models/pickup';
import { I18NextService } from 'angular-i18next';
import { startCase } from 'lodash';
// tslint:disable: max-classes-per-file

@Pipe({
  name: 'titleCasePipe',
})
export class TitleCasePipe implements PipeTransform {
  public transform(input?: string): string {
    if (!input) {
      return '';
    }
    return startCase(input);
  }
}

@Pipe({
  name: 'pickupStateLabelPipe',
})
export class PickupStateLabelPipe implements PipeTransform {
  public transform(pickup: Pickup | any): string {
    switch (pickup.state) {
      case 'new':
      case 'requested_offer':
      case 'received_offer_list':
      case 'sent_offer':
      case 'prebooked':
      case 'failed_to_board':
      case 'pending':
      case 'blocking_sent_offer':
        return 'label label-warning'; // yellow

      case 'waiting_for_vehicle':
        return 'label label-info'; // light-blue

      case 'completed':
        return 'label label-primary-blue';

      case 'enroute':
      case 'accepted_offer':
        return 'label label-success'; // green

      case 'aborted':
      case 'rejected_offer':
      case 'timed_out':
      case 'cancelled_by_user':
      case 'cancelled_by_operator':
      case 'rejected_by_system':
      case 'rejected_by_user':
      case 'no_offers':
      case 'blocking_no_offers':
      case 'replaced':
        return 'label label-danger'; // red
      default:
        return 'label label-default'; // grey
    }
  }
}

@Pipe({
  name: 'pickupStateTranslate',
})
export class PickupStateTranslatePipe implements PipeTransform {
  constructor(private i18NextService: I18NextService) {}

  public transform(state: string): string {
    return this.i18NextService.t(`pickups.state.${state}`);
  }
}

@Pipe({
  name: 'countPipe',
})
export class CountPipe implements PipeTransform {
  public transform(index: number, page: number = 1, limit: number = DEFAULT_PAGE_LIMIT): number {
    return index + 1 + limit * (page - 1);
  }
}

@Pipe({
  name: 'pbiLabelPipe',
})
export class PbiLabelPipe implements PipeTransform {
  public transform(prebookInstance: any): string {
    if (prebookInstance.is_dismissed) {
      return 'label label-default';
    }

    switch (prebookInstance.state) {
      case 'new':
        return 'label label-default';
      case 'has_offer':
        return 'label label-primary';
      case 'has_offer_messaged':
        return 'label label-warning';
      case 'valid':
      case 'accepted':
        return 'label label-success';
      case 'no_offers':
      case 'no_offer':
      case 'no_offer_messaged':
      case 'rejected':
      case 'cancelled':
      case 'aborted':
        return 'label label-danger';
      default:
        return 'label label-info';
    }
  }
}
