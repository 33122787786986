import { EnhancedWaypoint } from '@app/shared/models/waypoint';
import { Bacchus } from './bacchus';
import { Driver, DriverAttendance, DriverVehicleCost } from './driver';
import { GeofenceZone, Zeus } from './zeus';

export interface DriverBreakStatus {
  driving_duration: number;
  transit_state: string;
  transit_state_start: string;
}

export interface SimpleVehicleModel {
  id: string;
  start_time: string;
  end_time: string;
  driver_name: string;
  service_name: string;
  vehicle_number: string;
  state: string;
  updated_at: string;
}

export enum VehicleState {
  // Equivalent to the 'new' state of ex-prebook vehicle.
  // The vehicle was pre-assigned to a bacchus, but did not go online yet.
  Idle = 'idle',
  // Vehicle has joined a bacchus and is accepting new pickups.
  Available = 'available',
  // Vehicle has not sent updates in a short period and new bookings won't be assigned to vehicle.
  Unavailable = 'unavailable',
  // Vehicle has not sent updates in a long period while and all pickups (including enroute) will be aborted.
  End = 'end',
  Breakdown = 'breakdown', // Vehicle has unplanned downtime.
}

export interface Vehicle {
  id: string;
  registration_number: string;
  make_model: string;
  capacity: number;
  created_at: Date;
  end_time: string;
  location_lat: number;
  location_lon: number;
  location_updated_at: string;
  speed: number;
  routable: boolean;
  pseudo: boolean;
  previous_stop_at: Date;
  algo_state: string;
  taxi_mode: boolean;
  accepting_new_pickups: boolean;
  breakdown: boolean;
  gps_failure: boolean;
  bacchus_id: string;
  hash: string;
  route_id: string;
  route_state: string;
  last_known_route_index: number;
  change_pending_route_id: string;
  change_pending_route_at: Date;
  driver_id: string;
  next_stop_index: number;
  boarded: any;
  missed: any;
  arrived: any;
  reached: any;
  state: VehicleState;
  updated_at: string;
  online_at: string;
  vehicle_source: string;
  simulation_id: string;
  physical_vehicle_id: string;

  cancelled_pax_count: number;
  boarded_pax_count: number;
  missed_pax_count: number;
  arrived_pax_count: number;
  aborted_pax_count: number;
  prebooked_pax_count: number;
  waiting_for_vehicle_pax_count: number;

  // wheelchair counts
  cancelled_wc_count: number;
  boarded_wc_count: number;
  missed_wc_count: number;
  arrived_wc_count: number;
  aborted_wc_count: number;
  prebooked_wc_count: number;
  waiting_for_vehicle_wc_count: number;

  cancelled_adhoc_pax_count: number;
  boarded_adhoc_pax_count: number;
  missed_adhoc_pax_count: number;
  arrived_adhoc_pax_count: number;
  aborted_adhoc_pax_count: number;
  prebooked_adhoc_pax_count: number;
  waiting_for_vehicle_adhoc_pax_count: number;

  // wheelchair counts
  cancelled_adhoc_wc_count: number;
  boarded_adhoc_wc_count: number;
  missed_adhoc_wc_count: number;
  arrived_adhoc_wc_count: number;
  aborted_adhoc_wc_count: number;
  prebooked_adhoc_wc_count: number;
  waiting_for_vehicle_adhoc_wc_count: number;

  seat_count: SeatCount;

  driver: Driver;
  driver_attendance: DriverAttendance;
  erp_cost: DriverVehicleCost;
  estimated_erp_cost: DriverVehicleCost;

  stop_list: StopList;

  driver_break_status: DriverBreakStatus;

  // prebook vehicles field
  start_time: string;
  is_single_trip: boolean;
  is_prebookable: boolean;
  fixed_route_instance: any;
  route: any;
  instance_user: { [key: string]: string }; // key is prebook instance id, value is user id
  user_count: number;
  passenger_count: number;

  // incident management
  breakdown_reason_type: string;
  breakdown_reason_detail: string;
  can_reinstate: boolean;

  // helper field
  driver_name: string;
  service_number: string;
  bacchus: Bacchus;

  // break status helper field
  breakStatusLabel: string;
  breakStatusClassType: string;
  breakStatusTooltip: string;

  settings: {
    zones: GeofenceZone[];
  };
}

export interface VehicleUserStats {
  vehicle_id: string;
  user_count: number;
  passenger_count: number;
}

export interface SimpleVehicleWithPickupStats {
  vehicle_id: string;

  cancelled_pax_count: number;
  boarded_pax_count: number;
  missed_pax_count: number;
  arrived_pax_count: number;
  aborted_pax_count: number;
  prebooked_pax_count: number;
  waiting_for_vehicle_pax_count: number;

  // wheelchair counts
  cancelled_wc_count: number;
  boarded_wc_count: number;
  missed_wc_count: number;
  arrived_wc_count: number;
  aborted_wc_count: number;
  prebooked_wc_count: number;
  waiting_for_vehicle_wc_count: number;

  cancelled_adhoc_pax_count: number;
  boarded_adhoc_pax_count: number;
  missed_adhoc_pax_count: number;
  arrived_adhoc_pax_count: number;
  aborted_adhoc_pax_count: number;
  prebooked_adhoc_pax_count: number;
  waiting_for_vehicle_adhoc_pax_count: number;

  // wheelchair counts
  cancelled_adhoc_wc_count: number;
  boarded_adhoc_wc_count: number;
  missed_adhoc_wc_count: number;
  arrived_adhoc_wc_count: number;
  aborted_adhoc_wc_count: number;
  prebooked_adhoc_wc_count: number;
  waiting_for_vehicle_adhoc_wc_count: number;
}

interface FixedStop {
  id: string;
  stop_id: string;
  time_string: string;
  min_time_window: number;
  max_time_window: number;
  stop_type: string;
  fixed_time: string;
  stop_name: string;
  stop_location_lat: number;
  stop_location_lon: number;
  street_name: string;
  streetview_bearing: number;
  streetview_pitch: number;
  streetview_position_lat: number;
  streetview_position_lon: number;
  streetview_display: boolean;
}

interface SeatCount {
  passenger: number;
  wheelchair: number;
}

interface RecurringScheduleVehicle {
  bus_number: string;
  capacity: SeatCount;
  available_capacity: SeatCount;
}

export interface FixedRoute {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
  zeus_id: string;
  start_area: string;
  end_area: string;
  price: number;
  route_id: string;
  polyline: string;
  fixed_stops_json: string;
  fixed_stops: FixedStop[];
  state: 'Active' | 'Inactive' | 'Archived';
  allow_insert: boolean;
  vehicle_string: string;
  init_stop_id: '';
  recurring_schedule_id: string;
  recurring_schedule_vehicle?: RecurringScheduleVehicle;
}

export interface Route {
  id: string;
  vehicle_id: string;
  bacchus_id: string;
  created_at: Date;
  updated_at: Date;
  completed: boolean;
  state: string;
  encoded_polyline?: string;
  enhanced_waypoints: EnhancedWaypoint[];
}

export interface StopList {
  stops: Stop[];
}

export interface Stop {
  eta: Date;
  pickups: StopInfo[];
  dropoffs: StopInfo[];
}

export interface StopInfo {
  pickup_id: string;
}

export interface VehicleModel {
  id: string;
  name: string;
  lower_capacity: number;
  upper_capacity: number;
  standing_capacity: number;
  wheelchair_capacity: number;
  created_at: Date;
  updated_at: Date;
}

export interface PhysicalVehicle {
  id: string;
  registration_number: string;
  model_id: string;
  model_name: string;
  lower_capacity: number;
  upper_capacity: number;
  standing_capacity: number;
  wheelchair_capacity: number;
  status: string;
  created_at: Date;
  updated_at: Date;
  organization_id: string;
  zeuses: Zeus[];
  zeus_list: string;
}

export interface VehicleSession {
  cancellable: number;
  cancelled: number;
  total: number;
}
