<div *ngIf="session">
  <button class="avatar flex-container dropdown-toggle" [class.small]="showSmallerText()" data-toggle="dropdown">
    {{getShortName(session.name)}}
  </button>

  <ul class="dropdown-menu">
    <li>
      <span class="full-name">
        {{getFullName(session.name) | titleCasePipe}}
      </span>
      <span class="email">
        {{session.email}}
      </span>
    </li>
    <li role="separator" class="divider"></li>
    <li>
      <a href="/v2/account_settings" class="primary" i18nKey="menu.account_settings"></a>
    </li>
    <li>
      <a class="primary" (click)="logout()" i18nKey="menu.logout_no_name"></a>
    </li>
  </ul>
</div>