import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { ScopeService } from '@app/core/services/scope.service';
import { UserService } from '@app/core/services/user.service';
import { Bacchus } from '@app/shared/models/bacchus';
import { Collection } from '@app/shared/models/collection';
import { Zeus } from '@app/shared/models/zeus';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class ZeusService {
  private endpoints = {
    zeuses: `${this.userService.baseUrl()}/zeuses`,
    zeus: (id: string) => `${this.userService.baseUrl()}/zeuses/${id}`,
    bacchi: (tab: string) => `${this.userService.baseUrl()}/bacchi/${tab}`,
    bacchiByZeusId: `${this.userService.baseUrl()}/bacchi/current`,
    bacchusByID: (bacchusID: string) => `${this.userService.baseUrl()}/bacchi/${bacchusID}`,
    liveBacchi: `${this.userService.baseUrl()}/bacchi/live`,
  };
  constructor(private apiService: APIService, private userService: UserService, private scopeService: ScopeService) {}

  public getAllZeuses(): Observable<Zeus[]> {
    const params = { all: true, ...this.scopeService.projectScopeParam };
    return this.apiService.get(this.endpoints.zeuses, params).pipe(
      map((response: any) => {
        const zeuses: Zeus[] = response.zeuses.map((zeus: Zeus) => {
          zeus.settings = JSON.parse(zeus.settings_json);
          zeus.clientSettings = JSON.parse(zeus.client_settings);
          return zeus;
        });
        return zeuses;
      }),
    );
  }

  public getZeusesByOrganizationId(organizationID: string): Observable<Zeus[]> {
    const param = { organization_id: organizationID };
    return this.apiService.get(this.endpoints.zeuses, param).pipe(
      map((response: any) => {
        const zeuses: Zeus[] = response.zeuses.map((zeus: Zeus) => {
          zeus.settings = JSON.parse(zeus.settings_json);
          zeus.clientSettings = JSON.parse(zeus.client_settings);
          return zeus;
        });
        return zeuses;
      }),
    );
  }

  public getZeusByID(id: string) {
    return this.apiService.get(this.endpoints.zeus(id)).pipe(
      map(response => {
        const zeus: Zeus = response.zeus;
        zeus.settings = JSON.parse(zeus.settings_json);
        zeus.clientSettings = JSON.parse(zeus.client_settings);
        return zeus;
      }),
    );
  }

  public getBacchiByTab(tab: string, zeusIDs: string[], limit: number, page: number) {
    const params = {
      l: limit,
      p: page,
      zeus_ids: zeusIDs.join(','),
      ...this.scopeService.projectScopeParam,
    };

    return this.apiService.get(this.endpoints.bacchi(tab), params).pipe(
      map((response: any) => {
        const collection = new Collection<Bacchus>();
        const bacchi = response.bacchi as Bacchus[];

        bacchi.forEach(b => {
          if (b.settings_json) {
            b.settings = JSON.parse(b.settings_json);
          }
        });

        collection.items = bacchi;
        collection.count = response.bacchi_meta.count;
        collection.limit = response.bacchi_meta.limit;
        collection.page = response.bacchi_meta.page;
        return collection;
      }),
    );
  }

  public getBacchiByZeusID(zeusID: string) {
    const params = { l: 300, p: 1, zeus_ids: zeusID, ...this.scopeService.projectScopeParam };
    return this.apiService.get(this.endpoints.bacchiByZeusId, params).pipe(
      map((response: any) => {
        const bacchi = response.bacchi as Bacchus[];

        bacchi.forEach(b => {
          if (b.settings_json) {
            b.settings = JSON.parse(b.settings_json);
          }
        });

        return bacchi;
      }),
    );
  }

  public updateBacchus(bacchusId: string, body: object) {
    return this.apiService.post(this.endpoints.bacchusByID(bacchusId), undefined, body);
  }

  public getLiveBacchi() {
    return this.apiService.get(this.endpoints.liveBacchi).pipe(
      map((response: any) => {
        const bacchi = response.bacchi as Bacchus[];
        return bacchi.map((bacchus: Bacchus) => {
          bacchus.settings = JSON.parse(bacchus.settings_json);
          bacchus.clientSettings = JSON.parse(bacchus.client_settings);
          return bacchus;
        });
      }),
    );
  }

  public getBacchusById(id: string) {
    return this.apiService.get(this.endpoints.bacchusByID(id)).pipe(
      map((response: any) => {
        const bacchus = response.bacchus as Bacchus;

        try {
          bacchus.settings = bacchus.settings_json ? JSON.parse(bacchus.settings_json) : {};
          bacchus.clientSettings = bacchus.client_settings ? JSON.parse(bacchus.client_settings) : {};
        } catch {
          bacchus.settings = bacchus.settings || ({} as any);
          bacchus.clientSettings = bacchus.clientSettings || ({} as any);
        }

        return bacchus;
      }),
    );
  }
}
