import { Injectable } from '@angular/core';

declare var mapboxgl: any;

const MAPBOX_TOKEN = 'pk.eyJ1IjoibmphY2hvd3NraSIsImEiOiJjamxnN2NvY3EwOGloM3BvMHR2amNxaXZiIn0.8O36Vx3I4pL7McyZIs_Gww';

@Injectable()
export class MapboxService {
  public styleURL = 'mapbox://styles/njachowski/clfm9j2js001601plrti3r3ix';

  constructor() {
    if (typeof mapboxgl === 'undefined') {
      (window as any).mapboxgl = undefined;
      return;
    }

    mapboxgl.accessToken = MAPBOX_TOKEN;
  }

  public setToken() {
    if (mapboxgl) {
      mapboxgl.accessToken = MAPBOX_TOKEN;
    }
  }
}
