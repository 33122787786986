import { EventEmitter, Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { Bacchus } from '@app/shared/models/bacchus';
import { Collection } from '@app/shared/models/collection';
import { Pickup, PickupRequest } from '@app/shared/models/pickup';
import { SortDirection } from '@app/shared/models/sort-direction';
import { Vehicle } from '@app/shared/models/vehicle';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';

@Injectable()
export class PickupService {
  public onPickUpUpdated = new EventEmitter<Pickup>();
  public onPickupCacheUpdated = new EventEmitter();
  public onPickupCollectionUpdated = new EventEmitter<Collection<Pickup>>();
  public onShouldRefreshLivePickupCollection = new EventEmitter();
  public livePickupCache: { [key: string]: Pickup } = {}; // the key is the pickup Id;

  private pickupUpdateWS: WebSocketSubject<Pickup>;
  private pickupUpdateWSSub: Subscription;

  private readonly endpoints = {
    livePickups: `${this.userService.baseUrl()}/pickups/live`,
    tripDetails: `${this.userService.baseUrl()}/booking/trip_details`,
    pickupByID: (id: string) => `${this.userService.baseUrl()}/pickups/${id}`,
    pickupsForUser: (userID: string) => `${this.userService.baseUrl()}/users/${userID}/pickups`,
    userByID: (id: string) => `${this.userService.baseUrl()}/users/${id}`,
    liveBacchi: `${this.userService.baseUrl()}/bacchi/live`,
    bookings: `${this.userService.baseUrl()}/bookings`,
    bookingById: (id: string) => `${this.userService.baseUrl()}/bookings/${id}`,
    cancelBooking: (id: string) => `${this.userService.baseUrl()}/bookings/${id}/cancel`,
    bulkCancel: `${this.userService.baseUrl()}/bookings/bulk_cancel`,
  };

  constructor(private userService: UserService, private apiService: APIService) {
    this.initialize();
  }

  public ngOnDestroy(): void {
    this.pickupUpdateWS?.complete();
    this.pickupUpdateWSSub?.unsubscribe();
  }

  public getLivePickupsCollection(
    page: number,
    limit: number,
    bacchusIds: string[],
    states: string[],
    userIDs: string[],
    column = '',
    order = SortDirection.NONE,
  ) {
    const params: { [key: string]: string | number } = {
      p: page,
      l: limit,
      column,
      order,
    };
    if (bacchusIds && bacchusIds.length > 0) {
      params.bacchus_ids = bacchusIds.join(',');
    }

    if (states && states.length > 0) {
      params.states = states.join(',');
    }

    if (userIDs && userIDs.length > 0) {
      params.user_ids = userIDs.join(',');
    }

    this.apiService.get(this.endpoints.livePickups, params).subscribe((response: any) => {
      const collection = new Collection();
      const pickups: Pickup[] = response.pickups;

      if (!pickups || pickups.length === 0) {
        this.onPickupCollectionUpdated.emit(collection);
        return;
      }

      for (const pickup of pickups) {
        this.preserveLivePickupDropoffTimes(pickup);
        this.livePickupCache[pickup.id] = pickup;
      }

      collection.items = pickups;
      collection.count = response.pickups_meta.count;
      collection.limit = response.pickups_meta.limit;
      collection.page = response.pickups_meta.page;
      collection.q = '';
      this.onPickupCollectionUpdated.emit(collection);
    });
  }

  public getPickup(id: string): Observable<Pickup> {
    return this.apiService.get(this.endpoints.pickupByID(id)).pipe(map(response => response.pickup as Pickup));
  }

  public updatePickupCacheWithPickup(pickup: Pickup) {
    if (pickup == null) {
      return;
    }
    // New incoming pickup state change
    if (!(pickup.id in this.livePickupCache)) {
      if (pickup.state === 'waiting_for_vehicle') {
        // Actual new live pickup
        this.getPickup(pickup.id).subscribe((p: Pickup) => {
          this.livePickupCache[p.id] = p;
          this.onShouldRefreshLivePickupCollection.emit();
        });
      }
    } else {
      // existing live pickup
      const pickupInCache = this.livePickupCache[pickup.id];
      pickupInCache.remarks = pickup.remarks;
      if (pickup.state === 'waiting_for_vehicle' || pickup.state === 'enroute') {
        pickupInCache.board_time = pickup.board_time;
        pickupInCache.dropoff_time = pickup.dropoff_time;
        pickupInCache.state = pickup.state;
        pickupInCache.adhoc_demand = pickup.adhoc_demand;
        pickupInCache.demand = pickup.demand;

        this.livePickupCache[pickup.id] = pickupInCache;
        this.onPickupCacheUpdated.emit();
      } else {
        // Pickup reaches end state (state would no longer change anymore)
        delete this.livePickupCache[pickup.id];
        this.onShouldRefreshLivePickupCollection.emit();
      }
    }
  }

  public updatePickupCacheWithVehicles(vehicles: Vehicle[]) {
    if (vehicles == null || vehicles.length === 0) {
      return;
    }
    // update the live pickups
    let updated = false;

    for (const vehicle of vehicles) {
      if (vehicle.stop_list == null || vehicle.stop_list.stops == null || vehicle.stop_list.stops.length === 0) {
        continue;
      }

      for (const stop of vehicle.stop_list.stops) {
        if (stop.pickups != null && stop.pickups.length > 0) {
          for (const pickup of stop.pickups) {
            if (pickup.pickup_id in this.livePickupCache) {
              if (this.livePickupCache[pickup.pickup_id].live_pickup_time !== stop.eta) {
                this.livePickupCache[pickup.pickup_id].live_pickup_time = stop.eta;
                updated = true;
              }
            }
          }
        }
        if (stop.dropoffs != null && stop.dropoffs.length > 0) {
          for (const dropoff of stop.dropoffs) {
            if (dropoff.pickup_id in this.livePickupCache) {
              if (this.livePickupCache[dropoff.pickup_id].live_dropoff_time !== stop.eta) {
                this.livePickupCache[dropoff.pickup_id].live_dropoff_time = stop.eta;
                updated = true;
              }
            }
          }
        }
      }
    }

    if (updated) {
      this.onPickupCacheUpdated.emit();
    }
  }

  public updatePickupCollectionWithPickupCache(collection: Collection<Pickup>) {
    if (!collection || !collection.items || collection.items.length === 0) {
      return;
    }

    for (let i = 0; i < collection.items.length; i = i + 1) {
      const pickupId = collection.items[i].id;
      if (pickupId in this.livePickupCache) {
        collection.items[i] = this.livePickupCache[pickupId];
      }
    }
  }

  public preserveLivePickupDropoffTimes(pickup: Pickup) {
    if (this.livePickupCache == null) {
      return;
    }

    if (pickup.id in this.livePickupCache) {
      pickup.live_pickup_time = this.livePickupCache[pickup.id].live_pickup_time;
      pickup.live_dropoff_time = this.livePickupCache[pickup.id].live_dropoff_time;
    }
  }

  public createPickup(userID: string, body: string) {
    return this.apiService.post(this.endpoints.pickupsForUser(userID), undefined, body);
  }

  public getUser(id: string) {
    return this.apiService.get(this.endpoints.userByID(id)).pipe(map(response => response.user));
  }

  public getLiveBacchi(): Observable<Bacchus[]> {
    return this.apiService.get(this.endpoints.liveBacchi).pipe(
      map((response: any) => {
        const bacchi: Bacchus[] = response.bacchi;
        return bacchi.map((bacchus: Bacchus) => {
          bacchus.settings = JSON.parse(bacchus.settings_json);
          bacchus.clientSettings = JSON.parse(bacchus.client_settings);
          return bacchus;
        });
      }),
    );
  }

  public getUserPickups(userID: string, bacchusIDs: string[], states: string[], limit: number): Observable<Pickup[]> {
    const params = { states, limit, bacchus_ids: bacchusIDs };
    return this.apiService
      .get(this.endpoints.pickupsForUser(userID), params)
      .pipe(map(response => response.pickups as Pickup[]));
  }

  public book(body: PickupRequest): Observable<Pickup> {
    return this.apiService
      .post(this.endpoints.bookings, undefined, body, undefined, '', false)
      .pipe(map(response => response.pickup as Pickup));
  }

  public updateRemark(bookingId: string, remarks: string) {
    return this.apiService
      .put(this.endpoints.bookingById(bookingId), undefined, { pickup_data: { remarks } })
      .pipe(map(response => response.booking as Pickup));
  }

  public cancel(pickupID: string) {
    return this.apiService.put(this.endpoints.cancelBooking(pickupID));
  }

  public bulkCancel(bookingIDs: string[]) {
    return this.apiService.post(this.endpoints.bulkCancel, undefined, { booking_ids: bookingIDs });
  }

  private async initialize() {
    this.pickupUpdateWS = await this.userService.createWebsocket<Pickup>('v1.eros.*.booking.update.full');
    this.pickupUpdateWSSub = this.pickupUpdateWS.subscribe(pickup => {
      this.updatePickupCacheWithPickup(pickup);
      this.onPickUpUpdated.emit(pickup);
    });
  }
}
