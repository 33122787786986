import { Inject, Injectable, InjectionToken } from '@angular/core';

// tslint:disable-next-line: interface-name
export interface FaviconsConfig {
  icons: IconsConfig;
  cacheBusting?: boolean;
}

export interface IconsConfig {
  [name: string]: IconConfig;
}

export interface IconConfig {
  type: string;
  href: string;
  isDefault?: boolean;
}

export const BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>('Favicons Configuration');
export abstract class Favicons {
  public abstract activate(name: string): void;
  public abstract reset(): void;
}

// I provide the browser-oriented implementation of the Favicons class.
// tslint:disable-next-line: max-classes-per-file
@Injectable()
export class BrowserFavicons implements Favicons {
  private elementID: string;
  private icons: IconsConfig;
  private useCacheBusting: boolean;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
    this.elementID = 'favicons-service-injected-node';
    this.icons = Object.assign(Object.create(null), config.icons);
    this.useCacheBusting = config.cacheBusting || false;
    this.removeExternalLinkElements();
  }

  public activate(name: string): void {
    if (!this.icons[name]) {
      throw new Error(`Favicon for [${name}] not found.`);
    }
    this.setNode(this.icons[name].type, this.icons[name].href);
  }

  public reset(): void {
    for (const name of Object.keys(this.icons)) {
      const icon = this.icons[name];
      if (icon.isDefault) {
        this.setNode(icon.type, icon.href);
        return;
      }
    }

    this.removeNode();
  }

  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementID);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }

  private cacheBustHref(href: string): string {
    const augmentedHref =
      href.indexOf('?') === -1 ? `${href}?faviconCacheBust=${Date.now()}` : `${href}&faviconCacheBust=${Date.now()}`;
    return augmentedHref;
  }

  private removeExternalLinkElements(): void {
    const linkElements = document.querySelectorAll("link[rel ~= 'icon' i]");
    for (const linkElement of Array.from(linkElements)) {
      linkElement.parentNode.removeChild(linkElement);
    }
  }

  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementID);
    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private setNode(type: string, href: string): void {
    const augmentedHref = this.useCacheBusting ? this.cacheBustHref(href) : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}
