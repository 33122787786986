import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService, Flag } from '@app/core/services/feature-flag.service';

// This should be used together with *flag to hide legacy and show new item
@Directive({ selector: '[legacy-flag]' })
export class LegacyFlagDirective implements OnInit {
  @Input('legacy-flag') public legacyFlag: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}

  public ngOnInit(): void {
    if (!this.featureFlagService.isEnabled((this.legacyFlag as any) as Flag)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
