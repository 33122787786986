import { HttpErrorResponse } from '@angular/common/http';
import { generateListHTML } from '@app/shared/helpers/textHelper';

interface BulkBulkingError {
  error: string;
  fix?: string;
}

export class ErrorMessages {
  public static STATUS_ERROR_MESSAGES = {
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    422: 'Can Not Process',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
  };

  public static LAKHESIS_ERROR_MESSAGES = {
    1001: 'Saving User Info Failed',
    1007: 'Invalid Coupon',
    1010: 'Coupon does not exist',
    1011: 'Database Error',
    1014: 'Pickup Request Not In Available Geofence',
    1035: 'Overlapping Booking Error',
    1040: 'Departure Time Is Out Of Operating Hours',
    1041: 'Destination Time Is Out Of Operating Hours',
    1042: 'Departure Time Is In The Past',
    1043: 'Code Departure Time Not In Shift',
    1047: 'Code Destination Time Is Invalid',
    1050: 'Force Route Change Failed',
    1051: 'Force Route Change Update Failed',
    1053: 'Validation Error',
    1060: 'Pickup Action Failed',
    1100: 'Invalid Or Missing User Session ',
    1101: 'JWT expired',
    1200: 'External Service Failed',
    1300: 'Road Closure Service Failed',
    1500: 'Inactive Account',
    1501: 'Locked Account',
    1502: 'Password Expired',
    1505: 'Password Was Recently Used',
    1506: 'Password Is Too Weak',
    1507: 'Password Contains User Info',
    1508: 'User Or Driver Not Found',
    1510: 'Invalid Reset Password Token',
    1511: 'Expired Password Token',
    1512: 'Too Many OTP Requests',
    1513: 'Existing User Name Or Email',
    1517: 'Payment Fail',
    1518: 'Vehicle Capacity',
    1519: 'Unauthorized Request',
    1520: 'Invalid Claims From JWT',
    1521: 'Limit Pickup Dropoff',
    1522: 'Found Upcoming Pickups',
    1523: 'Invalid Card Details',
    1524: 'OSRM Service Fail',

    2600: 'Passenger Schedule Not Found',
    2601: 'Passenger Schedule Invalid State Transition',

    1700: 'Cannot decode user input',
    2501: 'Negative Route Capacity',

    2800: 'This job is duplicated. Can not run another job!',
  };

  public static MANAGE_ERROR_MESSAGES = {
    2000: 'Email does not exist',
    2008: 'Password must be reset',
    2009: 'Invalid email or password',
    2005: 'Incorrect login credentials',
    4001: 'Invitation link has expired',
    4002: 'Invitation link has been used',
    4003: 'Invalid invitation link',
    16000: 'Import is forbidden for this organization',
    17000: 'The password reset token is expired',
  };

  public static readonly UNKNOWN_ERROR_MESSAGE = 'An unknown error has occurred';

  public static BULK_BOOKING_ERROR_MESSAGES: Record<number, BulkBulkingError> = {
    10000: { error: 'Unable to proceed with import', fix: 'Please contact SWAT support team.' },
    10001: {
      error: '<b>Employee ID</b> not found',
      fix: 'Please check if <b>Employee ID</b> has been whitelisted or if you have entered the correct Employee ID',
    },
    10002: {
      error: '<b>Booking mode</b> not supported at the moment',
      fix: 'Please contact SWAT support team.',
    },
    10003: {
      error: '<b>Pick up</b> or <b>Drop off</b> time specified is in the wrong format',
      fix: 'Please ensure that pick up or drop off time is in the correct format <b>HH:MM</b> (24 hour time format)',
    },
    10004: { error: 'Invalid <b>start_date</b>', fix: 'Enter a valid <b>start_date</b>. Example: 20/10/2019' },
    10005: { error: 'Invalid <b>end_date</b>', fix: 'Enter a valid <b>end_date</b>. Example: 20/10/2019' },
    10007: {
      error: 'Passenger account for the following employee ID has not been created',
      fix: 'Please check if Employee ID has been whitelisted with create_account_on_import option set',
    },
    10008: {
      error: 'There is an existing booking made by passenger from their SWAT Commuter App',
      fix: 'Please contact SWAT support team.',
    },
    10009: {
      error: 'Unable to match <b>pick up location</b> specified to an existing stop',
      fix: 'Please ensure that pick up location name matches name of an existing stop',
    },
    10010: {
      error: 'Unable to match <b>drop off location</b> specified to an existing stop',
      fix: 'Please ensure that drop off location name matches name of an existing stop',
    },
    10011: {
      error: 'Unable to book passenger onto <b>route_number</b>',
      fix: 'Please ensure that route number is associated with the schedule',
    },
  };
}

export enum ErrorCodes {
  VALIDATION = 1053,
  MUST_RESET_PASSWORD = 2008,
}

// tslint:disable-next-line: max-classes-per-file
export class ErrorHelper {
  public static isLakhesisError(err: any): err is LakhesisError {
    return err.status === 'error' || err.status === 'fail';
  }

  public static isManageError(err: any): err is ManageError {
    return 'app' in err;
  }

  public static getLakhesisErrorMessage(error: LakhesisError) {
    const code = error.code;
    if (code === -1) {
      const keys = Object.keys(error.data);
      if (keys && keys.length > 0) {
        return error.data[keys[0]];
      }
    }

    if (error.message) {
      return error.message;
    }

    return ErrorMessages.LAKHESIS_ERROR_MESSAGES[code] || ErrorMessages.UNKNOWN_ERROR_MESSAGE;
  }

  public static getManageErrorMessage(error: ManageError) {
    if (error.validation) {
      try {
        const validationErrors: string[] = [];
        for (const key of Object.keys(error.validation)) {
          for (const validationError of error.validation[key]) {
            validationErrors.push(`${key} ${validationError}`);
          }
        }

        return generateListHTML(validationErrors);
      } catch {
        return `<code>${JSON.stringify(error.validation)}</code>`;
      }
    }

    return error.message || ErrorMessages.MANAGE_ERROR_MESSAGES[error.code] || ErrorMessages.UNKNOWN_ERROR_MESSAGE;
  }

  public static getSimpleError(error: HttpErrorResponse): SimpleError {
    const errorTitle = ErrorMessages.STATUS_ERROR_MESSAGES[error.status];

    const innerError = error.error;
    const simpleError: SimpleError = {
      title: errorTitle,
      code: 0,
      message: ErrorMessages.UNKNOWN_ERROR_MESSAGE,
      source: 'unknown',
    };

    if (!innerError) {
      simpleError.message = ErrorMessages.UNKNOWN_ERROR_MESSAGE;
    } else if (typeof innerError === 'string') {
      simpleError.message = innerError;
    } else if (ErrorHelper.isLakhesisError(innerError)) {
      simpleError.message = ErrorHelper.getLakhesisErrorMessage(innerError);
      simpleError.code = innerError.code;
      simpleError.data = innerError.data;
      simpleError.source = 'lakhesis';
    } else if (ErrorHelper.isManageError(innerError)) {
      simpleError.message = ErrorHelper.getManageErrorMessage(innerError);
      simpleError.code = innerError.code;
      simpleError.source = 'manage';
    } else {
      // Unknown error from unknown api server
      simpleError.message = ErrorMessages.UNKNOWN_ERROR_MESSAGE;
    }

    if (simpleError.code) {
      if (simpleError.source === 'manage' && ErrorMessages.MANAGE_ERROR_MESSAGES[simpleError.code]) {
        simpleError.title = ErrorMessages.MANAGE_ERROR_MESSAGES[simpleError.code];
      } else if (simpleError.source === 'lakhesis' && ErrorMessages.LAKHESIS_ERROR_MESSAGES[simpleError.code]) {
        simpleError.title = ErrorMessages.LAKHESIS_ERROR_MESSAGES[simpleError.code];
      } else {
        simpleError.title = `${simpleError.title} (Error Code: ${simpleError.code})`;
      }
    }

    return simpleError;
  }
}

export interface LakhesisError {
  data: { [key: string]: any };
  code: number;
  message: string;
  status: 'error' | 'fail';
}

export interface ManageError {
  app: string;
  code: number;
  message?: string;
  validation: Record<string, string[]>;
}

export interface SimpleError {
  title: string;
  message: string;
  code: number;
  source: 'unknown' | 'lakhesis' | 'manage';
  data?: { [key: string]: any };
  formattedError?: string;
}

export interface ServiceError {
  error: HttpErrorResponse;
  simpleError?: SimpleError;
}
