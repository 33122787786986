<div class="container-fluid">
  <div class="register-panel">
    <div class="form-container">
      <h4 class="title" i18nKey="button.register"></h4>
      <div>
        <form [formGroup]="registerForm" class="form-group">
          <div class="row form-row">
            <label for="email" class="control-label" i18nKey="label.email"></label>
            <input formControlName="email" type="text" id="email" class="form-control">
          </div>
          <div class="row form-row">
            <label for="firstName" class="control-label" i18nKey="label.first_name"></label>
            <input formControlName="firstName" type="text" id="firstName" class="form-control">
          </div>
          <div class="row form-row">
            <label for="lastName" class="control-label" i18nKey="label.last_name"></label>
            <input formControlName="lastName" type="text" id="lastName" class="form-control">
          </div>
          <div class="row form-row">
            <label for="language" class="control-label" i18nKey="label.language"></label>
            <select formControlName="language" id="language" class="form-control">
              <option *ngFor="let language of languages" [ngValue]="language">
                {{ language.name }}
              </option>
            </select>
          </div>
          <div class="row form-row">
            <label for="password" class="control-label" i18nKey="label.password"></label>
            <input formControlName="password" type="password" id="password" class="form-control">
          </div>
          <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)"
            class="password-validation-alerts alert alert-danger">
            <div *ngIf="!passwordControl.value || passwordControl.errors.minlength">
              {{ 'validation.password.min_length' | i18next: { num: PASSWORD_MIN_LENGTH } }}
            </div>
          </div>
          <div class="row form-row">
            <label for="confirm" class="control-label" i18nKey="label.confirm_password"></label>
            <input formControlName="confirm" type="password" id="confirm" class="form-control">
          </div>
          <div *ngIf="passwordDoesNotMatch()" class="row form-row alert alert-danger">
            {{ 'validation.password.not_match' | i18next }}
          </div>
          <div class="row form-row pull-right">
            <button class="btn btn-md btn-primary" (click)="register()" [disabled]="isInvalid()"
              i18nKey="button.register">
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>