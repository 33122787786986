<div class="organization-switch__container" *ngIf="canView()">
  <div class="divider"></div>
  <div class="selector__container">
    <span class="selector__label" i18nKey="label.workspace"></span>

    <ng-select [items]="workspaces" class="workspace-select" [(ngModel)]="selectedWorkspace" [clearable]="false"
      notFoundText="{{'label.no_result' | i18next}}" [searchFn]="searchWorkspace" groupBy="groupTerm"
      (change)="selectWorkspace($event)">
      <ng-template ng-optgroup-tmp let-item="item">
        <span *ngIf="item.groupTerm !== PLACEHOLDER_GROUP_TERM" class="workspace-group">{{item.groupTerm}}</span>
      </ng-template>
      <ng-template ng-label-tmp let-item="item">
        <span>{{item.organizationName}} - {{item.projectName}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span title="{{item.organizationName}} - {{item.projectName}}"
          [class.group-item]="item.groupTerm === PLACEHOLDER_GROUP_TERM"
          *ngIf="item.groupTerm === PLACEHOLDER_GROUP_TERM">
          {{item.organizationName}} - {{item.projectName}}
        </span>
        <span *ngIf="item.groupTerm !== PLACEHOLDER_GROUP_TERM">{{item.projectName}}</span>
      </ng-template>
    </ng-select>
  </div>
</div>