import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { LanguageService } from '@app/core/services/language.service';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ManageLoginService } from '@app/login/services/manage-login.service';
import { PASSWORD_MIN_LENGTH } from '@app/shared/config';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'manage-register',
  templateUrl: './manage-register.component.html',
  styleUrls: ['./manage-register.component.scss'],
})
export class ManageRegisterComponent {
  public languages = this.languageService.getAllSupportedLanguages();

  public registerForm: FormGroup;
  public firstNameControl: FormControl;
  public lastNameControl: FormControl;
  public languageControl: FormControl;
  public passwordControl: FormControl;
  public confirmControl: FormControl;
  public emailControl: FormControl;

  public isRegistering: boolean;
  public invitationToken: string;
  public PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;

  constructor(
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private loginService: ManageLoginService,
    private authService: ManageAuthService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.invitationToken = params.get('token');
    });
  }

  public ngOnInit(): void {
    this.setupForm();
  }

  public setupForm() {
    this.firstNameControl = new FormControl('' as string, [Validators.required]);
    this.lastNameControl = new FormControl('' as string, [Validators.required]);
    this.languageControl = new FormControl([Validators.required]);
    this.passwordControl = new FormControl('', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]);
    this.confirmControl = new FormControl('', [Validators.required]);
    this.emailControl = new FormControl(this.loginService.email, [Validators.required, Validators.email]);
    this.registerForm = new FormGroup(
      {
        firstName: this.firstNameControl,
        lastName: this.lastNameControl,
        language: this.languageControl,
        password: this.passwordControl,
        confirm: this.confirmControl,
        email: this.emailControl,
      },
      [this.confirmPasswordValidator()],
    );

    this.languageControl.valueChanges.subscribe(l => this.i18NextService.changeLanguage(l.value));
    this.languageControl.setValue(this.languages[0]);
  }

  public confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.get('password').value;
      const confirm = control.get('confirm').value;
      const match = password === confirm ? password !== '' && password !== null : false;
      return match ? null : { confirm: 'not match' };
    };
  }

  public async register() {
    this.isRegistering = true;

    try {
      const session = await this.loginService
        .registerOperator(
          this.invitationToken,
          this.emailControl.value,
          this.firstNameControl.value,
          this.lastNameControl.value,
          this.languageControl.value.value,
          this.passwordControl.value,
          this.confirmControl.value,
        )
        .toPromise();

      this.authService.saveUserSession(session);
      location.replace('/services'); // Hard refresh after register to display tabs properly
    } finally {
      this.isRegistering = false;
    }
  }

  public isInvalid(): boolean {
    return this.emailControl.invalid || this.passwordControl.invalid || this.isRegistering;
  }

  public passwordDoesNotMatch() {
    return (
      this.passwordControl.dirty &&
      this.confirmControl.dirty &&
      this.registerForm.errors &&
      this.registerForm.errors.confirm
    );
  }
}
