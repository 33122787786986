import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ScopeService } from '@app/core/services/scope.service';
import { Organization, Project } from '@app/shared/models/manage-scope';
import { groupBy, keyBy, sortBy } from 'lodash';

@Component({
  selector: 'workspace-switch',
  templateUrl: './workspace-switch.component.html',
  styleUrls: ['./workspace-switch.component.scss'],
})
export class WorkspaceSwitchComponent implements OnInit, AfterViewInit {
  public workspaces: Workspace[] = [];
  public selectedWorkspace: Workspace;

  // HACK: Fix bug OPSPROD-575 when group-term is undefined
  public PLACEHOLDER_GROUP_TERM = '_';

  private ORGANIZATION_ID_STORAGE_KEY = 'organizationId';
  private PROJECT_ID_STORAGE_KEY = 'projectId';
  private organizations: Organization[] = [];
  private projects: Project[] = [];

  constructor(
    private scopeService: ScopeService,
    private manageAuthService: ManageAuthService,
    private router: Router,
  ) {}

  public async ngOnInit() {
    await this.manageAuthService.sessionInitialized$.toPromise();

    const results = await Promise.all([
      this.manageAuthService.getScopes(),
      this.scopeService.organizations.toPromise(),
      this.scopeService.projects.toPromise(),
    ]);

    const allScopes = results[0];
    this.organizations = results[1];
    this.projects = results[2];

    const organizations = allScopes.filter(scope => scope.type === 'organization');
    const organizationMap = keyBy(organizations, org => org.id);
    const projects = allScopes.filter(scope => scope.type === 'project');

    for (const project of projects) {
      for (const organizationId of project.organization_ids) {
        const organization = organizationMap[organizationId];
        if (organization) {
          this.workspaces.push({
            organizationId: organization.id,
            organizationName: organization.name,
            projectId: project.id,
            projectName: project.name,
            groupTerm: this.PLACEHOLDER_GROUP_TERM,
          });
        }
      }
    }

    // Workaround to show workspaces with grouped organizations
    const groups = Object.entries(groupBy(this.workspaces, w => w.organizationName));
    for (const [organizationName, workspaces] of groups) {
      if (workspaces.length > 1) {
        for (const workspace of workspaces) {
          workspace.groupTerm = organizationName;
        }
      }
    }

    this.workspaces = sortBy(
      this.workspaces,
      w => w.organizationName.toLowerCase(),
      w => w.projectName.toLowerCase(),
    );

    if (this.workspaces.length > 0) {
      const urlParams = new URLSearchParams(window.location.search);
      const organizationId =
        urlParams.get(this.ORGANIZATION_ID_STORAGE_KEY) || localStorage.getItem(this.ORGANIZATION_ID_STORAGE_KEY);
      const projectId = urlParams.get(this.PROJECT_ID_STORAGE_KEY) || localStorage.getItem(this.PROJECT_ID_STORAGE_KEY);
      const workspace =
        this.workspaces.find(w => w.organizationId === organizationId && w.projectId === projectId) ||
        // sometimes, the url comes from systems that only have user project, select first one possible
        this.workspaces.find(w => w.projectId === projectId) ||
        this.workspaces[0];
      this.selectWorkspace(workspace, true);
    }
  }

  // Very ugly hack to disable auto complete
  // Wrapping whole component in <form> will make data-binding not working
  public ngAfterViewInit(): void {
    setTimeout(() => {
      const inputContainer = document.querySelector('div.ng-input');
      if (!inputContainer) {
        return;
      }
      const inputNode = inputContainer.childNodes[0];

      const form = document.createElement('form');
      form.setAttribute('autocomplete', 'off');
      form.classList.add('ng-input');
      form.appendChild(inputNode);

      inputContainer.append(form);
    }, 500);
  }

  public selectWorkspace(workspace: Workspace, withTimeout = false) {
    this.selectedWorkspace = workspace;

    const { organizationId, projectId } = workspace;
    localStorage.setItem(this.ORGANIZATION_ID_STORAGE_KEY, organizationId);
    localStorage.setItem(this.PROJECT_ID_STORAGE_KEY, projectId);

    // Sometimes the router is not ready to navigate, it might cause race condition bug
    // So we need to make artificial timeout of 500ms
    const waitTime = withTimeout ? 500 : 0;
    setTimeout(async () => {
      await this.manageAuthService.switchOrganization(organizationId);

      this.scopeService.organization = this.organizations.find(o => o.id === organizationId);
      this.scopeService.project = this.projects.find(p => p.id === projectId);

      this.router.navigate([], {
        queryParams: { [this.ORGANIZATION_ID_STORAGE_KEY]: organizationId, [this.PROJECT_ID_STORAGE_KEY]: projectId },
        queryParamsHandling: 'merge',
      });
    }, waitTime);
  }

  public canView() {
    if (!this.manageAuthService.isAuthenticated) {
      return false;
    }

    if (this.organizations.length < 2 && this.projects.length < 2) {
      return false;
    }

    return true;
  }

  public searchWorkspace(term: string, workspace: Workspace) {
    const lowercaseTerm = term.toLowerCase();
    return (
      workspace.organizationName.toLowerCase().includes(lowercaseTerm) ||
      workspace.projectName.toLowerCase().includes(lowercaseTerm)
    );
  }
}

interface Workspace {
  organizationId: string;
  organizationName: string;
  projectId: string;
  projectName: string;
  groupTerm: string;
}
