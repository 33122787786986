import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ScopeService } from '@app/core/services/scope.service';
import { Project } from '@app/shared/models/manage-scope';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements OnInit, OnDestroy {
  @Input('permM') public managePerms: string[] = [];
  @Input('orgScope') public orgScope = false;

  private onProjectChangeSub: Subscription;
  private onOrganizationChangeSub: Subscription;

  constructor(
    private el: ElementRef<HTMLElement>,
    private manageAuth: ManageAuthService,
    private scopeService: ScopeService,
  ) {}

  public async ngOnInit() {
    if (!this.managePerms) {
      // Lakhesis permissions are legacy. not required anymore
      throw new Error('Require either manage permissions or lakhesis permissions');
    }

    await this.manageAuth.sessionInitialized$.toPromise();
    this.checkPermissions();

    // If the permission belongs to org scope, we will not check permission based on project
    if (this.orgScope) {
      this.onOrganizationChangeSub = this.scopeService.organizationChanged$.subscribe(() => this.checkPermissions());
    } else {
      this.onProjectChangeSub = this.scopeService.projectChanged$.subscribe(project => this.checkPermissions(project));
    }
  }

  public ngOnDestroy(): void {
    if (this.onProjectChangeSub) {
      this.onProjectChangeSub.unsubscribe();
    }
    if (this.onOrganizationChangeSub) {
      this.onOrganizationChangeSub.unsubscribe();
    }
  }

  private checkPermissions(project?: Project) {
    const element = this.el.nativeElement;

    const hasPermission = this.manageAuth.hasPermissions(this.managePerms, project);
    if (hasPermission) {
      if (element.style && element.style.display === 'none') {
        element.style.display = element.dataset.display || 'initial';
      }
      return;
    }

    if (element.style) {
      if (element.style.display !== 'none') {
        element.setAttribute('data-display', element.style.display);
      }

      element.style.display = 'none';
    }
  }
}
