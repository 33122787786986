import { HttpClient } from '@angular/common/http';
/* tslint:disable: max-line-length */
import { Injectable } from '@angular/core';
import { Map } from 'leaflet';
import { map } from 'rxjs/operators';

@Injectable()
export class MapService {
  public map: Map;
  public baseMaps: any;
  private vtLayer: any;

  constructor(private http: HttpClient) {
    this.baseMaps = {
      OneMap: L.tileLayer(
        'https://maps-{s}.onemap.sg/v3/Default/{z}/{x}/{y}.png',
      ),
      Mapbox: L.tileLayer(
        'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibmphY2hvd3NraSIsImEiOiJjaXo1MXF2ZHcwNHZ4MzJwZzk0OXJ2enB1In0.O8O9n-Zzj0nnRUeR4J6kwA',
      ),
      OpenStreetMap: L.tileLayer(
        'http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
        {
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
        },
      ),
      Esri: L.tileLayer(
        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
        {
          attribution:
            'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
        },
      ),
      CartoDB: L.tileLayer(
        'http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
        {
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
        },
      ),
    };
  }

  public disableMouseEvent(elementId: string) {
    const element = document.getElementById(elementId) as HTMLElement;

    L.DomEvent.disableClickPropagation(element);
    L.DomEvent.disableScrollPropagation(element);
  }

  public toggleAirPortLayer() {
    if (this.vtLayer) {
      this.map.removeLayer(this.vtLayer);
      delete this.vtLayer;
    } else {
      this.http
        .get(
          'https://rawgit.com/haoliangyu/angular2-leaflet-starter/master/public/data/airports.geojson',
        )
        .pipe(map(res => res))
        .subscribe((result: any) => {
          this.vtLayer = L.vectorGrid.slicer(result);
          this.vtLayer.addTo(this.map);
        });
    }
  }
}
