<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" href="/">{{ getAppName() }} </a>
    </div>

    <ul *ngIf="sessionInitialized" class="nav navbar-nav">
      <li routerLinkActive="active" permission [permM]="[label.viewServiceSessions]">
        <a [i18nKey]="'menu.services'" href="/v2/service_sessions?{{ queryString() }}"></a>
      </li>
      <li *legacy-flag="'driver_schedule_v2'" routerLinkActive="active" permission [permM]="[label.showDrivers]">
        <a [i18nKey]="'menu.drivers'" [routerLink]="['drivers']" [queryParams]="params"></a>
      </li>
      <li *flag="'driver_schedule_v2'" routerLinkActive="active" permission [permM]="[label.showDrivers]">
        <a [i18nKey]="'menu.drivers'" href="/v2/drivers?{{ queryString() }}"></a>
      </li>
      <li *legacy-flag="'passenger_v2'" routerLinkActive="active" permission [permM]="[label.showPassengersLegacy]">
        <a [i18nKey]="'menu.passengers'" [routerLink]="['users']" [queryParams]="params"></a>
      </li>
      <li *legacy-flag="'passenger_v2'" routerLinkActive="active" permission [permM]="[label.viewPassengers]"
        [orgScope]="true">
        <a [i18nKey]="'menu.employees'" [routerLink]="['employees']" [queryParams]="params"></a>
      </li>
      <li *flag="'passenger_v2'" routerLinkActive="active" permission
        [permM]="[label.showPassengersLegacy, label.viewPassengers]" [orgScope]="true">
        <a [i18nKey]="'menu.passengers'" href="/v2/passengers?{{ queryString() }}"></a>
      </li>
      <li routerLinkActive="active" permission [permM]="[label.viewSchedules]">
        <a [i18nKey]="'menu.recurring_schedules'" href="/v2/schedules?{{ queryString() }}"></a>
      </li>
      <li routerLinkActive="active" permission [permM]="[label.showBookingCodes]" [orgScope]="true">
        <a [i18nKey]="'menu.booking_codes'" href="/v2/manage/booking_codes?{{ queryString() }}"></a>
      </li>
      <li *flag="'user_v2'" routerLinkActive="active" permission [permM]="[label.manageUsers]">
        <a [i18nKey]="'menu.users'" href="/v2/access_control/manage?{{ queryString() }}"></a>
      </li>
      <li *legacy-flag="'user_v2'" routerLinkActive="active" permission [permM]="[label.manageUsers]">
        <a [i18nKey]="'menu.users'" [routerLink]="['/access_control', 'manage']" [queryParams]="params"></a>
      </li>
      <li routerLinkActive="active" permission [permM]="[label.viewMessages]" [orgScope]="true">
        <a [i18nKey]="'menu.notifications'" href="/v2/notifications?{{ queryString() }}"></a>
      </li>

      <li permission
        [permM]="[label.showIncidents, label.manageCoupons, label.showRatings, label.showWaypoints, label.manageApps]"
        routerLinkActive="active" class="dropdown dropdown-hover">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
          aria-expanded="false">
          {{ 'menu.others' | i18next }} <span class="caret"></span>
        </a>

        <ul class="dropdown-menu">
          <li routerLinkActive="active" permission [permM]="[label.showIncidents]">
            <a [i18nKey]="'menu.incidents'" href="/v2/incidents?{{ queryString() }}"></a>
          </li>
          <li routerLinkActive="active" permission [permM]="[label.manageCoupons]">
            <a [i18nKey]="'menu.coupons'" href="/v2/coupons?{{ queryString() }}"></a>
          </li>
          <li class="nav-item" routerLinkActive="active" permission [permM]="[label.showRatings]">
            <a [i18nKey]="'menu.ratings'" href="/v2/ratings?{{ queryString() }}"></a>
          </li>
          <li routerLinkActive="active" permission [permM]="[label.showWaypoints]">
            <a [i18nKey]="'menu.waypoints'" href="/v2/waypoints?{{ queryString() }}" [queryParams]="params"></a>
          </li>

          <ng-container *ngIf="manageAuth.isSwatOp || manageAuth.isSwatAdmin">
            <li routerLinkActive="active">
              <a [i18nKey]="'menu.app_man'" href="/v2/appman?{{ queryString() }}"></a>
            </li>
            <li routerLinkActive="active">
              <a [i18nKey]="'menu.message_templates'" href="/v2/message_templates?{{ queryString() }}"></a>
            </li>
          </ng-container>
        </ul>
      </li>

      <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active"
        class="dropdown dropdown-hover">
        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
          aria-expanded="false">{{ 'menu.for_admin' | i18next }} <span class="caret"></span></a>
        <ul class="dropdown-menu">
          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active">
            <a i18nKey="menu.organizations" href="/v2/manage/organizations"> </a>
          </li>
          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active">
            <!-- <a i18nKey="menu.projects" [routerLink]="['manage', 'projects']" [queryParams]="params"></a> -->
            <a i18nKey="menu.projects" href="/v2/manage/projects"> </a>
          </li>
          <li *ngIf="manageAuth.isSwatAdmin" routerLinkActive="active">
            <a i18nKey="menu.programs" [routerLink]="['manage', 'programs']" [queryParams]="params"></a>
          </li>

          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" role="separator" class="divider"></li>

          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active">
            <a i18nKey="menu.service_templates" href="/v2/manage/services/templates?{{ queryString() }}"></a>
          </li>
          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" role="separator" class="divider"></li>
          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active">
            <a i18nKey="menu.fixed_routes" href="/v2/fixed_routes?{{ queryString() }}"></a>
          </li>
          <li *ngIf="manageAuth.isSwatAdmin || manageAuth.isSwatOp" routerLinkActive="active">
            <a i18nKey="menu.monitor" href="/v2/monitor?{{ queryString() }}"></a>
          </li>
          <li *ngIf="manageAuth.isSwatAdmin" routerLinkActive="active">
            <a i18nKey="menu.clients" href="/v2/clients?{{ queryString() }}"></a>
          </li>
        </ul>
      </li>
    </ul>

    <ul *ngIf="isAuthenticated()" class="nav navbar-nav navbar-right">
      <workspace-switch></workspace-switch>
      <avatar-dropdown></avatar-dropdown>
    </ul>
  </div>
</nav>