import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FixedRoutesService } from '@app/core/services/fixed-routes.service';
import * as Sentry from '@sentry/browser';
import { I18NextModule } from 'angular-i18next';

// Components
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { NotFoundComponent } from './core/components/404/not-found.component';
import { DebugButtonComponent } from './core/components/debug-button/debug-button.component';
import { AvatarDropdownComponent } from './core/components/navbar/avatar-dropdown/avatar-dropdown.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { ProjectSelectorComponent } from './core/components/navbar/project-selector/project-selector.component';
// tslint:disable-next-line: max-line-length
import { WorkspaceSwitchComponent } from './core/components/navbar/workspace-switch/workspace-switch.component';

// Login Components
import { LoginComponent } from './login/pages/login/login.component';
import { ManageLoginComponent } from './login/pages/manage-login/manage-login.component';
import { ManageRegisterComponent } from './login/pages/manage-register/manage-register.component';
import { ManageRequestTokenComponent } from './login/pages/manage-request-token/manage-request-token.component';
import { ManageResetPasswordComponent } from './login/pages/manage-reset-password/manage-reset-password.component';
import { ResetPasswordComponent } from './login/pages/reset-password/reset-password.component';
import { LoginService } from './login/services/login.service';
import { ManageLoginService } from './login/services/manage-login.service';

// Services
import { AuthGuard } from './core/guards/auth-guard.service';
import { AlertService } from './core/services/alert.service';
import { APINoAuthService } from './core/services/api-no-auth.service';
import { APIService } from './core/services/api.service';
import { AuthService } from './core/services/auth.service';
import { FeatureFlagService } from './core/services/feature-flag.service';
import { JobService } from './core/services/job.service';
import { LanguageService } from './core/services/language.service';
import { ManageAuthService } from './core/services/manage-auth.service';
import { ManageService } from './core/services/manage.service';
import { MapService } from './core/services/map.service';
import { MapboxService } from './core/services/mapbox.service';
import { OrganizationService } from './core/services/organization.service';
import { PassengerService } from './core/services/passengers.service';
import { PickupService } from './core/services/pickup.service';
import { ScopeService } from './core/services/scope.service';
import { UserService } from './core/services/user.service';
import { UtilityService } from './core/services/utility.service';
import { VehicleService } from './core/services/vehicle.service';
import { ZeusService } from './core/services/zeus.service';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { env } from '@env/environment';
import { SharedModule } from './shared/shared.module';

import { BROWSER_FAVICONS_CONFIG, BrowserFavicons, Favicons } from './core/favicon.interceptor';
import { I18N_PROVIDERS } from './core/i18next.provider';
import { TokenInterceptor } from './core/token.interceptor';

Sentry.init({
  dsn: 'https://1a70241602654e2cb674a79270183fbd@sentry.io/5108788',
  environment: env.envName,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const target = hint.event.target as HTMLElement;
      if (target.innerText) {
        breadcrumb.message = `${breadcrumb.message} [${target.innerText}]`;
      }
    }
    return breadcrumb;
  },
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any) {
    // tslint:disable-next-line: no-console
    console.log(error);

    // Remove this because it's too spam, we will only send error manually when click button
    // Sentry.captureException(error.originalError || error.error || error);
  }
}

// tslint:disable-next-line: max-classes-per-file
@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    TypeaheadModule.forRoot(),
    SharedModule.forRoot(),
    I18NextModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AvatarDropdownComponent,
    ProjectSelectorComponent,
    WorkspaceSwitchComponent,
    NavbarComponent,
    LoginComponent,
    ManageLoginComponent,
    ManageRequestTokenComponent,
    ManageRegisterComponent,
    ResetPasswordComponent,
    ManageResetPasswordComponent,
    ManageRequestTokenComponent,
    NotFoundComponent,
    DebugButtonComponent,
  ],
  providers: [
    Title,
    UserService,
    AuthService,
    AlertService,
    AuthGuard,
    VehicleService,
    JobService,
    PickupService,
    MapService,
    MapboxService,
    LoginService,
    UtilityService,
    OrganizationService,
    APIService,
    FixedRoutesService,
    FeatureFlagService,
    LanguageService,
    ManageService,
    ZeusService,
    APINoAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: Favicons,
      useClass: BrowserFavicons,
    },
    I18N_PROVIDERS,
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          swat: {
            type: 'image/x-icon',
            href: 'src/environments/swat/favicon.ico',
            isDefault: true,
          },
        },
        caseBusting: true,
      },
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    PassengerService,
    ManageLoginService,
    ManageAuthService,
    ScopeService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
