import { Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@app/core/services/language.service';
import { UserService } from '@app/core/services/user.service';
import { ManageCollection } from '@app/shared/models/manage-response';
import { Organization, Program, Project, ProjectView } from '@app/shared/models/manage-scope';
import { Service, ServiceTemplate } from '@app/shared/models/manage-services';
import { env } from '@env/environment';
import { orderBy } from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { APIService } from './api.service';
import { ManageAuthService } from './manage-auth.service';
import { ManageService } from './manage.service';

export enum ScopeType {
  ORGANIZATION = 'organization',
  PROJECT = 'project',
  PROGRAM = 'program',
}
export const SCOPE_TYPES = [ScopeType.ORGANIZATION, ScopeType.PROJECT];
export type Scope = Organization | Project | Program;

interface UserScope {
  __type?: ScopeType;
  organization?: Organization;
  project?: Project;
  program?: Program;
}

export interface ScopeChangedEvent {
  scope: Scope;
  scopeType: ScopeType;
}

export type ScopeParam =
  | {
      organization_id: string;
    }
  | {
      project_id: string;
    }
  | {
      program_id: string;
    }
  | {};

@Injectable()
export class ScopeService {
  get scope(): Scope | undefined {
    switch (this.userScope.__type) {
      case ScopeType.ORGANIZATION:
        return this.userScope.organization;
      case ScopeType.PROJECT:
        return this.userScope.project;
      case ScopeType.PROGRAM:
        return this.userScope.program;
    }
  }

  get scopeType() {
    return this.userScope.__type;
  }

  get scopeParam(): ScopeParam {
    if (!this.scope) {
      return {};
    }

    switch (this.userScope.__type) {
      case ScopeType.ORGANIZATION:
        return { organization_id: this.userScope.organization.id, application_group_id: env.applicationGroupID };
      case ScopeType.PROJECT:
        return { project_id: this.userScope.project.id, application_group_id: env.applicationGroupID };
      case ScopeType.PROGRAM:
        return { program_id: this.userScope.program.id, application_group_id: env.applicationGroupID };

      default:
        return {};
    }
  }

  get orgParam(): ScopeParam {
    return { organization_id: this.userScope.organization.id, application_group_id: env.applicationGroupID };
  }

  get queryParams() {
    return { projectId: this.project?.id, organizationId: this.organization?.id };
  }

  set organization(organization: Organization) {
    this.userScope.__type = ScopeType.ORGANIZATION;
    this.userScope.organization = organization;
    this.scopeChanged$.next({ scope: this.userScope.organization, scopeType: ScopeType.ORGANIZATION });
    this.organizationChanged$.next(organization);
  }
  get organization() {
    return this.userScope.organization;
  }
  get organizationScopeParam() {
    if (this.userScope.organization) {
      return { organization_id: this.userScope.organization.id, application_group_id: env.applicationGroupID };
    }
    return {};
  }
  get organizationId() {
    return this.manageAuthService.session.organization_id;
  }

  set project(project: Project) {
    this.userScope.__type = ScopeType.PROJECT;
    this.userScope.project = project;
    this.scopeChanged$.next({ scope: this.userScope.project, scopeType: ScopeType.PROJECT });
    this.projectChanged$.next(project);
  }
  get project() {
    return this.userScope.project;
  }
  get projectScopeParam() {
    if (this.userScope.project) {
      return { project_id: this.userScope.project.id, application_group_id: env.applicationGroupID };
    }
    return {};
  }

  set program(program: Program) {
    this.userScope.__type = ScopeType.PROGRAM;
    this.userScope.program = program;
    this.scopeChanged$.next({ scope: this.userScope.program, scopeType: ScopeType.PROGRAM });
  }
  get program() {
    return this.userScope.program;
  }

  get organizations(): Observable<Organization[]> {
    if (this.manageAuthService.isSwatAdmin || this.manageAuthService.isSwatOp) {
      const burst = !this.organizationCache$ || this.isBurstingOrgCache;
      if (burst) {
        this.organizationCache$ = this.fetchOrganizations(burst);
      }

      return this.organizationCache$;
    }

    return this.manageAuthService.organizations;
  }

  get projects(): Observable<Project[]> {
    if (this.manageAuthService.isSwatAdmin || this.manageAuthService.isSwatOp) {
      const burst = !this.projectCache$ || this.isBurstingProjCache;
      if (burst) {
        this.projectCache$ = this.fetchProjects(burst);
      }

      return this.projectCache$;
    }

    return this.manageAuthService.projects;
  }

  get programs(): Observable<Program[]> {
    if (this.manageAuthService.isSwatAdmin || this.manageAuthService.isSwatOp) {
      const burst = !this.programCache$ || this.isBurstingProgCache;
      if (burst) {
        this.programCache$ = this.fetchPrograms(burst);
      }

      return this.programCache$;
    }

    return this.manageAuthService.programs;
  }

  public reload$ = new Subject<any>();
  public burstOrgCache$ = new Subject<{}>();
  public burstProjCache$ = new Subject<{}>();
  public burstProgCache$ = new Subject<{}>();

  public scopeChanged$ = new BehaviorSubject<ScopeChangedEvent>(undefined);
  public projectChanged$ = new BehaviorSubject<Project>(undefined);
  public organizationChanged$ = new BehaviorSubject<Organization>(undefined);

  private endpoints = {
    organizations: `${this.manageService.manageUrl()}/v1/organizations`,
    organization: (id: string) => `${this.manageService.manageUrl()}/v1/organizations/${id}`,
    projects: `${this.manageService.manageUrl()}/v1/projects`,
    project: (id: string) => `${this.manageService.manageUrl()}/v1/projects/${id}`,
    programs: `${this.manageService.manageUrl()}/v1/programs`,
    program: (id: string) => `${this.manageService.manageUrl()}/v1/programs/${id}`,

    scopes: `${this.manageService.manageUrl()}/v1/operator?scopes=true`,

    // Needed for service template selector
    services: `${this.manageService.manageUrl()}/v1/services`,
    serviceSessions: `${this.manageService.manageUrl()}/v1/services/sessions`,
    serviceTemplates: `${this.manageService.manageUrl()}/v1/services/templates`,

    legacyProject: (id: string) => `${this.userService.baseUrl()}/projects/${id}`,
  };

  private organizationCache$: Observable<Organization[]>;
  private orgCacheBurstTimer$ = timer(60000); // 60s;
  private orgCacheBurstTimerSubscription: Subscription = new Subscription();
  private isBurstingOrgCache = false;

  private projectCache$: Observable<Project[]>;
  private projCacheBurstTimer$ = timer(60000); // 60s;
  private projCacheBurstTimerSubscription: Subscription = new Subscription();
  private isBurstingProjCache = false;

  private programCache$: Observable<Program[]>;
  private progCacheBurstTimer$ = timer(60000); // 60s;
  private progCacheBurstTimerSubscription: Subscription = new Subscription();
  private isBurstingProgCache = false;

  private userScope: UserScope = {
    __type: undefined,
    organization: undefined,
    project: undefined,
    program: undefined,
  };

  constructor(
    private apiService: APIService,
    private manageService: ManageService,
    private manageAuthService: ManageAuthService,
    private userService: UserService,
  ) {
    this.burstOrgCache$.subscribe(() => {
      this.isBurstingOrgCache = true;
      this.orgCacheBurstTimerSubscription.unsubscribe();
      this.orgCacheBurstTimerSubscription = this.orgCacheBurstTimer$.subscribe(() => {
        this.isBurstingOrgCache = false;
      });
    });

    this.burstProjCache$.subscribe(() => {
      this.isBurstingProjCache = true;
      this.projCacheBurstTimerSubscription.unsubscribe();
      this.projCacheBurstTimerSubscription = this.projCacheBurstTimer$.subscribe(
        () => (this.isBurstingProjCache = false),
      );
    });

    this.burstProgCache$.subscribe(() => {
      this.isBurstingProgCache = true;
      this.progCacheBurstTimerSubscription.unsubscribe();
      this.progCacheBurstTimerSubscription = this.progCacheBurstTimer$.subscribe(
        () => (this.isBurstingProgCache = false),
      );
    });

    this.manageAuthService.userLogout$.subscribe(() => this.clearObservable());
  }

  public setScope(scope: Scope, scopeType: ScopeType) {
    this.userScope.__type = scopeType;
    switch (scopeType) {
      case ScopeType.ORGANIZATION:
        this.userScope.organization = scope as Organization;
        break;
      case ScopeType.PROJECT:
        this.userScope.project = scope as Project;
        break;
      case ScopeType.PROGRAM:
        this.userScope.program = scope as Program;
        break;
    }
    this.scopeChanged$.next({ scope, scopeType });
  }

  public ngOnDestroy(): void {
    this.reload$.unsubscribe();
  }

  public clearObservable() {
    this.organizationCache$ = undefined;
    this.projectCache$ = undefined;
    this.programCache$ = undefined;
  }

  public createOrganization(organization: Organization) {
    const { name, client, system, code, type, registration, offices, reporting, application_id } = organization;

    const payload: Partial<Organization> = {
      name,
      client,
      system,
      code,
      type,
      reporting,
      application_id,
    };

    if (offices.length > 0) {
      payload.offices = organization.offices;
    }
    if (Object.keys(registration).length > 0) {
      payload.registration = registration;
    }

    return this.apiService.post(this.endpoints.organizations, undefined, payload);
  }

  public updateOrganization(organization: Organization) {
    const { name, client, system, code, type, registration, offices, reporting, application_id } = organization;

    const payload: Partial<Organization> = {
      name,
      client,
      system,
      code,
      type,
      reporting,
      application_id,
    };

    if (offices.length > 0) {
      payload.offices = organization.offices;
    }
    if (Object.keys(registration).length > 0) {
      payload.registration = registration;
    }

    return this.apiService.put(this.endpoints.organization(organization.id), undefined, payload);
  }

  public deleteOrganization(organization: Organization) {
    return this.apiService.delete(this.endpoints.organization(organization.id));
  }

  public createProject({ name, client, system, reporting, sgerp_ids }: Project) {
    const payload: Partial<Project> = {
      name,
      client,
      system,
      reporting,
      sgerp_ids,
    };
    return this.apiService.post(this.endpoints.projects, undefined, payload);
  }

  public updateProject({ id, name, client, system, reporting, sgerp_ids }: Project) {
    const payload: Partial<Project> = {
      name,
      client,
      system,
      sgerp_ids,
      reporting,
    };

    return this.apiService.put(this.endpoints.project(id), undefined, payload);
  }

  public deleteProject(project: Project) {
    return this.apiService.delete(this.endpoints.project(project.id));
  }

  public createProgram(program: Program) {
    const payload: Partial<Program> = {
      organization_id: program.organization_id,
      project_id: program.project_id,
    };
    return this.apiService.post(this.endpoints.programs, undefined, payload);
  }

  public updateProgram(program: Program) {
    const payload: Partial<Program> = {
      organization_id: program.organization_id,
      project_id: program.project_id,
    };

    return this.apiService.put(this.endpoints.program(program.id), undefined, payload);
  }

  public deleteProgram(program: Program) {
    return this.apiService.delete(this.endpoints.program(program.id));
  }

  public getProgramsByProjectID(projectID: string) {
    return this.apiService
      .get(this.endpoints.programs, { project_id: projectID })
      .pipe(map((response: ManageCollection<Program>) => response.results));
  }

  public getServices(projectID: string): Observable<Service[]> {
    const params = { all: true, project_id: projectID };
    return this.apiService
      .get(this.endpoints.services, params)
      .pipe(map((response: ManageCollection<Service>) => response.results));
  }

  public getServiceTemplates(projectID: string): Observable<ServiceTemplate[]> {
    const params = { all: true, project_id: projectID };
    return this.apiService
      .get(this.endpoints.serviceTemplates, params)
      .pipe(map((response: ManageCollection<ServiceTemplate>) => response.results));
  }

  public getServiceSessions(projectID: string): Observable<Service[]> {
    const params = { all: true, project_id: projectID };
    return this.apiService
      .get(this.endpoints.serviceSessions, params)
      .pipe(map((response: ManageCollection<Service>) => response.results));
  }

  private fetchOrganizations = (burst?: boolean) => {
    const params: { [key: string]: number | boolean } = { all: true };
    if (burst) {
      params.burst = Math.random();
    }
    return this.apiService.get(this.endpoints.organizations, params).pipe(
      map((response: ManageCollection<Organization>) => response.results),
      shareReplay(1),
    );
  };

  private fetchProjects = (burst?: boolean) => {
    const params: { [key: string]: number | boolean } = { all: true };
    if (burst) {
      params.burst = Math.random();
    }
    return this.apiService.get(this.endpoints.projects, params).pipe(
      map((response: ManageCollection<Project>) => response.results),
      shareReplay(1),
    );
  };

  private fetchPrograms = (burst?: boolean) => {
    const params: { [key: string]: number | boolean } = { all: true };
    if (burst) {
      params.burst = Math.random();
    }
    return this.apiService.get(this.endpoints.programs, params).pipe(
      map((response: ManageCollection<Program>) => response.results),
      shareReplay(1),
    );
  };
}
