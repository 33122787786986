import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@app/core/services/language.service';
import { PermissionLabel, TabPermission } from '@app/shared/models/manage-permission';
import { Organization, Project } from '@app/shared/models/manage-scope';
import { OperatorScope, SessionV2 } from '@app/shared/models/manage-session';
import { memoize } from 'lodash';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ManageService } from './manage.service';

@Injectable()
export class ManageAuthService {
  public get session() {
    return this.sessionCache;
  }

  public get isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  public get token() {
    return this.isAuthenticated ? localStorage.getItem('token') : '';
  }

  public get userLanguage(): string {
    return this.isAuthenticated && this.sessionCache ? this.sessionCache.language : DEFAULT_LANGUAGE;
  }

  public get userName(): string {
    return this.isAuthenticated && this.sessionCache
      ? this.sessionCache.name.first + ' ' + this.sessionCache.name.last
      : '';
  }

  public get isSwatAdmin(): boolean {
    return this.isAuthenticated && this.sessionCache && !!this.sessionCache.swatadmin;
  }

  public get isSwatOp(): boolean {
    return this.isAuthenticated && this.sessionCache && !!this.sessionCache.swatop;
  }

  public get organizations(): Observable<Organization[]> {
    if (!this.session) {
      return of([]);
    }

    const promise = this.getScopes().then(scopes => {
      const organizations: Organization[] = scopes
        .filter(scope => scope.type === 'organization')
        .map(scope => {
          const organization: Organization = {
            id: scope.id,
            type: '',
            name: { [DEFAULT_LANGUAGE]: scope.name },
            description: { [DEFAULT_LANGUAGE]: scope.name },
            client: {},
            system: {},
            offices: [],
            registration: [],
            code: '',
            application_id: '',
          };
          return organization;
        });
      return organizations;
    });

    return from(promise).pipe(shareReplay(1));
  }

  public get projects(): Observable<Project[]> {
    if (!this.session) {
      return of([]);
    }

    const promise = this.getScopes().then(scopes => {
      const projects: Project[] = scopes
        .filter(scope => scope.type === 'project')
        .map(scope => {
          const project: Project = {
            id: scope.id,
            name: { [DEFAULT_LANGUAGE]: scope.name },
            description: { [DEFAULT_LANGUAGE]: scope.name },
            system: {},
            client: {},
            reporting: {},
            organization_ids: scope.organization_ids,
          };

          return project;
        });
      return projects;
    });

    return from(promise).pipe(shareReplay(1));
  }

  // Deprecate this call please
  public get programs() {
    return of([]);
  }

  public userLogout$ = new Subject();
  public forceUserLogout$ = new Subject();
  // Can turn this into a promise and await to make sure the session is initialized
  public sessionInitialized$ = new BehaviorSubject<SessionV2>(undefined);

  public getScopes: () => Promise<OperatorScope[]> = memoize(this._getScopes).bind(this);

  private tabs: TabPermission[] = [
    { permission: PermissionLabel.viewServiceSessions, route: ['/services'], v2_route: '/v2/service_sessions' },
    { permission: PermissionLabel.showDrivers, route: ['/drivers'] },
    { permission: PermissionLabel.showPassengersLegacy, route: ['/users'] },
    { permission: PermissionLabel.viewPassengers, route: ['/employees'] },
    {
      permission: PermissionLabel.viewSchedules,
      route: ['/schedules'],
      v2_route: '/v2/schedules',
    },
    {
      permission: PermissionLabel.viewMessages,
      route: ['/notifications'],
      v2_route: '/v2/notifications',
    },
    { permission: PermissionLabel.showBookingCodes, route: ['/booking_codes'] },
    { permission: PermissionLabel.manageUsers, route: ['/access_control', 'manage'] },
  ];

  private sessionCache: SessionV2;
  private endpoints = {
    session: `${this.manageService.manageUrl()}/v2/sessions/self`,
    sessionSwitch: (token: string) => `${this.manageService.manageUrl()}/v2/sessions/${token}`,
    logout: (token: string) => `${this.manageService.manageUrl()}/v2/sessions/${token}`,
    scopes: `${this.manageService.manageUrl()}/v2/operator?scopes=true`,
  };

  public constructor(private http: HttpClient, private manageService: ManageService) {
    this.forceUserLogout$.subscribe(() => this.logOut());
  }

  public ngOnDestroy(): void {
    this.forceUserLogout$.unsubscribe();
  }

  public saveUserSession(session: SessionV2) {
    localStorage.setItem('token', session.id);
    localStorage.setItem('lastUpdated', new Date().toISOString());
    this.sessionCache = session;
  }

  public updateUserSession(session: SessionV2) {
    localStorage.setItem('token', session.id);
    this.sessionCache = session;
  }

  public async initializeSession() {
    try {
      const result = await this.http.get<SessionV2>(this.endpoints.session).toPromise();
      this.updateUserSession(result);
      this.sessionInitialized$.next(this.session);
      this.sessionInitialized$.complete();
    } catch (err) {
      const error = err as HttpErrorResponse;

      const isTokenExpired = error.status === 401;
      const isTokenInvalid = error.status === 403 && error.error?.code === 1007;
      if (isTokenExpired || isTokenInvalid) {
        this.forceUserLogout$.next();
      }
    }
  }

  public async switchOrganization(organizationId: string) {
    // Do not call API organization id is not changed
    if (this.sessionCache && this.sessionCache.organization_id === organizationId) {
      return;
    }

    const newSession = await this.http
      .put<SessionV2>(this.endpoints.sessionSwitch(this.token), { organization_id: organizationId })
      .toPromise();

    this.updateUserSession(newSession);
  }

  public logOut() {
    this.http.delete(this.endpoints.logout(this.token)).subscribe();
    localStorage.clear();
    this.sessionCache = undefined;
    this.userLogout$.next();
    location.href = '/v2/login';
  }

  public hasPermissions = (permissions: string[], project?: Project): boolean => {
    if (this.isSwatAdmin || this.isSwatOp) {
      return true;
    }

    const session = this.session;
    if (!session) {
      return false;
    }

    // Check organization scope
    if (!project) {
      const orgPermissions = session.permissions[session.organization_id] || [];
      if (orgPermissions.includes(PermissionLabel.SCOPE_ADMIN)) {
        return true;
      }

      return permissions.some((permission: string) => {
        return orgPermissions.includes(permission);
      });
    }

    // Check project scope
    if (project) {
      const scopePermissions = session.permissions[project.id] || [];
      const isScopeAdmin = scopePermissions.includes(PermissionLabel.SCOPE_ADMIN);

      if (isScopeAdmin) {
        return true;
      }

      return permissions.some((permission: string) => {
        return scopePermissions.includes(permission);
      });
    }
  };

  public firstAccessibleTab() {
    const tab = this.tabs.find((t: TabPermission) => this.hasPermissions([t.permission]));
    return tab;
  }

  private async _getScopes() {
    try {
      const scopes = await this.http.get<{ scopes: OperatorScope[] }>(this.endpoints.scopes).toPromise();
      return scopes.scopes;
    } catch (err) {
      const error = err as HttpErrorResponse;

      const isTokenExpired = error.status === 401;
      const isTokenInvalid = error.status === 403 && error.error?.code === 1007;
      if (isTokenExpired || isTokenInvalid) {
        this.forceUserLogout$.next();
      }
    }
  }
}
