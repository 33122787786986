import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AlertService } from '@app/core/services/alert.service';
import { PASSWORD_MIN_LENGTH } from '@app/shared/config';
import { I18NextService } from 'angular-i18next';
import { Subscription } from 'rxjs';
import { ManageLoginService } from '../../services/manage-login.service';

@Component({
  selector: 'manage-reset-password',
  templateUrl: './manage-reset-password.component.html',
  styleUrls: ['../login.scss'],
})
export class ManageResetPasswordComponent implements OnInit, AfterViewInit {
  public token: string;
  public email: string;
  public isResettingPassword = false;

  public resetPasswordForm: FormGroup;
  public passwordControl: FormControl;
  public repeatPasswordControl: FormControl;
  public tokenRequested: boolean;

  public onTokenSubscription: Subscription;
  public PASSWORD_MIN_LENGTH = PASSWORD_MIN_LENGTH;

  constructor(
    private route: ActivatedRoute,
    private loginService: ManageLoginService,
    private alertService: AlertService,
    private router: Router,
    private i18next: I18NextService,
  ) {
    const { paramMap, queryParamMap } = this.route;

    paramMap.subscribe((params: ParamMap) => {
      this.token = params.get('token');
    });

    queryParamMap.subscribe((params: ParamMap) => {
      this.email = params.get('email');
    });
  }

  public ngOnInit() {
    this.setupResetPasswordForm();
  }

  public ngAfterViewInit() {
    this.resetCredentials();
  }

  public setupResetPasswordForm() {
    this.passwordControl = new FormControl('', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]);
    this.repeatPasswordControl = new FormControl('', [Validators.required]);

    this.resetPasswordForm = new FormGroup(
      {
        passwordControl: this.passwordControl,
        repeatPasswordControl: this.repeatPasswordControl,
      },
      this.confirmPasswordValidator(),
    );
  }

  public resetCredentials() {
    this.isResettingPassword = false;
    this.setupResetPasswordForm();
  }

  public resetPasswordAndOTPFields() {
    this.isResettingPassword = false;
    this.setupResetPasswordForm();
  }

  public confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.get('passwordControl').value;
      const repeatedPassword = control.get('repeatPasswordControl').value;
      const match = password === repeatedPassword ? password !== '' && password !== null : false;
      return match ? null : { confirm: 'not match' };
    };
  }

  public async resetPassword() {
    this.isResettingPassword = true;

    try {
      await this.loginService
        .resetPassword({
          token: this.token,
          email: this.email,
          password: this.passwordControl.value,
          confirm: this.repeatPasswordControl.value,
        })
        .toPromise();
      this.alertService.success({ title: this.i18next.t('login.password_reset.alert.success') });
      this.router.navigate(['/login']);
    } finally {
      this.isResettingPassword = false;
    }
  }
}
