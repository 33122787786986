import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AlertService } from '@app/core/services/alert.service';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ManageLoginService } from '@app/login/services/manage-login.service';
import { ErrorCodes, ErrorHelper, ServiceError } from '@app/shared/models/errors';
import { SessionV2 } from '@app/shared/models/manage-session';
import { I18NextService } from 'angular-i18next';

// Login flow
// 1 - If they are already logged in, hit POST /v2/operator with the invitation_id
// 2 - If they aren't logged in, call `GET v2/invitation/id` to get the state {email, existing}
// 3 - Based on `existing`, redirect them to login page (3a) or Register (3b)
// 3a - In Login Page, do a normal login, then hit POST /v2/operator with the invitation_id
// 3b - In Register Page, hit POST /v2/operator with the invitation_id and the other create details

@Component({
  selector: 'manage-login',
  templateUrl: './manage-login.component.html',
  styleUrls: ['../login.scss'],
})
export class ManageLoginComponent {
  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }
  public loginErrorMessage: string = null;
  public isLoggingIn: boolean = false;

  public showLoginNotice = false;
  public loginForm: FormGroup;
  public invitationToken: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: ManageAuthService,
    private loginService: ManageLoginService,
    private i18NextService: I18NextService,
    private alertService: AlertService,
  ) {
    location.href = '/v2/login';
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.invitationToken = params.get('token');
      if (this.invitationToken) {
        this.checkInvitations(this.invitationToken);
      } else if (this.authService.isAuthenticated) {
        this.router.navigate(['/']);
      }
    });
  }

  public ngOnInit(): void {
    this.setup();
  }

  public setup() {
    this.loginForm = new FormGroup({
      email: new FormControl(this.loginService.email, [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public resetCredentials() {
    this.isLoggingIn = false;
    this.password.reset();
  }

  public resetPasswordField() {
    this.isLoggingIn = false;
  }

  public invalidLogin() {
    return this.isLoggingIn || this.loginForm.invalid;
  }

  public async login() {
    this.isLoggingIn = true;

    try {
      const session = await this.loginService
        .login({ email: this.email.value, password: this.password.value })
        .toPromise();

      if (this.invitationToken) {
        // Flow 3a
        // Need to save token to local so user can acceptInvitation
        this.authService.saveUserSession(session);

        try {
          // Just ignore when the invitation token is invalid
          const invitationSession = await this.loginService.acceptInvitation(this.invitationToken).toPromise();
          this.loginAndRedirect(invitationSession);
        } catch {
          this.loginAndRedirect(session);
        }
      } else {
        this.loginAndRedirect(session);
      }
    } catch (err) {
      const { simpleError: error } = err as ServiceError;

      if (error && error.code === ErrorCodes.MUST_RESET_PASSWORD) {
        this.router.navigate(['/manage/reset_password']);
      }
      // TODO: Implement TOTP checking

      this.resetCredentials();
    } finally {
      this.isLoggingIn = false;
    }
  }

  private async checkInvitations(invitationID: string) {
    try {
      // Flow 1
      if (this.authService.isAuthenticated) {
        const session = await this.loginService.acceptInvitation(invitationID).toPromise();
        this.loginAndRedirect(session);
      } else {
        // Flow 2 and 3
        const invitation = await this.loginService.checkInvitation(invitationID).toPromise();
        if (invitation.existing) {
          // Flow 3a
          this.showLoginNotice = true;
          this.loginForm.controls.email.setValue(invitation.email);
        } else {
          // Flow 3b
          this.loginService.email = invitation.email;
          this.router.navigate(['/manage', 'register', invitationID]);
        }
      }
    } catch (error) {
      if (this.authService.isAuthenticated) {
        location.replace('/');
        return;
      }

      // If token is invalid, redirect to page instead
      // Do blah blah

      const simpleError = ErrorHelper.getSimpleError(error);
      const { title, message } = simpleError;
      this.alertService.error({ title, html: message, noTranslate: true });
    }
  }

  private async loginAndRedirect(session: SessionV2) {
    await this.authService.saveUserSession(session);
    await this.i18NextService.changeLanguage(session.language);

    location.replace('/');
  }
}
