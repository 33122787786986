import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { SeatCount } from '@app/shared/models/pickup';
import { FixedRoute } from '@app/shared/models/vehicle';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface CloneRouteInput {
  recurring_schedule_id: string;
  name: string;
  bus_number?: string;
  capacity: SeatCount;
}

export interface CreateRouteInput {
  name: string;
  zeus_id: string;
  stops: Array<{ id: string; clock: string }>;
}

@Injectable()
export class FixedRoutesService {
  private endpoints = {
    fixedRoutesAll: `${this.userService.baseUrl()}/fixed_routes/all`,
    create: `${this.userService.baseUrl()}/fixed_routes`,
    clone: (templateID: string) => `${this.userService.baseUrl()}/fixed_routes/${templateID}/clone`,
    fixedRoutes: (routeID: string) => `${this.userService.baseUrl()}/fixed_routes/${routeID}`,
    delete: (routeID: string) => `${this.userService.baseUrl()}/fixed_routes/${routeID}`,
    allRouteTemplates: (zeusID: string) => `${this.userService.baseUrl()}/zeuses/${zeusID}/fixed_route_templates`,
  };
  constructor(private apiService: APIService, private userService: UserService) {}

  public fetchAllFixedRoutes(): Observable<FixedRoute[]> {
    return this.apiService.get(this.endpoints.fixedRoutesAll).pipe(map(response => response.fixed_routes));
  }

  public update(fixedRoute: FixedRoute): Observable<any> {
    return this.apiService.put(
      this.endpoints.fixedRoutes(fixedRoute.id),
      null,
      fixedRoute,
      undefined,
      'Route had been successfully updated',
    );
  }

  public getAllRouteTemplates(zeusID: string): Observable<FixedRoute[]> {
    return this.apiService.get(this.endpoints.allRouteTemplates(zeusID)).pipe(map(response => response.routes));
  }

  public create(input: CreateRouteInput): Observable<FixedRoute> {
    return this.apiService.post(this.endpoints.create, undefined, input).pipe(map(response => response.fixed_route));
  }

  public clone(templateID: string, input: CloneRouteInput): Observable<FixedRoute> {
    return this.apiService
      .post(this.endpoints.clone(templateID), undefined, input, undefined, 'New route had been successfully cloned!')
      .pipe(map(response => response.fixed_route));
  }

  public delete(fixedRouteId: string) {
    return this.apiService.delete(this.endpoints.delete(fixedRouteId));
  }

  // In server side, route name are generated with format `service_name month route_number`
  // Therefore we can try to detect the route number based on route name
  // As long as the name follow the same format
  public getRouteNumber(routeName: string) {
    const splited = routeName.split(' ').filter(s => s.length > 0);
    return splited[splited.length - 1];
  }
}
