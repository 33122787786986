import { Component, Inject, OnInit } from '@angular/core';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { SessionV2 } from '@app/shared/models/manage-session';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Component({
  selector: 'avatar-dropdown',
  templateUrl: './avatar-dropdown.component.html',
  styleUrls: ['./avatar-dropdown.component.scss'],
})
export class AvatarDropdownComponent implements OnInit {
  public session: SessionV2;

  constructor(private manageAuth: ManageAuthService, @Inject(I18NEXT_SERVICE) private i18n: ITranslationService) {}

  public async ngOnInit() {
    this.session = await this.manageAuth.sessionInitialized$.toPromise();
  }

  public showSmallerText() {
    return this.i18n.language === 'ja-jp'; // HACK: Japanese font size is bigger so we must show smaller text
  }

  public getShortName(name: SessionV2['name']) {
    if (this.i18n.language === 'ja-jp') {
      return name.first;
    }

    let shortName = '';

    const { first, last } = name;
    if (first && first.length > 0) {
      shortName += first[0];
    }
    if (last && last.length > 0) {
      shortName += last[0];
    }

    return shortName.toLocaleUpperCase();
  }

  public getFullName(name: SessionV2['name']) {
    return `${name.first} ${name.last}`;
  }

  public logout() {
    this.manageAuth.logOut();
  }
}
