<div class="container-fluid">
  <div class="container">
    <div class="login-panel">
      <div *ngIf="showLoginNotice" class="alert alert-info" role="alert" [innerHTML]="'login.email_exist' | i18next">
      </div>

      <div class="form-container">
        <h4 class="login-title text-center" i18nKey="login.title">
        </h4>
        <form [formGroup]="loginForm" class="form-group">
          <div class="input-group login-input-group">
            <span class="input-group-addon">
              <i class="fa fa-envelope text-secondary"></i>
            </span>
            <input formControlName="email" type="text" class="form-control no-validate" placeholder="Email" />
          </div>
          <div class="input-group login-input-group">
            <span class="input-group-addon">
              <i class="fa fa-key text-secondary"></i>
            </span>
            <input formControlName="password" type="password" class="form-control no-validate" placeholder="Password"
              (keyup.enter)="login()" />
          </div>

          <button type="button" class="btn btn-primary login-button" [disabled]="invalidLogin()" (click)="login()">
            <span *ngIf="!isLoggingIn" i18nKey="login.log_in"></span>
            <span *ngIf="isLoggingIn" i18nKey="login.logging_in"></span>
          </button>
        </form>
      </div>

      <div class="forgot-password-container">
        <a [routerLink]="['/manage/reset_password']" i18nKey="login.forgot_password"></a>
      </div>
    </div>
  </div>
</div>