import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AlertService } from '@app/core/services/alert.service';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ManageLoginService } from '../../services/manage-login.service';

@Component({
  selector: 'manage-request-token',
  templateUrl: './manage-request-token.component.html',
  styleUrls: ['../login.scss'],
})
export class ManageRequestTokenComponent {
  public isSendingEmail = false;
  public tokenRequested = false;
  public formControl = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private loginService: ManageLoginService,
    private alertService: AlertService,
    @Inject(I18NEXT_SERVICE) private i18next: ITranslationService,
  ) {}

  public async requestToken() {
    if (this.isSendingEmail || this.tokenRequested || this.formControl.invalid) {
      return;
    }
    this.isSendingEmail = true;
    try {
      await this.loginService.requestToken(this.formControl.value).toPromise();
      this.tokenRequested = true;
      this.alertService.success({ title: 'login.password_reset.alert.title', text: 'login.password_reset.alert.text' });
    } finally {
      this.isSendingEmail = false;
    }
  }

  public getButtonText() {
    if (this.isSendingEmail) {
      return this.i18next.t('login.password_reset.sending');
    } else {
      if (this.tokenRequested) {
        return this.i18next.t('login.password_reset.sent');
      }
      return this.i18next.t('button.send');
    }
  }
}
