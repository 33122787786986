import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AlertService } from '@app/core/services/alert.service';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ManageService } from '@app/core/services/manage.service';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'debug-button',
  templateUrl: './debug-button.component.html',
  styleUrls: ['./debug-button.component.scss'],
})
export class DebugButtonComponent {
  constructor(
    private http: HttpClient,
    private manageService: ManageService,
    private manageAuth: ManageAuthService,
    private alertService: AlertService,
  ) {}

  public async sendDebugRequest() {
    const endpoint = `${this.manageService.manageUrl()}/debug`;
    const { id, ...session } = this.manageAuth.session;

    await this.http.post(endpoint, { session }, { responseType: 'text' }).toPromise();
    this.alertService.success({ text: 'Thank you for helping us troubleshoot this issue.' });

    const error = new Error('Trouble Shooting Exception');
    error.name = 'Manual Exception for troubleshooting ' + Math.random();

    Sentry.captureException(error);
  }
}
