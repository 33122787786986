import { EventEmitter, Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { ManageService } from '@app/core/services/manage.service';
import { PickupService } from '@app/core/services/pickup.service';
import { UserService } from '@app/core/services/user.service';
import { UtilityService } from '@app/core/services/utility.service';
import { Bacchus } from '@app/shared/models/bacchus';
import { DriverV2 } from '@app/shared/models/driver';
import { Pickup, PickupState } from '@app/shared/models/pickup';
import { SendNotificationsPayload } from '@app/shared/models/send-notifications';
import {
  DriverBreakStatus,
  SimpleVehicleModel,
  SimpleVehicleWithPickupStats,
  Vehicle,
  VehicleState,
} from '@app/shared/models/vehicle';
import { VehicleBreakdownPayload } from '@app/shared/models/vehicle-breakdown';
import { I18NextService } from 'angular-i18next';
import * as delay from 'delay';
import { flatten, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { forkJoin, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { bufferTime, catchError, map } from 'rxjs/operators';
import { WebSocketSubject } from 'rxjs/webSocket';

@Injectable()
export class VehicleService {
  public onVehicleUpdated = new EventEmitter<string[]>(); // this applies to updates from live vehicles
  public onVehicleDetailUpdated = new EventEmitter<Vehicle>(); // this applies to ALL, including non-idle vehicles
  public onRouteAssigned = new EventEmitter<any>();
  public onDriverAssigned = new EventEmitter<any>();
  public onForcedRouteChange = new EventEmitter<any>();

  public onGetNonIdleVehicles: ReplaySubject<string[]> = new ReplaySubject<string[]>();
  public onGetNonIdleVehiclesSubscription: Subscription;

  public onGetVehicleStat: ReplaySubject<string> = new ReplaySubject<string>();
  public onGetVehicleStatSubscription: Subscription;

  // All vehicles starting breakdown flow. the key is the vehicle Id
  public breakdownStartingVehicles: { [key: string]: Vehicle } = {};

  public invisibleVehicles: { [key: string]: boolean } = {};

  // the key is the vehicle Id. This caches non-idle vehicles only
  private vehiclesCache: { [key: string]: Vehicle } = {};

  private vehicleUpdateWSs: Record<string, WebSocketSubject<any>> = {};
  private vehicleUpdateWSSubs: Record<string, Subscription> = {};

  private driverBreakWS: WebSocketSubject<any>;
  private driverBreakWSSub: Subscription;

  private readonly REFRESHING_INTERVAL = 3 * 1000; // ms. Refreshing will be triggered after 3 seconds have passed.

  private readonly endpoints = {
    driversForBacchi: `${this.userService.baseUrl()}/drivers/bacchi`,
    reverseGeoCode: `${this.userService.baseUrl()}/reverse_geocode`,
    nonIdleVehicles: `${this.userService.baseUrl()}/bacchi/vehicles`,
    serviceVehicles: `${this.userService.baseUrl()}/bacchi/service_vehicles`,
    vehicleByID: (id: string) => `${this.userService.baseUrl()}/vehicles/${id}`,
    forceRouteChange: (routeID: string) => `${this.userService.baseUrl()}/${routeID}/force_route_change`,
    syncDriverSchedules: (bacchusID: string) =>
      `${this.userService.baseUrl()}/bacchi/${bacchusID}/vehicles/sync_driver_schedules`,
    syncFixedRouteSchedules: (bacchusID: string) =>
      `${this.userService.baseUrl()}/bacchi/${bacchusID}/fixed_routes/create_from_driver_schedules`,
    removeVehiclesFixedRouteInstance: (vehicleID: string, friID: string) =>
      `${this.userService.baseUrl()}/vehicles/${vehicleID}/remove/fixed_route_instances/${friID}`,
    pickupsForVehicle: (vehicleID: string) => `${this.userService.baseUrl()}/vehicles/${vehicleID}/pickups`,
    usersForVehicle: (vehicleID: string) => `${this.userService.baseUrl()}/vehicles/${vehicleID}/users`,
    physicalVehicleForVehicle: (vehicleId: string) =>
      `${this.userService.baseUrl()}/bacchi/${vehicleId}/matching_demand/physical_vehicles`,
    physicalVehicleForBacchus: (bacchusID: string) =>
      `${this.userService.baseUrl()}/bacchi/${bacchusID}/physical_vehicles`,
    breakDownVehicle: (vehicleID: string) => `${this.userService.baseUrl()}/vehicles/${vehicleID}/breakdown`,
    sendNotifications: (vehicleID: string) => `${this.userService.baseUrl()}/vehicles/${vehicleID}/send_notifications`,
    pickupStat: (vehicleID: string) => `${this.userService.baseUrl()}/vehicles/${vehicleID}/pickupstats`,
    conflictVehicles: `${this.userService.baseUrl()}/vehicles/find_conflict`,
    overlapVehicles: `${this.userService.baseUrl()}/vehicles/find_overlap`,

    driversForBacchiV2: `${this.manageService.manageUrl()}/v2/drivers`,
    syncDriverSchedulesV2: `${this.manageService.manageUrl()}/v2/vehicles/sessions/sync`,
    deleteVehicleV2: (vehicleID: string) => `${this.manageService.manageUrl()}/v2/vehicles/sessions/${vehicleID}`,
    bulkCancelBookings: (vehicleID: string) =>
      `${this.manageService.manageUrl()}/v2/vehicles/sessions/${vehicleID}/bulk_cancel`,
  };

  constructor(
    private pickupService: PickupService,
    private userService: UserService,
    private utilityService: UtilityService,
    private apiService: APIService,
    private i18next: I18NextService,
    private manageService: ManageService,
  ) {
    this.onGetNonIdleVehiclesSubscription = this.onGetNonIdleVehicles
      .pipe(bufferTime(this.REFRESHING_INTERVAL))
      .subscribe((listOfBacchusIDs: string[][]) => {
        if (listOfBacchusIDs.length === 0) {
          return;
        }
        const bacchusIDs = uniq(flatten(listOfBacchusIDs));
        if (bacchusIDs.length === 0) {
          return;
        }

        this.getNonIdleVehiclesForBacchi(bacchusIDs);
      });

    this.onGetVehicleStatSubscription = this.onGetVehicleStat
      .pipe(bufferTime(this.REFRESHING_INTERVAL))
      .subscribe((vehicleIDs: string[]) => {
        const uniqueVehicleIDs = uniq(vehicleIDs);
        for (const vehicleID of uniqueVehicleIDs) {
          this.getPickupStatsForVehicle(vehicleID);
        }
      });

    this.initializeService();
  }

  public ngOnDestroy(): void {
    this.onGetNonIdleVehiclesSubscription.unsubscribe();
    this.onGetVehicleStatSubscription.unsubscribe();

    this.driverBreakWS?.complete();
    this.driverBreakWSSub?.unsubscribe();

    for (const bacchusId of Object.keys(this.vehicleUpdateWSs)) {
      this.unsubscribeBacchusVehicles(bacchusId);
    }
  }

  // these vehicles have been hydrated with driver, pickup stats and break status
  public getNonIdleVehiclesForBacchi(bacchusIds: string[]) {
    const params = { bacchi: bacchusIds };

    this.apiService
      .get(this.endpoints.nonIdleVehicles, params)
      .pipe(
        map((response: any) => {
          return response.vehicles;
        }),
      )
      .subscribe((vehicles: Vehicle[]) => {
        if (vehicles && vehicles.length > 0) {
          this.updateVehicleCacheWithVehicles(vehicles, false, false);

          // note that there is no call to pickupService.updatePickupCacheWithVehicles(vehicles)
          // because these vehicles don't contain the live pickup info
        }
        this.onVehicleUpdated.emit(bacchusIds);
      });
  }

  public getPickupStatsForVehicle(vehicleId: string) {
    this.apiService.get(this.endpoints.pickupStat(vehicleId)).subscribe((response: any) => {
      if (response.vehicle) {
        const pickupStats: SimpleVehicleWithPickupStats = response.vehicle;
        this.updateVehicleCacheWithPickupStats(pickupStats);
      }
    });
  }

  public getPickupStatsForVehicleObservable(vehicleIds: string[]) {
    return forkJoin(vehicleIds.map(vehicleId => this.apiService.get(this.endpoints.pickupStat(vehicleId))));
  }

  public updateVehicleCacheWithVehicles(
    vehicles: Vehicle[], // these vehicles might come from vehicle.update or getNonIdleVehiclesForBacchi
    preservePickupStats: boolean,
    preserveBreakStatus: boolean,
  ) {
    let bacchusIDsWithNewNonIdleVehicles: string[] = [];
    // replace vehicles by new vehicles with the same vehicleId
    for (const v of vehicles) {
      if (this.vehiclesCache[v.id]) {
        // vehicle already in cache. possible scenarios:
        // 1. An existing live vehicle getting an update, hence stats should be preserved
        // 2. getNonIdleVehicles() getting called again as part of refresh, hence stats should NOT be preserved.
        // For 1, the hydrated driver should be preserved.

        if (preservePickupStats) {
          v.cancelled_pax_count = this.vehiclesCache[v.id].cancelled_pax_count;
          v.boarded_pax_count = this.vehiclesCache[v.id].boarded_pax_count;
          v.missed_pax_count = this.vehiclesCache[v.id].missed_pax_count;
          v.arrived_pax_count = this.vehiclesCache[v.id].arrived_pax_count;
          v.aborted_pax_count = this.vehiclesCache[v.id].aborted_pax_count;
          v.prebooked_pax_count = this.vehiclesCache[v.id].prebooked_pax_count;
          v.waiting_for_vehicle_pax_count = this.vehiclesCache[v.id].waiting_for_vehicle_pax_count;

          v.cancelled_wc_count = this.vehiclesCache[v.id].cancelled_wc_count;
          v.boarded_wc_count = this.vehiclesCache[v.id].boarded_wc_count;
          v.missed_wc_count = this.vehiclesCache[v.id].missed_wc_count;
          v.arrived_wc_count = this.vehiclesCache[v.id].arrived_wc_count;
          v.aborted_wc_count = this.vehiclesCache[v.id].aborted_wc_count;
          v.prebooked_wc_count = this.vehiclesCache[v.id].prebooked_wc_count;
          v.waiting_for_vehicle_wc_count = this.vehiclesCache[v.id].waiting_for_vehicle_wc_count;

          v.cancelled_adhoc_pax_count = this.vehiclesCache[v.id].cancelled_adhoc_pax_count;
          v.boarded_adhoc_pax_count = this.vehiclesCache[v.id].boarded_adhoc_pax_count;
          v.missed_adhoc_pax_count = this.vehiclesCache[v.id].missed_adhoc_pax_count;
          v.arrived_adhoc_pax_count = this.vehiclesCache[v.id].arrived_adhoc_pax_count;
          v.aborted_adhoc_pax_count = this.vehiclesCache[v.id].aborted_adhoc_pax_count;
          v.prebooked_adhoc_pax_count = this.vehiclesCache[v.id].prebooked_adhoc_pax_count;
          v.waiting_for_vehicle_adhoc_pax_count = this.vehiclesCache[v.id].waiting_for_vehicle_adhoc_pax_count;

          v.cancelled_adhoc_wc_count = this.vehiclesCache[v.id].cancelled_adhoc_wc_count;
          v.boarded_adhoc_wc_count = this.vehiclesCache[v.id].boarded_adhoc_wc_count;
          v.missed_adhoc_wc_count = this.vehiclesCache[v.id].missed_adhoc_wc_count;
          v.arrived_adhoc_wc_count = this.vehiclesCache[v.id].arrived_adhoc_wc_count;
          v.aborted_adhoc_wc_count = this.vehiclesCache[v.id].aborted_adhoc_wc_count;
          v.prebooked_adhoc_wc_count = this.vehiclesCache[v.id].prebooked_adhoc_wc_count;
          v.waiting_for_vehicle_adhoc_wc_count = this.vehiclesCache[v.id].waiting_for_vehicle_adhoc_wc_count;
        }

        if (preserveBreakStatus) {
          v.driver_break_status = this.vehiclesCache[v.id].driver_break_status;
          v.breakStatusLabel = this.vehiclesCache[v.id].breakStatusLabel;
          v.breakStatusClassType = this.vehiclesCache[v.id].breakStatusClassType;
          v.breakStatusTooltip = this.vehiclesCache[v.id].breakStatusTooltip;
        } else {
          this.setLabelClassDurationForVehicleBreakStatus(v);
        }

        if (!v.driver) {
          v.driver = this.vehiclesCache[v.id].driver;
        }
      } else {
        // vehicle not in cache. possible scenarios:
        // 1. a replacement vehicle coming online via vehicle update directly.
        //    It was not previously captured by getNonIdleVehicles(). It has no
        //    hydrated data at all.
        // 2. getNonIdleVehicles() returns a newly online vehicle
        // For 1, we can tell it's replacement vehicle because it has no driver.
        // We should call getNonIdleVehicles() to get vehicles with proper hydration data
        if (!v.driver) {
          bacchusIDsWithNewNonIdleVehicles.push(v.bacchus_id);
          continue;
        }
      }
      this.vehiclesCache[v.id] = v;
    }
    if (bacchusIDsWithNewNonIdleVehicles.length > 0) {
      bacchusIDsWithNewNonIdleVehicles = uniq(bacchusIDsWithNewNonIdleVehicles);
      this.onGetNonIdleVehicles.next(bacchusIDsWithNewNonIdleVehicles);
    }
  }

  public updateVehicleCacheWithPickupStats(pickupStats: SimpleVehicleWithPickupStats) {
    const id: string = pickupStats.vehicle_id;
    if (this.vehiclesCache[id]) {
      this.vehiclesCache[id].cancelled_pax_count = pickupStats.cancelled_pax_count;
      this.vehiclesCache[id].boarded_pax_count = pickupStats.boarded_pax_count;
      this.vehiclesCache[id].missed_pax_count = pickupStats.missed_pax_count;
      this.vehiclesCache[id].arrived_pax_count = pickupStats.arrived_pax_count;
      this.vehiclesCache[id].aborted_pax_count = pickupStats.aborted_pax_count;
      this.vehiclesCache[id].prebooked_pax_count = pickupStats.prebooked_pax_count;
      this.vehiclesCache[id].waiting_for_vehicle_pax_count = pickupStats.waiting_for_vehicle_pax_count;

      this.vehiclesCache[id].cancelled_wc_count = pickupStats.cancelled_wc_count;
      this.vehiclesCache[id].boarded_wc_count = pickupStats.boarded_wc_count;
      this.vehiclesCache[id].missed_wc_count = pickupStats.missed_wc_count;
      this.vehiclesCache[id].arrived_wc_count = pickupStats.arrived_wc_count;
      this.vehiclesCache[id].aborted_wc_count = pickupStats.aborted_wc_count;
      this.vehiclesCache[id].prebooked_wc_count = pickupStats.prebooked_wc_count;
      this.vehiclesCache[id].waiting_for_vehicle_wc_count = pickupStats.waiting_for_vehicle_wc_count;

      this.vehiclesCache[id].cancelled_adhoc_pax_count = pickupStats.cancelled_adhoc_pax_count;
      this.vehiclesCache[id].boarded_adhoc_pax_count = pickupStats.boarded_adhoc_pax_count;
      this.vehiclesCache[id].missed_adhoc_pax_count = pickupStats.missed_adhoc_pax_count;
      this.vehiclesCache[id].arrived_adhoc_pax_count = pickupStats.arrived_adhoc_pax_count;
      this.vehiclesCache[id].aborted_adhoc_pax_count = pickupStats.aborted_adhoc_pax_count;
      this.vehiclesCache[id].prebooked_adhoc_pax_count = pickupStats.prebooked_adhoc_pax_count;
      this.vehiclesCache[id].waiting_for_vehicle_adhoc_pax_count = pickupStats.waiting_for_vehicle_adhoc_pax_count;

      this.vehiclesCache[id].cancelled_adhoc_wc_count = pickupStats.cancelled_adhoc_wc_count;
      this.vehiclesCache[id].boarded_adhoc_wc_count = pickupStats.boarded_adhoc_wc_count;
      this.vehiclesCache[id].missed_adhoc_wc_count = pickupStats.missed_adhoc_wc_count;
      this.vehiclesCache[id].arrived_adhoc_wc_count = pickupStats.arrived_adhoc_wc_count;
      this.vehiclesCache[id].aborted_adhoc_wc_count = pickupStats.aborted_adhoc_wc_count;
      this.vehiclesCache[id].prebooked_adhoc_wc_count = pickupStats.prebooked_adhoc_wc_count;
      this.vehiclesCache[id].waiting_for_vehicle_adhoc_wc_count = pickupStats.waiting_for_vehicle_adhoc_wc_count;
    }
  }

  public getNonIdleVehiclesForBacchiFromCache(
    bacchusIds: string[],
    isFilterInvisibleVehicles: boolean = false,
  ): Vehicle[] {
    const outputVehicles: Vehicle[] = [];

    if (!bacchusIds) {
      return outputVehicles;
    }

    for (const vehicleId in this.vehiclesCache) {
      if (bacchusIds.includes(this.vehiclesCache[vehicleId].bacchus_id)) {
        if (!isFilterInvisibleVehicles || !this.invisibleVehicles[vehicleId]) {
          outputVehicles.push(this.vehiclesCache[vehicleId]);
        }
      }
    }

    return outputVehicles;
  }

  public updateVehicle(vehicleId: string, body: object) {
    return this.apiService.put(this.endpoints.vehicleByID(vehicleId), undefined, body).pipe(
      map((response: any) => {
        const vehicle = response.vehicle;
        this.onVehicleDetailUpdated.emit(vehicle);
        return vehicle;
      }),
    );
  }

  public forceRouteChange(routeId: string, body: string) {
    this.apiService.put(this.endpoints.forceRouteChange(routeId), undefined, body).subscribe((response: any) => {
      this.onForcedRouteChange.emit(response.data);
    });
  }

  public getLabelSymbolAndClassForVehicleState(vehicle: Vehicle) {
    if (!vehicle) {
      return [' ', 'Unknown', ''];
    }

    switch (vehicle.state) {
      case VehicleState.Idle:
        return ['I', this.i18next.t('service.vehicles.label.idle'), 'default'];
      case VehicleState.Available:
        return ['A', this.i18next.t('service.vehicles.label.available'), 'success'];
      case VehicleState.End:
        return ['E', this.i18next.t('service.vehicles.label.end'), 'default'];
      case VehicleState.Breakdown:
        return ['B', this.i18next.t('service.vehicles.label.breakdown'), 'danger'];
      case VehicleState.Unavailable:
        const now = new Date();
        const lastUpdatedAt = new Date(vehicle.updated_at);
        const lastLocationUpdatedAt = new Date(vehicle.location_updated_at);

        const lastUpdatedElapsedTimeInMinute = Math.floor((now.getTime() - lastUpdatedAt.getTime()) / 1000 / 60);
        if (lastUpdatedElapsedTimeInMinute > 5) {
          return ['W', this.i18next.t('service.vehicles.label.websocket_issue'), 'danger'];
        }

        const lastLocationUpdatedAtElapsedTimeInMinute = Math.floor(
          (now.getTime() - lastLocationUpdatedAt.getTime()) / 1000 / 60,
        );
        if (vehicle.gps_failure || lastLocationUpdatedAtElapsedTimeInMinute > 5) {
          return ['G', this.i18next.t('service.vehicles.label.gps_issue'), 'danger'];
        }

        return ['U', this.i18next.t('service.vehicles.label.unavailable'), 'danger'];
      default:
        return [' ', this.i18next.t('service.vehicles.label.unknown'), ''];
    }
  }

  public getLabelSymbolAndClassForVehicleStateByVehicleId(vehicleId: string) {
    if (!this.vehiclesCache[vehicleId]) {
      return [' ', ' Unknown', ' '];
    }

    return this.getLabelSymbolAndClassForVehicleState(this.vehiclesCache[vehicleId]);
  }

  public setLabelClassDurationForVehicleBreakStatus(vehicle: Vehicle) {
    vehicle.breakStatusLabel = '';
    vehicle.breakStatusClassType = 'default';
    vehicle.breakStatusTooltip = '';

    if (!vehicle || !vehicle.driver_break_status) {
      return;
    }

    const status = vehicle.driver_break_status;

    // for 'driving' state, startTime is the start time of the latest driving trip
    // whereas status.driving_duration contains the total driving time aggregated over multiple trips
    // since the start or the last breakdown. For the UI, the aggregated driving time
    // should be displayed.
    const tooltip: string = `${this.i18next.t('service.vehicles.label.driving')}: ${this.utilityService.formatDuration(
      status.driving_duration,
    )}`;

    const transitState = status.transit_state;
    const formattedDuration = this.utilityService.formatTimeLapse(status.transit_state_start);

    if (transitState === 'hot_standby') {
      vehicle.breakStatusLabel = this.i18next.t('service.vehicles.label.stand_by');
      vehicle.breakStatusClassType = 'warning';
      vehicle.breakStatusTooltip = tooltip + vehicle.breakStatusLabel + ': ' + formattedDuration;
      return;
    }

    if (transitState === 'break') {
      vehicle.breakStatusLabel = this.i18next.t('service.vehicles.label.break');
      vehicle.breakStatusClassType = 'warning';
      vehicle.breakStatusTooltip = tooltip + vehicle.breakStatusLabel + ': ' + formattedDuration;
      return;
    }

    if (transitState === 'preparation') {
      vehicle.breakStatusLabel = this.i18next.t('service.vehicles.label.preparation');
      vehicle.breakStatusClassType = 'info';
      vehicle.breakStatusTooltip = tooltip + vehicle.breakStatusLabel + ': ' + formattedDuration;
      return;
    }

    vehicle.breakStatusLabel = this.i18next.t('service.vehicles.label.driving');
    vehicle.breakStatusClassType = 'success';
    vehicle.breakStatusTooltip = tooltip;
  }

  // these vehicles have been hydrated with driver, route, route's pickups and fixed route instances
  public getBacchiServiceVehicles(bacchusIds: string[], getStats: boolean) {
    const params = {
      bacchi: bacchusIds,
      stats: getStats ? '1' : '0',
    };

    return this.apiService.get(this.endpoints.serviceVehicles, params).pipe(
      map((response: any) => {
        return response.vehicles as Vehicle[];
      }),
    );
  }

  public syncVehiclesWithDriverSchedules(bacchusId: string, showMessage = true) {
    return this.apiService
      .post(
        this.endpoints.syncDriverSchedules(bacchusId),
        undefined,
        undefined,
        undefined,
        showMessage ? 'Driver schedule synced!' : '',
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  public async syncVehiclesWithDriverSchedulesV2(bacchusId: string, projectId: string) {
    const result = await this.apiService
      .post(
        this.endpoints.syncDriverSchedulesV2,
        undefined,
        {
          project_id: projectId,
          service_session_id: bacchusId,
        },
        undefined,
        '',
      )
      .pipe(
        map((response: any) => {
          return response.total as number;
        }),
      )
      .toPromise();

    await delay(2000);
    return result;
  }

  public createFixedRoutesVehiclesFromDriverSchedules(bacchusId: string, showMessage = true) {
    return this.apiService
      .post(
        this.endpoints.syncFixedRouteSchedules(bacchusId),
        undefined,
        undefined,
        undefined,
        showMessage ? 'Fixed routes created!' : '',
      )
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }

  public removeFixedRouteInstance(fixedRouteInstanceId: string, vehicleId: string) {
    return this.apiService
      .post(this.endpoints.removeVehiclesFixedRouteInstance(vehicleId, fixedRouteInstanceId))
      .pipe(map(response => response.vehicle));
  }

  public sendNotifications(vehicleId: string, body: SendNotificationsPayload) {
    return this.apiService
      .post(this.endpoints.sendNotifications(vehicleId), undefined, body)
      .pipe(map(response => response.status));
  }

  public getVehiclePickups(vehicleId: string, states: PickupState[]) {
    const params: { [key: string]: string } = {};
    if (states && states.length > 0) {
      params.states = states.join(',');
    }

    return this.apiService
      .get(this.endpoints.pickupsForVehicle(vehicleId), params)
      .pipe(map(response => response.pickups));
  }

  public getReverseGeocode(lat: number, lon: number, buffer: number) {
    const params = { lat, lon, buffer };
    return this.apiService.get(this.endpoints.reverseGeoCode, params).pipe(map(response => response.geocode_info));
  }

  public getDriversForBacchi(bacchusIDs: string[]) {
    const params = { bacchus_ids: bacchusIDs.join(',') };
    return this.apiService.get(this.endpoints.driversForBacchi, params).pipe(map(response => response.drivers));
  }

  public getDriversForBacchiV2(bacchusID: string) {
    const params = { 'service_template_group_ids[]': bacchusID, all: true, status: 'normal' };
    return this.apiService.get(this.endpoints.driversForBacchiV2, params).pipe(
      map(response => {
        const results = response.results as Array<{ driver: DriverV2 }>;
        const drivers = results.map(r => r.driver);

        // Hack for bindLabel to work with ng-select
        drivers.forEach(d => {
          d.name_ = `${d.name.first} ${d.name.last}`;
        });
        return drivers;
      }),
    );
  }

  public usersForVehicle(vehicleId: string) {
    return this.apiService.get(this.endpoints.usersForVehicle(vehicleId)).pipe(map(response => response.users));
  }

  public getPhysicalVehiclesForBacchus(bacchusID: string) {
    return this.apiService
      .get(this.endpoints.physicalVehicleForBacchus(bacchusID))
      .pipe(map(response => response.physical_vehicles));
  }

  public getPhysicalVehiclesForVehicle(vehicleId: string) {
    return this.apiService
      .get(this.endpoints.physicalVehicleForVehicle(vehicleId))
      .pipe(map(response => response.physical_vehicles));
  }

  public findConflictVehicles(
    driverID: string,
    startTime: string,
    originalVehicle: Vehicle,
    serviceSession: Bacchus,
  ): Observable<SimpleVehicleModel[]> {
    return this.apiService
      .get(this.endpoints.conflictVehicles, {
        driver_id: driverID,
        start_time: startTime,
      })
      .pipe(
        map(response => {
          const allVehicles: SimpleVehicleModel[] = response.vehicles;

          const { id } = originalVehicle;

          const conflictVehicles = allVehicles.filter(
            vehicle =>
              vehicle.id !== id &&
              // New logic
              (moment(vehicle.start_time).isSameOrAfter(serviceSession.operating_start_time) ||
                moment(vehicle.end_time).isSameOrBefore(serviceSession.operating_end_time)),
          );
          return conflictVehicles;
        }),
        catchError(() => of([])),
      );
  }

  public findOverlapVehicles(
    driverID: string,
    originalVehicleID: string,
    startTime: string,
    endTime: string,
  ): Observable<SimpleVehicleModel[]> {
    return this.apiService
      .get(this.endpoints.overlapVehicles, {
        driver_id: driverID,
        start_time: startTime,
        end_time: endTime,
        vehicle_session_id: originalVehicleID,
      })
      .pipe(
        map(response => {
          const overlapVehicles: SimpleVehicleModel[] = response.vehicles;
          return overlapVehicles;
        }),
        catchError(() => of([])),
      );
  }

  public breakdownVehicle(vehicleId: string, body: VehicleBreakdownPayload) {
    return this.apiService
      .put(this.endpoints.breakDownVehicle(vehicleId), undefined, body)
      .pipe(map(response => response.status));
  }

  public async deleteVehicle(vehicleId: string, isForce?: boolean) {
    const result = await this.apiService
      .delete(this.endpoints.deleteVehicleV2(vehicleId), isForce ? { mode: 'force' } : undefined, undefined, '')
      .toPromise();
    await delay(2000); // Lol, wait for sync

    return !!result;
  }

  public async getVehicleSessionsBookings(vehicleId: string) {
    const result = await this.apiService
      .delete(this.endpoints.deleteVehicleV2(vehicleId), { mode: 'check' }, undefined, '')
      .toPromise();

    return result;
  }

  public async bulkCancelBookings(vehicleId: string) {
    const result = await this.apiService.put(this.endpoints.bulkCancelBookings(vehicleId)).toPromise();

    return result;
  }

  public async subscribeBacchusVehicles(bacchus: Bacchus) {
    const bacchusId = bacchus.id;

    const ws = await this.userService.createWebsocket<{ data: { vehicle: Vehicle } }>(
      `vehicle.update_slim.*.${bacchusId}`,
    );
    const wsSub = ws.subscribe(msg => {
      const vehicle = msg.data.vehicle;
      vehicle.bacchus = bacchus; // In vehicle.update_slim, we remove bacchus to reduce message size

      // NOTE: this vehicle update DOES NOT come with pickup stats, break status and driver hydration
      this.updateVehicleCacheWithVehicles([vehicle], true, true);
      this.pickupService.updatePickupCacheWithVehicles([vehicle]);
      this.onVehicleUpdated.emit([vehicle.bacchus_id]);
    });

    this.vehicleUpdateWSs[bacchusId] = ws;
    this.vehicleUpdateWSSubs[bacchusId] = wsSub;
  }

  public unsubscribeBacchusVehicles(bacchusId: string) {
    this.vehicleUpdateWSSubs[bacchusId]?.unsubscribe();
    this.vehicleUpdateWSs[bacchusId]?.complete();
  }

  public toggleInvisibleVehicles(vehicleId: string) {
    if (this.invisibleVehicles[vehicleId]) {
      delete this.invisibleVehicles[vehicleId];
    } else {
      this.invisibleVehicles[vehicleId] = true;
    }
  }

  private async initializeService() {
    this.driverBreakWS = await this.userService.createWebsocket('v1.poseidon.*.driver_break_status.update');
    this.driverBreakWSSub = this.driverBreakWS.subscribe((msg: any) => {
      const data = msg.data;
      const vehicleId = data.vehicle_id;
      const status: DriverBreakStatus = data.driver_break_status;
      if (this.vehiclesCache[vehicleId]) {
        this.vehiclesCache[vehicleId].driver_break_status = status;
        this.setLabelClassDurationForVehicleBreakStatus(this.vehiclesCache[vehicleId]);
        this.onVehicleUpdated.emit([this.vehiclesCache[vehicleId].bacchus_id]);
      }
    });

    this.pickupService.onPickUpUpdated.subscribe((pickup: Pickup) => {
      if (!pickup.vehicle_id || pickup.vehicle_id.length === 0) {
        return;
      }
      if (
        pickup.state === PickupState.CancelledByUser || // this means a change in cancelled count
        pickup.state === PickupState.Enroute || // this means a change in boarded count
        pickup.state === PickupState.FailedToBoard || // this means a change in missed count
        pickup.state === PickupState.Completed || // this means a change in arrived count
        // this and below means a change in on_demand_count
        ((pickup.pickup_type === 'on_demand' || pickup.pickup_type === 'on_demand_fixed') &&
          pickup.state === PickupState.WaitingForVehicle)
      ) {
        this.onGetVehicleStat.next(pickup.vehicle_id);
      }
    });
  }
}
