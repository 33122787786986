<div class="container-fluid">
  <div class="container">
    <div class="login-panel">
      <div class="alert alert-danger" role="alert" *ngIf="loginErrorMessage">
        {{loginErrorMessage}}
      </div>
      <div class="form-container">
        <h4 class="login-title text-center">
          Log In
        </h4>

        <div class="input-group login-input-group">
          <span class="input-group-addon">
            <i class="fa fa-envelope text-secondary"></i>
          </span>
          <input type="text" class="form-control no-validate" placeholder="Email" [(ngModel)]="email" />
        </div>
        <div class="input-group login-input-group">
          <span class="input-group-addon">
            <i class="fa fa-key text-secondary"></i>
          </span>
          <input type="password" class="form-control no-validate" placeholder="Password" [(ngModel)]="password" (keyup.enter)="loginSWAT()"/>
        </div>
  
        <button class="btn btn-primary login-button" [class.disabled]="isLoggingIn" (click)="loginSWAT()">
          <div *ngIf="!isLoggingIn">Login</div>
          <div *ngIf="isLoggingIn">Logging In...</div>
        </button>
  
        <div *ngIf="allowAuth0Login()">
          <div class="or-label text-secondary">- or -</div>
          <div>
            <button class="btn btn-default login-button" (click)="showLoginPage()">
              Login via Auth0
            </button>
          </div>
        </div>

      </div>

      <div class="forgot-password-container">
        <a [routerLink]="['/reset_password']">Forgot Password?</a>
      </div>
    </div>
  </div>
</div>