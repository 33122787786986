import { AfterViewInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { LoginService } from '@app/login/services/login.service';
import { env } from '@env/environment';
import * as auth0 from 'auth0-js';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['../login.scss'],
})
export class LoginComponent implements AfterViewInit {
  public webAuth: any;
  public lock: any;
  public email: string = null;
  public password: string = null;
  public loginErrorMessage: string = null;
  public isLoggingIn: boolean = false;

  constructor(
    private userService: UserService,
    private auth: AuthService,
    private login: LoginService,
    private router: Router,
  ) {
    if (env.allowedManageLogin) {
      this.router.navigate(['/manage', 'login']);
    }

    if (auth.isAuthenticatedWithAuth0()) {
      this.router.navigate(['/services']);
    }
  }

  public ngAfterViewInit() {
    this.resetCredentials();
    this.webAuth = new auth0.WebAuth({
      domain: 'swatmobile.au.auth0.com',
      clientID: 'cXb5MkH8NiuH79NgTBp7o5bW0brB33aS',
    });
  }

  public resetCredentials() {
    this.email = null;
    this.password = null;
    this.loginErrorMessage = null;
    this.isLoggingIn = false;
  }

  public resetPasswordField() {
    this.password = null;
    this.isLoggingIn = false;
  }

  public loginSWAT() {
    this.loginErrorMessage = null;

    if (!this.email || this.email.length === 0) {
      this.loginErrorMessage = 'Please enter your email address.';
      return;
    }

    if (!this.password || this.password.length === 0) {
      this.loginErrorMessage = 'Please enter your password.';
      return;
    }

    this.isLoggingIn = true;

    this.userService.userLogin(this.email, this.password).subscribe(
      (response: any) => {
        this.auth.setLoginSession(response);
        this.userService.getUserSession().subscribe(
          (session: any) => {
            const context = JSON.parse(session.context);
            this.auth.setSessionContext(context);
            this.resetCredentials();
            document.location.reload();
          },
          (err: any) => {
            this.loginErrorMessage = 'Something went wrong. Please try again.';
            this.resetPasswordField();
          },
        );
      },
      (err: any) => {
        if (err.code) {
          switch (err.code) {
            case this.userService.ACCOUNT_LOCKED_CODE:
              this.loginErrorMessage =
                // tslint:disable-next-line:max-line-length
                'Login Disabled: Too many invalid login attempts. Please reset your password.';
              break;
            case this.userService.INVALID_LOGIN_CREDENTIALS_CODE:
              const loginAttemptsLeft = err.data.login_attempts_left;
              this.loginErrorMessage =
                // tslint:disable-next-line:max-line-length
                `Login Failed: Incorrect email or password. You have ${loginAttemptsLeft} attempts remaining before your account is locked.`;
              break;
            case this.userService.ACCOUNT_INACTIVE_CODE:
              this.loginErrorMessage =
                // tslint:disable-next-line:max-line-length
                'Login Disabled: This account is currently inactive. Please contact your administrator to re-activate the account.';
              break;
            case this.userService.PASSWORD_EXPIRED_CODE:
              this.loginErrorMessage =
                // tslint:disable-next-line:max-line-length
                'Password Expired: You have to change your password every 1 year. Please reset your password.';
              break;
          }
        } else {
          this.loginErrorMessage = 'Something went wrong. Please try again.';
        }
        this.resetPasswordField();
      },
    );
  }

  public showLoginPage() {
    const options = {
      responseType: 'token id_token',
      audience: 'https://swatmobile.au.auth0.com/userinfo',
      scope: 'openid',
      redirectUri: this.userService.redirectUrl(),
    };

    this.webAuth.popup.authorize(options, (err: any, authResult: any) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.auth.setAuth0Session(authResult);
        this.userService.getUserSession().subscribe((session: any) => {
          const context = JSON.parse(session.context);
          this.auth.setSessionContext(context);
          this.login.redirect('/services');
        });
      } else if (err) {
        // tslint:disable-next-line: no-console
        console.log(err);
      }
    });
  }

  public allowAuth0Login(): boolean {
    return env.allowedAuth0Login;
  }
}
