<div class="container-fluid">
  <div class="container">
    <div class="login-panel">
      <div class="alert alert-danger" role="alert" *ngIf="resetPasswordErrorMessage">
        {{resetPasswordErrorMessage}}
      </div>
      <div class="alert alert-success" role="alert" *ngIf="resetPasswordSuccessMessage">
        {{resetPasswordSuccessMessage}}
      </div>
      <div class="form-container">
        <h4 class="login-title">
          Password Reset
        </h4>
        <div *ngIf="!mobile && !resetPasswordSuccessMessage">
          <div class="or-label" style="margin-bottom: 30px; width: 400px;">
            Enter your email below to generate a one-time passcode (OTP) sent to the mobile number linked to your
            account.
          </div>
          <div class="input-group login-input-group">
            <span class="input-group-addon">
              <i class="fa fa-envelope text-secondary"></i>
            </span>
            <input type="text" class="form-control no-validate" placeholder="Email" [(ngModel)]="email"
              [ngModelOptions]="{standalone: true}" />
          </div>

          <button class="btn btn-primary login-button" [class.disabled]="isGeneratingOTP" (click)="requestOTP()">
            <div *ngIf="!isGeneratingOTP">Generate OTP</div>
            <div *ngIf="isGeneratingOTP">Generating OTP...</div>
          </button>
        </div>

        <div *ngIf="mobile && !resetPasswordSuccessMessage" [formGroup]="resetPasswordForm">
          <div class="or-label" style="margin-bottom: 30px; width: 400px;">
            A one-time-passcode (OTP) has been sent to <strong>{{mobile}}</strong>. Please enter the OTP below to verify
            your account.
          </div>

          <div class="input-group login-input-group">
            <span class="input-group-addon">
              <i class="fa fa-lock text-secondary"></i>
            </span>
            <input type="text" class="form-control no-validate" placeholder="OTP" [(ngModel)]="otp"
              [ngModelOptions]="{standalone: true}" />
            <a type="button" class="resend-link"
              [ngClass]="{'active': !isGeneratingOTP && otpTimeLeft <= 0, 'disabled': isGeneratingOTP || otpTimeLeft > 0}"
              (click)=requestOTP()>
              <div *ngIf="!isGeneratingOTP && otpTimeLeft <= 0">Resend</div>
              <div *ngIf="!isGeneratingOTP && otpTimeLeft > 0">... {{otpTimeLeft}}s</div>
              <div *ngIf="isGeneratingOTP">Resending...</div>
            </a>
          </div>

          <div class="or-label" style="margin-top: 20px; margin-bottom: 30px; width: 400px;">
            Create a password that is at least 12 characters and includes 1 uppercase letter, 1 lowercase letter and 1
            number.
          </div>

          <div class="input-container">
            <div class="input-group login-input-group">
              <span class="input-group-addon">
                <i class="fa fa-key text-secondary"></i>
              </span>
              <input type="password" [formControl]="passwordControl" class="form-control no-validate"
                placeholder="Password" />
            </div>
            <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)"
              class="password-validation-alerts alert alert-danger">
              <div>Must satisfy the following:</div>
              <ul>
                <li *ngIf="passwordControl.errors.required || passwordControl.errors.minlength">
                  At least 12 characters long
                </li>
                <li *ngIf="passwordControl.errors.upper">
                  Contains at least 1 upper case character
                </li>
                <li *ngIf="passwordControl.errors.lower">
                  Contains at least 1 lower case character
                </li>
                <li *ngIf="passwordControl.errors.digit">
                  Contains at least 1 digit
                </li>
              </ul>
            </div>
          </div>

          <div class="input-container">
            <div class="input-group login-input-group">
              <span class="input-group-addon">
                <i class="fa fa-key text-secondary"></i>
              </span>
              <input type="password" [formControl]="repeatPasswordControl" class="form-control no-validate"
                placeholder="Repeat Password" />
            </div>
            <div *ngIf="(repeatPasswordControl.dirty || repeatPasswordControl.touched)">
              <div
                *ngIf="(repeatPasswordControl.invalid && repeatPasswordControl.errors.required) || (resetPasswordForm.invalid && resetPasswordForm.errors && resetPasswordForm.errors.confirm)"
                class="password-validation-alerts alert alert-danger">
                Passwords do not match
              </div>
            </div>
          </div>

          <button class="btn btn-primary login-button" style="margin-top: 20px;"
            [class.disabled]="isResettingPassword || passwordControl.invalid || repeatPasswordControl.invalid"
            (click)="resetPassword()">
            <div *ngIf="!isResettingPassword">Reset Password</div>
            <div *ngIf="isResettingPassword">Resetting Password...</div>
          </button>
        </div>

        <div *ngIf="resetPasswordSuccessMessage">
          <a type="button" class="btn btn-primary login-button" [routerLink]="['/login']">
            Back to Log In
          </a>
        </div>
      </div>

      <div class="forgot-password-container">
        <a class="forgot-password-link" [routerLink]="['/login']">Cancel</a>
      </div>
    </div>
  </div>
</div>