export enum PickupState {
  New = 'new',
  Pending = 'pending',
  Prebooked = 'prebooked',
  Enroute = 'enroute',
  Aborted = 'aborted',
  WaitingForVehicle = 'waiting_for_vehicle',
  FailedToBoard = 'failed_to_board',
  CancelledByUser = 'cancelled_by_user',
  CancelledByOperator = 'cancelled_by_operator',
  Completed = 'completed',
  BlockingNoOffers = 'blocking_no_offers',
  NoReplacement = 'no_replacement',
  GotReplacement = 'got_replacement',
  AcceptedOffer = 'accepted_offer',
  RequestOffer = 'requested_offer',
  NoOffer = 'no_offers',
  TimedOut = 'timed_out',
}

export interface Pickup {
  id: string;
  created_at: Date;
  vehicle_id: string;
  bacchus_id: string;
  pickup_type: string;
  state: PickupState;
  passenger_count: number;
  pickup_location_name: string;
  pickup_location_lat: number;
  pickup_location_lon: number;
  dropoff_location_name: string;
  dropoff_location_lat: number;
  dropoff_location_lon: number;
  departure_time: Date | string;
  destination_time: Date | string;
  user_id: string;

  user_name: string;
  user_mobile: string;

  bacchus_name: string;
  bacchus_status: string;
  bacchus_settings_json: string;
  bacchus_start_time: Date;
  bacchus_end_time: Date;

  offer_pickup_time?: Date | string;
  offer_dropoff_time?: Date | string;
  live_pickup_time: Date;
  live_dropoff_time: Date;

  offer_price: number;

  vehicle_number: string;
  service_number: string;
  driver_name: string;
  driver_username: string;

  bacchus_settings: any;

  wheelchair_count: number; // this is temporary

  // incident management helper
  incident_action: string; // heal, replace, abort, none

  // send notifications helper
  to_notify: boolean;

  demand: Demand;
  adhoc_demand: Demand;

  is_replaced: boolean;
  accepted_offer_id: string;

  board_time: Date;
  dropoff_time: Date;

  origin: string;
  remarks: string | null;

  offer_pickup_location?: string;
  offer_dropoff_location?: string;
  offer_pickup_location_type?: string;
  offer_dropoff_location_type?: string;
  offer_pickup_location_street_name?: string;
  offer_dropoff_location_street_name?: string;
  offer_pickup_location_lat?: number;
  offer_pickup_location_lon?: number;
  offer_dropoff_location_lat?: number;
  offer_dropoff_location_lon?: number;

  estimated_departure_time: Date | string;
  estimated_destination_time: Date | string;
}

export interface Demand {
  passenger: number;
  wheelchair: number;
}

export interface TripHistory extends Pickup {
  tickets?: any;
  updated_at: Date;
  program_id?: any;
  request_offer_time: Date;
  reject_time: Date;
  cancel_time: Date;
  offer_accept_time: Date;
  vehicle_arrival_time: Date;
  board_time: Date;
  dropoff_time: Date;
  abort_time: Date;
  ready_to_board_time?: any;
  fail_to_board_time?: any;
  transaction_id?: any;
  walking_distance: number;
  pickup_type: string;
  is_auto_accept_offer: boolean;
  notifications?: any;
  origin: string;
  origin_id: string;
  user_id: string;
  user_info?: any;
  offer?: any;
  preferred_pickup_stop_id: string;
  preferred_dropoff_stop_id: string;
  passenger_schedule_id?: any;
  rating: number;
  breakdown_reason_type: string;
  breakdown_reason_detail: string;
  offer_waiting_time: number;
  actual_waiting_time: number;
  offer_pickup_time: Date;
  offer_dropoff_time: Date;
  offer_journey_time: number;
  journey_time: number;
  trip_rating: number;
  feedback: string;
  remarks: string | null;
}

export interface PickupRequest {
  passenger_id: string;
  replace?: boolean;
  zeus_ids: string[];
  pickup_data: {
    bacchus_id: string;

    pickup_location_name: string;
    pickup_location_lat: number;
    pickup_location_lon: number;
    dropoff_location_name: string;
    dropoff_location_lat: number;
    dropoff_location_lon: number;
    preferred_pickup_stop_id: string;
    preferred_dropoff_stop_id: string;

    request_time: Date;
    departure_time?: Date | string;
    destination_time?: Date | string;

    demand: SeatCount;
    origin: string;

    version: string;
    remarks?: string;

    is_auto_accept_offer: boolean;
  };
}

export interface SeatCount {
  passenger: number;
  wheelchair: number;
}

export interface Tickets {
  type: string;
  seat_count_type: string;
  demand: number;
}

export function getPaxCountStrings(pickup: Pickup | TripHistory) {
  if (!pickup) {
    return {};
  }

  const plannedPax = pickup.demand.passenger ? pickup.demand.passenger : 0;
  const plannedWc = pickup.demand.wheelchair ? pickup.demand.wheelchair : 0;
  const actualPax = plannedPax + (pickup.adhoc_demand.passenger ? pickup.adhoc_demand.passenger : 0);
  const actualWc = plannedWc + (pickup.adhoc_demand.wheelchair ? pickup.adhoc_demand.wheelchair : 0);

  const actualWcStr = actualWc > 0 ? '+' + actualWc.toString() : '';

  return { actualPax, actualWc: actualWcStr };
}
