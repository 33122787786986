import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import sweetalert2 from 'sweetalert2';

interface AlertOption {
  title?: string;
  text?: string;
  html?: string;
  noTranslate?: boolean;
  titleOption?: any;
  textOption?: any;
  confirmButton?: string;
  cancelButton?: string;
}

@Injectable()
export class AlertService {
  constructor(@Inject(I18NEXT_SERVICE) private i18n: ITranslationService) {}

  public success({ title, text, html, noTranslate, titleOption, textOption }: AlertOption) {
    sweetalert2({
      title: noTranslate ? title : this.i18n.t(title, titleOption),
      text: noTranslate ? text : this.i18n.t(text, textOption),
      type: 'success',
      html,
    });
  }

  public info({ title, text, html, noTranslate, titleOption, textOption }: AlertOption) {
    sweetalert2({
      title: noTranslate ? title : this.i18n.t(title, titleOption),
      text: noTranslate ? text : this.i18n.t(text, textOption),
      html,
      type: 'info',
    });
  }

  public error({ title, text, html, noTranslate, titleOption, textOption }: AlertOption) {
    sweetalert2({
      title: noTranslate ? title : this.i18n.t(title, titleOption),
      text: noTranslate ? text : this.i18n.t(text, textOption),
      html,
      type: 'error',
      width: '45rem',
    });
  }

  public warning({ title, text, html, noTranslate, titleOption, textOption }: AlertOption) {
    sweetalert2({
      title: noTranslate ? title : this.i18n.t(title, titleOption),
      text: noTranslate ? text : this.i18n.t(text, textOption),
      html,
      type: 'warning',
    });
  }

  public async confirm({
    title,
    text,
    html,
    noTranslate,
    titleOption,
    textOption,
    confirmButton = 'button.yes',
    cancelButton = 'button.no',
  }: AlertOption): Promise<boolean> {
    const result = await sweetalert2({
      title: noTranslate ? title : this.i18n.t(title, titleOption),
      text: noTranslate ? text : this.i18n.t(text, textOption),
      html,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: this.i18n.t(confirmButton),
      cancelButtonText: this.i18n.t(cancelButton),
      width: '52rem',
      reverseButtons: true,
    });

    return result.value;
  }
}
