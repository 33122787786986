export class Collection<T = any> {
  public items: T[];
  public count: number;
  public page: number;
  public limit: number;
  public q: string;
  public numPages: number;

  public totalPages() {
    return this.numPages || Math.ceil(this.count / this.limit) || 0;
  }

  public pages() {
    const numberOfPages = this.totalPages();
    if (numberOfPages > 0) {
      // tslint:disable-next-line
      return new Array(numberOfPages);
    }

    // tslint:disable-next-line
    return new Array(1);
  }

  public firstEntry() {
    return (this.page - 1) * this.limit + 1;
  }

  public lastEntry() {
    const last = this.page * this.limit;
    return this.count < last ? this.count : last;
  }

  public nextPage() {
    const n = this.page + 1;
    return n > this.totalPages() ? this.totalPages() : n;
  }

  public previousPage() {
    const n = this.page - 1;
    return n < 1 ? 1 : n;
  }
}
