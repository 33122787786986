import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { ScopeService } from '@app/core/services/scope.service';
import { UserService } from '@app/core/services/user.service';
import { Collection } from '@app/shared/models/collection';
import { TripHistory } from '@app/shared/models/pickup';
import { FavoritePlace, Place } from '@app/shared/models/place';
import { Transaction } from '@app/shared/models/transactions';
import { User } from '@app/shared/models/user';
import { env } from '@env/environment';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PassengerService {
  public defaultCurrency: string = env.defaultCurrency;
  public onPassengerSelected = new ReplaySubject<string>();
  public onTransactionChanged = new ReplaySubject();
  public onBalanceChanged = new ReplaySubject();

  private readonly endpoints = {
    users: `${this.userService.baseUrl()}/users/organizations`,
    refund: `${this.userService.baseUrl()}/transactions/refund`,
    transactions: `${this.userService.baseUrl()}/transactions`,
    transactionsForUser: (id: string) => `${this.userService.baseUrl()}/users/${id}/transactions`,
    usersByID: (id: string) => `${this.userService.baseUrl()}/users/${id}`,
    customerInfoByID: (id: string) => `${this.userService.baseUrl()}/users/${id}/customer`,
    removePaymentMethod: (userID: string) => `${this.userService.baseUrl()}/users/${userID}/remove_payment_method`,
    transactionCSV: (userID: string) => `${this.userService.baseUrl()}/users/${userID}/transaction_csv`,
    pickupRefund: (id: string) => `${this.userService.baseUrl()}/pickups/${id}/refund`,
    transactionsByReceipt: (id: string) => `${this.userService.baseUrl()}/pickups/receipt/${id}/transactions`,
    receipt: (id: string) => `${this.userService.baseUrl()}/pickups/receipt/${id}`,
    tripHistoryForUser: (userID: string) => `${this.userService.baseUrl()}/users/${userID}/trip_history`,
    favoritePlaces: (userID: string) => `${this.userService.baseUrl()}/users/${userID}/favorite_places`,
  };
  constructor(private apiService: APIService, private userService: UserService, private scopeService: ScopeService) {}

  public getTransactionsForUser(userId: string, limit: number, page: number) {
    const params = {
      l: limit,
      p: page,
      all: false,
      count: true,
    };

    return this.apiService.get(this.endpoints.transactionsForUser(userId), params).pipe(
      map((response: any) => {
        const collection = new Collection();
        collection.items = response.transactions;
        collection.count = response.transactions_meta.count;
        collection.limit = response.transactions_meta.limit;
        collection.page = response.transactions_meta.page;
        return collection;
      }),
    );
  }

  public refundTransaction(transactionId: string) {
    const body = JSON.stringify({ transaction_id: transactionId });
    return this.apiService.post(this.endpoints.refund, undefined, body).pipe(
      map((response: any) => {
        return response.transaction;
      }),
    );
  }

  public getUser(userId: string): Observable<User> {
    return this.apiService.get(this.endpoints.usersByID(userId)).pipe(map(response => response.user));
  }

  public getCustomerInfoForUser(userId: string) {
    return this.apiService.get(this.endpoints.customerInfoByID(userId)).pipe(
      map((response: any) => {
        return response.customer;
      }),
    );
  }

  public removePaymentMethodForUser(userId: string) {
    return this.apiService.get(this.endpoints.removePaymentMethod(userId));
  }

  public addManualCredits(userId: string, amount: number) {
    const body = JSON.stringify({
      amount,
      user_id: userId,
      currency: this.defaultCurrency,
    });

    return this.apiService.post(this.endpoints.transactions, undefined, body);
  }

  public downloadTransactionCsv(userId: string) {
    return this.apiService.get(this.endpoints.transactionCSV(userId)).pipe(
      map((response: any) => {
        return {
          type: response.headers,
          body: response.arrayBuffer(),
        };
      }),
    );
  }

  public getUsers(term: string, limit: number, page: number): Observable<Collection<User>> {
    const params = {
      q: term,
      l: limit,
      p: page,
      ...this.scopeService.projectScopeParam,
    };
    return this.apiService.get(this.endpoints.users, params).pipe(
      map((response: any) => {
        const collection = new Collection<User>();
        collection.items = response.user_info;
        collection.count = response.user_info_meta.count;
        collection.limit = response.user_info_meta.limit;
        collection.page = response.user_info_meta.page;
        collection.q = term;
        return collection;
      }),
    );
  }

  public searchUsers(term: string, bacchusID: string): Observable<User[]> {
    const params = { q: term, all: true, bacchus_id: bacchusID, ...this.scopeService.projectScopeParam };
    return this.apiService.get(this.endpoints.users, params).pipe(map((response: any) => response.user_info as User[]));
  }

  public updateUser(userID: string, body: string) {
    return this.apiService.put(this.endpoints.usersByID(userID), undefined, body).pipe(
      map((response: any) => {
        return response.user_info;
      }),
    );
  }

  public refund(pickupID: string) {
    return this.apiService.post(this.endpoints.pickupRefund(pickupID));
  }

  public getTransactionsByReceipt(pickupID: string): Observable<Transaction[]> {
    return this.apiService
      .get(this.endpoints.transactionsByReceipt(pickupID))
      .pipe(map(response => response.transactions));
  }

  public getReceipt(pickupID: string) {
    return this.apiService.get(this.endpoints.receipt(pickupID)).pipe(map(response => response.receipt));
  }

  public getTripHistoryForUser(userID: string, limit: number, page: number) {
    return this.apiService.get(this.endpoints.tripHistoryForUser(userID), { l: limit, p: page }).pipe(
      map((response: any) => {
        const collection = new Collection<TripHistory>();
        collection.items = response.triphistory;
        collection.count = response.triphistory_meta.count;
        collection.limit = response.triphistory_meta.limit;
        collection.page = response.triphistory_meta.page;
        return collection;
      }),
    );
  }

  public getFavoritePlaces(userID: string): Observable<FavoritePlace[]> {
    return this.apiService.get(this.endpoints.favoritePlaces(userID)).pipe(
      map((response: any) => {
        return response.places as FavoritePlace[];
      }),
      catchError(() => of([])),
    );
  }
}
