import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18NextModule } from 'angular-i18next';

import { FileDropDirective } from '@app/directives/file-drop.directive';
import { I18nDirective } from '@app/directives/i18n.directive';
import { VarDirective } from '@app/directives/ng-var.directive';
import { PermissionDirective } from '@app/directives/permission.directive';

import { NgSelectModule } from '@ng-select/ng-select';
import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { CalendarModule } from 'primeng/calendar';
import { InputSwitchModule } from 'primeng/inputswitch';

import { SliderModule } from 'primeng/slider';

import {
  DatetimePickerComponent,
  ItemsDropDownComponent,
  LiveRouteComponent,
  LoadingIndicatorComponent,
  LocationSelectComponent,
  PaginationComponent,
  ServiceSelectorComponent,
  UserSelectComponent,
} from './components';

import {
  DateRangePipe,
  DateTimePipe,
  FormattedTimeLapse,
  FullDatePipe,
  Time24Pipe,
  TimeRangePipe,
  TimestampPipe,
} from './pipes/custom-date.pipe';
import { DisplayNamePipe } from './pipes/display-name.pipe';
import { DriverInfoPipe } from './pipes/driver-info.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { MobilePipe } from './pipes/mobile.pipe';
import { OrderIconPipe } from './pipes/order.pipe';
import { ActualPaxPipe, PaxTooltipPipe } from './pipes/pax-count.pipe';
import { PickupDatePipe } from './pipes/pickup-date.pipe';
import { PickupLocationPipe } from './pipes/pickup-location.pipe';
import { ManageServiceNamePipe, ManageServiceSessionNamePipe } from './pipes/service-name.pipe';
import { ServicePipe } from './pipes/service.pipe';
import {
  CountPipe,
  PbiLabelPipe,
  PickupStateLabelPipe,
  PickupStateTranslatePipe,
  TitleCasePipe,
} from './pipes/state.pipe';
import { BalanceLabelPipe, CardPipe, PricePipe, RemarkPipe } from './pipes/transaction.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CapacityPipe } from './pipes/vehicle.pipe';
import { ZonePipe } from './pipes/zone.pipe';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FeatureFlagDirective } from '@app/directives/feature-flag.directive';
import { LegacyFlagDirective } from '@app/directives/legacy-flag.directive';
import { TabOrderDirective } from '@app/directives/tab-order.directive';
import { TabPermissionDirective, TabPermissionProjectDirective } from '@app/directives/tab-permission.directive';
import { JsonObjectImpurePipe, JsonObjectPipe, JsonTextPipe } from '@app/shared/pipes/json.pipe';
import { UserTripDetailsComponent } from '@app/users/components/user-trip-details/user-trip-details.component';
import { ImportCSVModalComponent } from './components/import-csv-modal/import-csv-modal.component';
import { PickupBookingFormComponent } from './components/pickup-booking-form/pickup-booking-form.component';
import { PickupsComponent } from './components/pickups/pickups.component';

@NgModule({
  imports: [
    AccordionModule,
    BsDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NKDatetimeModule,
    SliderModule,
    TimepickerModule,
    DatepickerModule,
    CollapseModule,
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    TooltipModule,
    TabsModule,
    TypeaheadModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    CalendarModule,
    I18NextModule,
    InputSwitchModule,
  ],
  declarations: [
    PaginationComponent,
    ServiceSelectorComponent,
    ItemsDropDownComponent,
    DatetimePickerComponent,
    LiveRouteComponent,
    UserTripDetailsComponent,
    LocationSelectComponent,
    UserSelectComponent,
    PickupsComponent,
    PickupBookingFormComponent,
    LoadingIndicatorComponent,
    ImportCSVModalComponent,

    DriverInfoPipe,
    DateTimePipe,
    DisplayNamePipe,
    Time24Pipe,
    ServicePipe,
    TitleCasePipe,
    MobilePipe,
    TruncatePipe,
    FormattedTimeLapse,
    RemarkPipe,
    CardPipe,
    ActualPaxPipe,
    PaxTooltipPipe,
    BalanceLabelPipe,
    PickupStateLabelPipe,
    PickupStateTranslatePipe,
    PricePipe,
    CountPipe,
    FullDatePipe,
    PbiLabelPipe,
    OrderIconPipe,
    ZonePipe,
    PickupLocationPipe,
    PickupDatePipe,
    DurationPipe,
    DateRangePipe,
    TimeRangePipe,
    TimestampPipe,
    CapacityPipe,
    JsonTextPipe,
    JsonObjectPipe,
    JsonObjectImpurePipe,
    ManageServiceNamePipe,
    ManageServiceSessionNamePipe,

    I18nDirective,
    FeatureFlagDirective,
    LegacyFlagDirective,
    FileDropDirective,
    PermissionDirective,
    TabPermissionDirective,
    TabPermissionProjectDirective,
    VarDirective,
    TabOrderDirective,
  ],
  exports: [
    PaginationComponent,
    ServiceSelectorComponent,
    ItemsDropDownComponent,
    UserSelectComponent,
    DatetimePickerComponent,
    LiveRouteComponent,
    UserTripDetailsComponent,
    LocationSelectComponent,
    PickupsComponent,
    PickupBookingFormComponent,
    LoadingIndicatorComponent,
    ImportCSVModalComponent,

    DriverInfoPipe,
    DateTimePipe,
    DisplayNamePipe,
    Time24Pipe,
    ServicePipe,
    TitleCasePipe,
    MobilePipe,
    TruncatePipe,
    FormattedTimeLapse,
    ZonePipe,
    PickupLocationPipe,
    DurationPipe,
    DateRangePipe,
    TimeRangePipe,
    TimestampPipe,
    CapacityPipe,
    ManageServiceNamePipe,
    ManageServiceSessionNamePipe,
    JsonTextPipe,
    JsonObjectPipe,
    JsonObjectImpurePipe,
    RemarkPipe,
    CardPipe,
    ActualPaxPipe,
    PaxTooltipPipe,
    BalanceLabelPipe,
    PickupStateLabelPipe,
    PickupStateTranslatePipe,
    PickupDatePipe,
    PricePipe,
    CountPipe,
    FullDatePipe,
    PbiLabelPipe,
    OrderIconPipe,

    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    CalendarModule,

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NKDatetimeModule,
    SliderModule,
    TimepickerModule,
    DatepickerModule,
    NgSelectModule,

    AccordionModule,
    BsDatepickerModule,
    CollapseModule,
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    TooltipModule,
    TabsModule,
    TypeaheadModule,
    I18NextModule,
    InputSwitchModule,

    I18nDirective,
    FeatureFlagDirective,
    LegacyFlagDirective,
    FileDropDirective,
    PermissionDirective,
    TabPermissionDirective,
    TabPermissionProjectDirective,
    VarDirective,
    TabOrderDirective,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
