<div class="container-fluid">
  <div class="container">
    <div class="login-panel">
      <div class="form-container">
        <h4 class="login-title" i18nKey="login.password_reset.title">
        </h4>
        <div class="or-label" style="margin-bottom: 30px; width: 400px;" i18nKey="login.password_reset.hint"> </div>
        <div class="input-group login-input-group">
          <span class="input-group-addon">
            <i class="fa fa-envelope text-secondary"></i>
          </span>
          <input type="text" class="form-control no-validate" placeholder="Email" [formControl]="formControl" />
        </div>

        <button class="btn btn-primary login-button"
          [disabled]="isSendingEmail || tokenRequested || formControl.invalid" (click)="requestToken()">
          {{getButtonText()}}
        </button>

      </div>
      <div class="forgot-password-container">
        <a class="forgot-password-link" [routerLink]="['/manage/login']" i18nKey="button.cancel"></a>
      </div>
    </div>
  </div>
</div>