export interface Permission {
  id: string;
  label: string;
  name: { [key: string]: string };
  description: { [key: string]: string };
}

export enum PermissionLabel {
  SCOPE_ADMIN = 'admin',

  manageApps = 'm_app_man',

  viewServices = 'view_services',
  manageServices = 'manage_services',
  viewServiceSessions = 'view_service_sessions',
  manageServiceSessions = 'manage_service_sessions',
  manageServiceSessionSettings = 'manage_service_session_settings',

  manageCoupons = 'm_coupons',

  showDrivers = 'm_drivers',
  manageDrivers = 'md_drivers',

  showDriverSchedules = 'md_schedules',
  manageDriverSchedules = 'mds_modify',

  showVehicles = 'view_vehicles',
  manageVehicles = 'manage_vehicles',

  showBookingCodes = 'view_booking_codes',
  manageBookingCodes = 'manage_booking_codes',

  showEmployeesLegacy = 'm_employees',
  manageEmployees = 'me_modify',

  showUsers = 'view_users',
  manageUsers = 'manage_users',

  showLive = 'm_live',
  manageServiceVehicles = 'ml_vehicles',

  showServicePickups = 'ml_pickups',
  manageServicePickups = 'mlp_create',

  viewSchedules = 'view_recurring_schedules',
  manageSchedules = 'manage_recurring_schedules',

  showRatings = 'm_trip_ratings',

  manageBreakDownVehicle = 'mlv_breakdown',

  showPassengersLegacy = 'm_passengers',
  viewPassengers = 'view_passengers',
  managePassengers = 'manage_passengers',

  showPassengerTrips = 'mp_trips',
  showPassengerProfile = 'mp_profile',
  showTransactions = 'mp_transactions',
  manageTransactions = 'mpt_refund',

  syncDriverSchedules = 'msbv_sync',
  setupFixedRoutes = 'msbv_setup',

  showIncidents = 'm_incidents',
  manageIncidents = 'mi_modify',

  showWaypoints = 'm_waypoints',

  viewMessages = 'view_messages',
  manageMessages = 'manage_messages',
}

export interface TabPermission {
  permission: string;
  route: string[];
  v2_route?: string;
}
