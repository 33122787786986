import { Injectable } from '@angular/core';
import { APINoAuthService } from '@app/core/services/api-no-auth.service';
import { APIService } from '@app/core/services/api.service';
import { ManageService } from '@app/core/services/manage.service';
import { Collection } from '@app/shared/models/collection';
import { DriverVehicleCost } from '@app/shared/models/driver';
import { Permission } from '@app/shared/models/manage-permission';
import { Role } from '@app/shared/models/manage-role';
import { User } from '@app/shared/models/manage-session';
import { RolePermission, UserRole } from '@app/shared/models/user';
import { Waypoint } from '@app/shared/models/waypoint';
import { Geofence } from '@app/shared/models/zeus';
import { env } from '@env/environment';
import delay from 'delay';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { webSocket } from 'rxjs/webSocket';

@Injectable()
export class UserService {
  public LOCALHOST = 'localhost';
  public DEV = 'dev';
  public SPARTA = 'sparta';
  public STAGING = 'staging';
  public MASTER = 'master';
  public PRODUCTION = 'production';

  public INVALID_SESSION_CODE = 1100;
  public JWT_EXPIRED_CODE = 1101;
  public ACCOUNT_INACTIVE_CODE = 1500;
  public ACCOUNT_LOCKED_CODE = 1501;
  public PASSWORD_EXPIRED_CODE = 1502;
  public INVALID_LOGIN_CREDENTIALS_CODE = 1503;
  public OTP_VALIDATION_FAILED_CODE = 1504;
  public PASSWORD_RECENTLY_USED_CODE = 1505;
  public PASSWORD_TOO_WEAK_CODE = 1506;
  public PASSWORD_CONTAINS_USER_INFO_CODE = 1507;

  public TICKET_EXPIRED_TIME_IN_SECONDS = 30;

  public hostedDomain: string;
  public deploymentEnvironment: string;
  public versionLabel: string;
  public isVersionMismatched: boolean;
  private usersUrl = '/users';
  private pickupsUrl = '/pickups';
  private waypointsUrl = '/waypoints';
  private fixedRoutesPaginatedUrl = '/fixed_routes_paginated';
  private vehiclesUrl = '/vehicles';
  private accessControlUrl = '/access_control';
  private incidentsUrl = '/incidents';

  private ticketCache: string = undefined;
  private lastTicketUpdated = moment();
  private isRequestingTicket = false;

  constructor(
    private apiService: APIService,
    private apiNoAuthService: APINoAuthService,
    private manageService: ManageService,
  ) {
    this.hostedDomain = env.hostedDomain;

    if (this.deployment() === 'localhost') {
      this.deploymentEnvironment = this.LOCALHOST;
      this.versionLabel = 'localhost';
    } else {
      this.getVersion().subscribe((response: any) => {
        if (!response) {
          return;
        }

        let branch = response.SOURCE_BRANCH;
        this.deploymentEnvironment = branch;

        if (
          (branch === this.PRODUCTION && this.deployment() !== 'api') ||
          (branch === this.STAGING && this.deployment() !== 'staging') ||
          (branch === this.DEV && this.deployment() !== 'dev') ||
          (branch === this.SPARTA && this.deployment() !== 'sparta')
        ) {
          branch = response.SOURCE_BRANCH + '+' + this.deployment();
          this.isVersionMismatched = true;
        }

        this.versionLabel = branch + ':' + response.SOURCE_COMMIT.substring(0, 5);
      });
    }
  }

  public redirectUrl(): string {
    switch (this.deploymentEnvironment) {
      case this.LOCALHOST:
        return 'http://localhost.swatmobile.io:8080/popup.html';
      case this.DEV:
        return 'https://morpheus.dev.swatrider.com/popup.html';
      case this.STAGING:
      case this.MASTER:
        return 'https://morpheus.staging.swatrider.com/popup.html';
      case this.PRODUCTION:
        return 'https://morpheus.api.swatrider.com/popup.html';
      case this.SPARTA:
        return 'https://morpheus.sparta.swatrider.com/popup.html';
      default:
        return '';
    }
  }

  public baseUrl(): string {
    if (env.domain !== 'localhost') {
      return 'https://' + env.domain + '/v1/bs';
    }
    return 'http://localhost:9004';
  }

  public moaUrl(): string {
    if (env.domain !== 'localhost') {
      return 'https://moa.' + env.domain;
    }
    return 'http://localhost:2345';
  }

  public async createWebsocket<T = any>(routingKey: string) {
    const bacchiServerWebsocketUrl = `wss://${env.domain}/v1/bs/ws`;
    const ticket = await this.getWSTicket();
    const url = `${bacchiServerWebsocketUrl}?routing_key=${encodeURIComponent(routingKey)}&ticket=${ticket}`;

    return webSocket<T>(url);
  }

  public deployment(): string {
    let res = this.baseUrl().replace('https://', '');
    res = res.replace('http://', '');
    res = res.replace(':9004', '');
    res = res.replace('.swatrider.com/v1', '');
    res = res.replace('/bs', '');

    return res;
  }

  public getVersion() {
    return this.apiService.get('src/assets/version.json');
  }

  public computeEstimatedErp(vehicleId: string): Observable<DriverVehicleCost> {
    const path = `${this.baseUrl()}${this.vehiclesUrl}/${vehicleId}/erp`;
    return this.apiService.post(path).pipe(map(res => res.driver_vehicle_cost));
  }

  public getWaypoints(searchString: string, geojson: string, limit: number, page: number) {
    const params: { [key: string]: string | number } = {
      geojson,
      l: limit,
      p: page,
    };
    if (searchString !== null && searchString !== '') {
      params.q = searchString;
    }

    return this.apiService.get(this.baseUrl() + this.waypointsUrl, params).pipe(
      map((response: any) => {
        const collection = new Collection<Waypoint>();
        collection.items = response.waypoints;
        collection.count = response.waypoints_meta.count;
        collection.limit = response.waypoints_meta.limit;
        collection.page = response.waypoints_meta.page;
        collection.q = searchString;
        return collection;
      }),
    );
  }

  public uploadWaypointPhoto(waypointID: string, file: File) {
    const params = {
      filename: file.name,
    };
    const fd = new FormData();
    fd.append('photo', file, file.name);
    return this.apiService.post(this.baseUrl() + this.waypointsUrl + '/' + waypointID + '/photos', params, fd);
  }

  public getWaypointPhotos(waypointID: string) {
    return this.apiService.get(this.baseUrl() + this.waypointsUrl + '/' + waypointID + '/photos').pipe(
      map((response: any) => {
        const collection = new Collection();
        collection.items = response.photos;
        collection.count = collection.items.length;
        collection.limit = 1;
        collection.page = 1;
        return collection;
      }),
    );
  }

  public deleteWaypointPhotos(waypointID: string, photoID: string) {
    return this.apiService.delete(this.baseUrl() + this.waypointsUrl + '/' + waypointID + '/photos/' + photoID);
  }

  public getActiveFixedRoutesCollection(limit: number, page: number) {
    const params = { l: limit, p: page };
    return this.apiService.get(this.baseUrl() + this.fixedRoutesPaginatedUrl, params).pipe(
      map((response: any) => {
        const collection = new Collection();
        collection.items = response.fixed_routes;
        collection.count = response.fixed_routes_meta.count;
        collection.limit = response.fixed_routes_meta.limit;
        collection.page = response.fixed_routes_meta.page;
        return collection;
      }),
    );
  }

  public getGeofences(): Observable<Geofence[]> {
    return this.apiService.get(this.baseUrl() + '/geofences').pipe(map(res => res.geofences));
  }

  public createFixedRouteBooking(userId: string, fixedRouteInstanceId: string, bookingJson: string) {
    return this.apiService.post(
      this.baseUrl() + this.usersUrl + '/' + userId + '/prebook_instances/fixed_route_instance/' + fixedRouteInstanceId,
      bookingJson,
    );
  }

  public getUserSession(): Observable<any> {
    return this.apiService.get(this.baseUrl() + '/user/session').pipe(map(res => res.session));
  }

  public getPickupStateCounts(bacchusIds: string[], states: string[]): Observable<any> {
    const params: { [key: string]: string | number } = {};

    if (bacchusIds && bacchusIds.length > 0) {
      params.bacchus_ids = bacchusIds.join(',');
    }

    if (states && states.length > 0) {
      params.states = states.join(',');
    }

    return this.apiService.get(this.baseUrl() + this.pickupsUrl + '/state_counts', params).pipe(map(res => res.result));
  }

  public getPermissions(): Observable<Permission[]> {
    return this.apiService
      .get(this.baseUrl() + this.accessControlUrl + '/permissions')
      .pipe(map(res => res.permissions));
  }

  public getRolesForOrganization(organizationId: string): Observable<Role[]> {
    return this.apiService
      .get(this.baseUrl() + this.accessControlUrl + '/roles?organization_id=' + organizationId)
      .pipe(map(res => res.roles));
  }

  public getRolePermissionsForRole(roleId: string): Observable<RolePermission[]> {
    return this.apiService
      .get(this.baseUrl() + this.accessControlUrl + '/role_permissions?role_id=' + roleId)
      .pipe(map(res => res.role_permissions));
  }

  public createRole(body: string): Observable<any> {
    return this.apiService
      .post(this.baseUrl() + this.accessControlUrl + '/roles', undefined, body)
      .pipe(map(res => res.role));
  }

  public deleteRole(roleId: string): Observable<any> {
    return this.apiService.delete(this.baseUrl() + this.accessControlUrl + '/roles/' + roleId);
  }

  public updateRole(roleId: string, permissions: string[]): Observable<any> {
    const body = JSON.stringify({
      permission_ids: permissions,
    });
    return this.apiService
      .put(this.baseUrl() + this.accessControlUrl + '/roles/' + roleId + '/role_permissions', undefined, body)
      .pipe(map(res => res.role_permissions));
  }

  public getUsersForOrganization(organizationId: string): Observable<User[]> {
    return this.apiService
      .get(this.baseUrl() + this.accessControlUrl + '/users?organization_id=' + organizationId)
      .pipe(map(res => res.users));
  }

  public getUserRoles(): Observable<UserRole[]> {
    return this.apiService.get(this.baseUrl() + this.accessControlUrl + '/user_roles').pipe(map(res => res.user_roles));
  }

  public createUser(body: string): Observable<any> {
    return this.apiService
      .post(this.baseUrl() + this.accessControlUrl + '/users', undefined, body)
      .pipe(map(res => res.user));
  }

  public getUserRoleForUser(userId: string): Observable<UserRole[]> {
    return this.apiService
      .get(this.baseUrl() + this.accessControlUrl + '/user_roles?user_id=' + userId)
      .pipe(map(res => res.user_roles));
  }

  public updateUserRolesForUser(userId: string, roles: string[]): Observable<any> {
    const body = JSON.stringify({
      role_ids: roles,
    });

    return this.apiService
      .put(this.baseUrl() + this.accessControlUrl + '/users/' + userId + '/user_roles', undefined, body)
      .pipe(map(res => res.user_roles));
  }

  public deleteUser(userId: string): Observable<any> {
    return this.apiService.delete(this.baseUrl() + this.accessControlUrl + '/users/' + userId);
  }

  public getVehicleUtilisedRoutes(vehicleID: string): Observable<any> {
    const path = this.baseUrl() + this.vehiclesUrl + '/' + vehicleID + '/utilised_routes';
    return this.apiService.get(path).pipe(map(res => res.routes));
  }

  public getPickupUtilisedRoutes(pickupID: string): Observable<any> {
    const path = this.baseUrl() + this.pickupsUrl + '/' + pickupID + '/utilised_routes';
    return this.apiService.get(path).pipe(map(res => res.routes));
  }

  public userLogin(email: string, password: string): Observable<any> {
    const body = JSON.stringify({
      email,
      password,
    });
    const path: string = this.baseUrl() + '/login';
    return this.apiNoAuthService.post(path, body).pipe(map((response: any) => response.data));
  }

  public requestOTP(email: string): Observable<any> {
    const body = JSON.stringify({
      email,
    });
    const path: string = this.baseUrl() + '/request_otp';
    return this.apiService.post(path, undefined, body);
  }

  public resetPassword(email: string, otp: string, password: string): Observable<any> {
    const body = JSON.stringify({
      email,
      otp,
      password,
    });
    const path: string = this.baseUrl() + '/reset_password';
    return this.apiService.post(path, undefined, body);
  }

  public updateMorpheusUser(userId: string, accountStatus: string): Observable<any> {
    const body = JSON.stringify({
      account_status: accountStatus,
    });
    const path: string = this.baseUrl() + this.accessControlUrl + '/users/' + userId;
    return this.apiService.put(path, undefined, body);
  }

  public getBreakDownVehicles(zeusIDs: string[], limit: number, page: number) {
    const params = {
      l: limit,
      p: page,
      zeus_ids: zeusIDs.join(','),
    };

    return this.apiService.get(this.baseUrl() + this.incidentsUrl, params).pipe(
      map((response: any) => {
        const collection = new Collection();
        collection.items = response.vehicles;
        collection.count = response.vehicle_meta.count;
        collection.limit = response.vehicle_meta.limit;
        collection.page = response.vehicle_meta.page;
        return collection;
      }),
    );
  }

  public updateBreakDownDetails(vehicleID: string, type: string, details: string) {
    const body = JSON.stringify({
      breakdown_reason_type: type,
      breakdown_reason_detail: details,
    });
    return this.apiService.put(this.baseUrl() + this.incidentsUrl + '/' + vehicleID, undefined, body);
  }

  public getTripRatings(
    zeusIDs: string[],
    startTime: string,
    endTime: string,
    state: string,
    limit: number,
    page: number,
  ): Observable<any> {
    const path: string = this.baseUrl() + '/trip_ratings';
    const params = {
      state, // pickup state: 'completed' or 'cancelled_by_user'
      start_time: startTime,
      end_time: endTime,
      l: limit,
      p: page,
      zeus_ids: zeusIDs.join(','),
    };

    return this.apiService.get(path, params).pipe(
      map((response: any) => {
        const collection = new Collection();
        const data = response;
        collection.items = data.trip_ratings;
        collection.count = data.trip_ratings_meta.count;
        collection.limit = data.trip_ratings_meta.limit;
        collection.page = data.trip_ratings_meta.page;
        return collection;
      }),
    );
  }

  public updateUserProfile(input: { language: string; first_name: string; last_name: string; email: string }) {
    const endpoint = `${this.manageService.manageUrl()}/v2/operator`;

    return this.apiService.put(endpoint, undefined, input);
  }

  private async getWSTicket() {
    // Simple lock to make sure only 1 API call is made at a time
    // Because creating a ticket will invalidate old one immediately
    while (this.isRequestingTicket) {
      await delay(100);
    }

    const isTicketExpired = moment().diff(this.lastTicketUpdated, 'second') >= this.TICKET_EXPIRED_TIME_IN_SECONDS;
    if (this.ticketCache && !isTicketExpired) {
      return this.ticketCache;
    }

    this.isRequestingTicket = true;
    const ticket = await this.apiService
      .get(`${this.baseUrl()}/user/ticket`)
      .pipe(
        map(response => {
          return response.ticket as string;
        }),
      )
      .toPromise();
    this.isRequestingTicket = false;
    this.ticketCache = ticket;
    this.lastTicketUpdated = moment();
    return ticket;
  }
}
