import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/core/services/alert.service';
import { ErrorHelper, ServiceError } from '@app/shared/models/errors';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// TokenInterceptor transforms http requests made by HttpClient from @angular/common/http
// by adding an authorization token header. However, some http calls does not require token
// interceptor, for example: login, reset password, register. To bypass token interceptor for
// some particular requests, we use HttpBackend handler which dispatches requests directly to
// the backend, without going through the interceptor chain. More details can be found here:
// https://github.com/angular/angular/issues/20203#issuecomment-368680437

@Injectable()
export class APINoAuthService {
  private http: HttpClient;

  constructor(handler: HttpBackend, private alertService: AlertService) {
    this.http = new HttpClient(handler);
  }

  public get<T = any>(url: string): Observable<T> {
    return this.http.get(url).pipe(
      map((response: T) => {
        return response;
      }),
    );
  }

  public post<T = any>(url: string, body: any | null): Observable<T> {
    return this.http.post(url, body).pipe(
      map((response: T) => {
        return response;
      }),
      catchError(this.handleError),
    );
  }

  public put<T = any>(url: string, body: any | null): Observable<T> {
    return this.http.put(url, body).pipe(
      map((response: T) => {
        return response;
      }),
      catchError(this.handleError),
    );
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // tslint:disable-next-line: no-console
      console.error('An error occurred:', error.error.message);
    } else {
      const simpleError = ErrorHelper.getSimpleError(error);
      const { title, message } = simpleError;

      this.alertService.error({ title, html: message, noTranslate: true });

      const serviceError: ServiceError = {
        error,
        simpleError,
      };

      // tslint:disable-next-line: no-console
      console.error(serviceError);

      return throwError(serviceError);
    }
    return throwError({ error });
  };
}
