import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Favicons } from '@app/core/favicon.interceptor';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { env } from '../environments/environment';

@Component({
  selector: 'morpheus',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private favicon = env.favicon;
  constructor(private titleService: Title, private favicons: Favicons, private manageAuth: ManageAuthService) {}

  public ngOnInit() {
    this.titleService.setTitle(env.appName);
    this.favicons.activate(this.favicon);
    this.manageAuth.initializeSession();
  }
}
