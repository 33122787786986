import { Injectable } from '@angular/core';
import { APIService } from '@app/core/services/api.service';
import { UserService } from '@app/core/services/user.service';
import { JobHandler, JobResult } from '@app/shared/models/job';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JobService {
  private readonly endpoints = {
    jobById: (jobId: string) => `${this.userService.baseUrl()}/jobs/${jobId}`,
    findJobs: (uniqueId: string, handler: JobHandler) =>
      `${this.userService.baseUrl()}/jobs?unique_id=${uniqueId}&handler=${handler}`,
  };

  constructor(private userService: UserService, private apiService: APIService) {}

  public getJobResult(jobId: string): Observable<JobResult> {
    return this.apiService.get(this.endpoints.jobById(jobId)).pipe(map(response => response.job));
  }

  public findJobs(uniqueId: string, handler: JobHandler): Observable<[JobResult?]> {
    return this.apiService.get(this.endpoints.findJobs(uniqueId, handler)).pipe(map(response => response.jobs));
  }
}
