import { Injectable } from '@angular/core';
import { env } from '@env/environment';

export type Features = typeof env.manageFeatures;
export type Flag = keyof Features;

@Injectable()
export class FeatureFlagService {
  public getAllFlags() {
    return Object.keys(env.manageFeatures);
  }

  public getAllFeatures(): Features {
    return env.manageFeatures;
  }

  public isEnabled(feature: Flag): boolean {
    return env.manageFeatures[feature];
  }
}
