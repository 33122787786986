import { Injectable } from '@angular/core';
import { Organization } from '@app/shared/models/manage-scope';
import { OrganizationConfiguration } from '@app/shared/models/organization-configuration';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { APIService } from './api.service';
import { AuthService } from './auth.service';
import { ManageAuthService } from './manage-auth.service';
import { ManageService } from './manage.service';
import { ScopeService } from './scope.service';
import { UserService } from './user.service';

// TODO replace this service by scopeService when manage is fully integrated
@Injectable()
export class OrganizationService {
  public organizationsCache$: Observable<any[]>;

  private readonly endpoints = {
    organizationsLegacy: `${this.userService.baseUrl()}/access_control/organizations`,
    organizationsManage: `${this.manageService.manageUrl()}/v1/organizations`,
    getConfig: `${this.userService.baseUrl()}/organization_configurations`,
    createConfig: `${this.userService.baseUrl()}/organization_configurations`,
    updateConfig: (configId: string) => `${this.userService.baseUrl()}/organization_configurations/${configId}`,
    deleteConfig: (configId: string) => `${this.userService.baseUrl()}/organization_configurations/${configId}`,
  };

  constructor(
    private authService: AuthService,
    private apiService: APIService,
    private userService: UserService,
    private manageService: ManageService,
    private manageAuthService: ManageAuthService,
    private scopeService: ScopeService,
  ) {}

  public getOrganizations(): Observable<Organization[]> {
    if (this.manageAuthService.isAuthenticated) {
      // Manage backend
      this.organizationsCache$ = this.scopeService.organizations;
    } else if (this.authService.isAuthenticatedWithAuth0()) {
      // Bacchisever backend
      if (!this.authService.hasUserPermissions(['mu_organizations'])) {
        this.organizationsCache$ = of([this.authService.defaultOrg()]).pipe(shareReplay(1));
      } else {
        this.organizationsCache$ = this.apiService.get(this.endpoints.organizationsLegacy).pipe(
          map((data: any) => {
            return data.organizations;
          }),
          shareReplay(1),
        );
      }
    }

    return this.organizationsCache$;
  }

  public getConfigurations(organizationID: string): Observable<OrganizationConfiguration[]> {
    return this.apiService
      .get(this.endpoints.getConfig, { organization_id: organizationID })
      .pipe(map(res => res.configurations));
  }

  public createConfiguration(configuration: Partial<OrganizationConfiguration>) {
    if (configuration.employee_type === 'default') {
      configuration.employee_type = null;
    }
    return this.apiService.post(this.endpoints.createConfig, undefined, configuration);
  }

  public updateConfiguration(configuration: Partial<OrganizationConfiguration>) {
    if (configuration.employee_type === 'default') {
      configuration.employee_type = null;
    }
    return this.apiService.put(this.endpoints.updateConfig(configuration.id), undefined, configuration);
  }

  public deleteConfiguration(configId: string) {
    return this.apiService.delete(this.endpoints.deleteConfig(configId));
  }
}
