import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './core/components/404/not-found.component';
import { LoginComponent } from './login/pages/login/login.component';
import { ManageLoginComponent } from './login/pages/manage-login/manage-login.component';
import { ManageRegisterComponent } from './login/pages/manage-register/manage-register.component';
import { ManageRequestTokenComponent } from './login/pages/manage-request-token/manage-request-token.component';
import { ManageResetPasswordComponent } from './login/pages/manage-reset-password/manage-reset-password.component';
import { ResetPasswordComponent } from './login/pages/reset-password/reset-password.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/services',
    pathMatch: 'full',
  },
  {
    path: 'services',
    loadChildren: () => import('./services/services.module').then(m => m.ServicesModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/passengers.module').then(m => m.PassengersModule),
  },
  {
    path: 'coupons',
    loadChildren: () => import('./coupons/coupons.module').then(m => m.CouponsModule),
  },
  {
    path: 'drivers',
    loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule),
  },
  {
    path: 'manage/drivers',
    loadChildren: () => import('./manage-drivers/manage-drivers.module').then(m => m.ManageDriversModule),
  },
  {
    path: 'waypoints',
    loadChildren: () => import('./waypoints/waypoints.module').then(m => m.WaypointsModule),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'manage/login',
    component: ManageLoginComponent,
  },
  {
    path: 'manage/login/:token',
    component: ManageLoginComponent,
  },
  {
    path: 'manage/services',
    loadChildren: () => import('./manage-services/manage-service.module').then(m => m.ManageServicesModule),
  },
  {
    path: 'manage/booking_codes',
    loadChildren: () => import('./manage-booking-codes/booking-codes.module').then(m => m.BookingCodesModule),
  },
  {
    path: 'access_control',
    loadChildren: () => import('./access-control/access-control.module').then(m => m.AccessControlModule),
  },
  {
    path: 'incidents',
    loadChildren: () => import('./incidents/incidents.module').then(m => m.IncidentsModule),
  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule),
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
  },
  {
    path: 'manage/reset_password',
    component: ManageRequestTokenComponent,
  },
  {
    path: 'manage/register/:token',
    component: ManageRegisterComponent,
  },
  {
    path: 'manage/reset_password/:token',
    component: ManageResetPasswordComponent,
  },
  {
    path: 'ratings',
    loadChildren: () => import('./ratings/ratings.module').then(m => m.RatingsModule),
  },
  {
    path: 'schedules',
    loadChildren: () =>
      import('./recurring-schedules/recurring-schedules.module').then(m => m.RecurringSchedulesModule),
  },
  {
    path: 'fixed_routes',
    loadChildren: () => import('./fixed-routes/fixed-routes.module').then(m => m.FixedRoutesModule),
  },
  {
    path: 'manage',
    loadChildren: () => import('./scopes/scopes.module').then(m => m.ScopesModule),
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
];

export const routing = RouterModule.forRoot(appRoutes);
