import { Injectable } from '@angular/core';
import { APINoAuthService } from '@app/core/services/api-no-auth.service';
import { APIService } from '@app/core/services/api.service';
import { ManageService } from '@app/core/services/manage.service';
import { Session, SessionV2 } from '@app/shared/models/manage-session';
import { env } from '@env/environment';
import { Observable } from 'rxjs';

interface Invitation {
  email: string;
  existing: boolean;
}

@Injectable()
export class ManageLoginService {
  private manageHost = this.manageService.manageUrl();

  private registerEmail: string;
  set email(email: string) {
    this.registerEmail = email;
  }

  get email() {
    return this.registerEmail || '';
  }

  private readonly endpoints = {
    invitation: (token: string) => `${this.manageHost}/v2/invitations/${token}`,
    operator: `${this.manageHost}/v2/operator`,
    session: `${this.manageHost}/v2/sessions`,
    requestToken: `${this.manageHost}/v2/password_resets`,
    resetPassword: (token: string) => `${this.manageHost}/v2/password_resets/${token}`,
  };
  constructor(
    private apiNoAuthService: APINoAuthService,
    private manageService: ManageService,
    private apiService: APIService,
  ) {}

  public login({ email, password }: { email: string; password: string }) {
    const body = { email, password };
    return this.apiNoAuthService.post<SessionV2>(this.endpoints.session, body);
  }

  public requestToken(email: string) {
    const body = { email };
    return this.apiNoAuthService.post(this.endpoints.requestToken, body);
  }

  public resetPassword({
    token,
    email,
    password,
    confirm,
  }: {
    token: string;
    email?: string;
    password: string;
    confirm: string;
  }) {
    const body = { password, email, confirm };
    return this.apiNoAuthService.put(this.endpoints.resetPassword(token), body);
  }

  public registerOperator(
    token: string,
    email: string,
    first: string,
    last: string,
    language: string,
    password: string,
    confirm: string,
  ) {
    const body = {
      email,
      language,
      password,
      confirm,
      first_name: first,
      last_name: last,
      invitation_id: token,
    };
    return this.apiNoAuthService.post(this.endpoints.operator, body);
  }

  public checkInvitation(invitationID: string) {
    return this.apiNoAuthService.get<Invitation>(this.endpoints.invitation(invitationID));
  }

  public acceptInvitation(invitationID: string): Observable<SessionV2> {
    return this.apiService.post(
      this.endpoints.operator,
      undefined,
      { invitation_id: invitationID },
      undefined,
      undefined,
      false,
    );
  }
}
