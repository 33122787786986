// The texts such as ENV_DOMAIN here are placeholder.
// They will be replaced by the start.sh script when docker starts
// Thus these placeholder names have to be unique across the application
export const env = {
  appName: 'Morpheus',
  envName: 'dev',
  domain: 'dev.swatrider.com',
  manageURL: 'https://dev.swatapi.com/manage',
  hostedDomain: 'swatmobile.io',
  allowedCountries: ['sg'],
  defaultCountry: 'sg',
  allowedAuth0Login: false,
  allowedManageLogin: true,
  driverPassword: {
    requireUpperCase: true,
    requireDigit: true,
    requireLowerCase: true,
    minLength: 1,
  },
  userPassword: {
    requireUpperCase: false,
    requireDigit: false,
    requireLowerCase: false,
    minLength: 1,
  },
  favicon: 'swat',
  defaultCurrency: 'sgd',
  manageFeatures: {
    passenger_v2: true,
    user_v2: true,
    driver_schedule_v2: true,
  },
  applicationGroupID: '9ed379da-e986-416e-8af8-3362c1f43a09',
};
