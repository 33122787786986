<div class="container-fluid">
  <div class="container">
    <div class="login-panel">
      <div class="form-container">
        <h4 class="login-title" i18nKey="login.password_reset.title"> </h4>
        <div>
          <div class="input-container">
            <div class="input-group login-input-group">
              <span class="input-group-addon">
                <i class="fa fa-key text-secondary"></i>
              </span>
              <input type="password" [formControl]="passwordControl" class="form-control no-validate"
                placeholder="Password" />
            </div>
            <div *ngIf="passwordControl.invalid && (passwordControl.dirty || passwordControl.touched)"
              class="password-validation-alerts alert alert-danger">
              <div *ngIf="!passwordControl.value || passwordControl.errors.minlength">
                {{ 'validation.password.min_length' | i18next: { num: PASSWORD_MIN_LENGTH } }}
              </div>
            </div>
          </div>

          <div class="input-container">
            <div class="input-group login-input-group">
              <span class="input-group-addon">
                <i class="fa fa-key text-secondary"></i>
              </span>
              <input type="password" [formControl]="repeatPasswordControl" class="form-control no-validate"
                placeholder="Repeat Password" />
            </div>
            <div *ngIf="(repeatPasswordControl.dirty || repeatPasswordControl.touched)">
              <div
                *ngIf="(repeatPasswordControl.invalid && repeatPasswordControl.errors.required) || (resetPasswordForm.invalid && resetPasswordForm.errors && resetPasswordForm.errors.confirm)"
                class="password-validation-alerts alert alert-danger">
                {{ 'validation.password.not_match' | i18next }}
              </div>
            </div>
          </div>

          <button class="btn btn-primary login-button" style="margin-top: 20px;"
            [class.disabled]="isResettingPassword || passwordControl.invalid || repeatPasswordControl.invalid"
            (click)="resetPassword()">
            <div *ngIf="!isResettingPassword" i18nKey="button.reset_password"></div>
            <div *ngIf="isResettingPassword" i18nKey="login.password_reset.resetting"></div>
          </button>
        </div>

      </div>

      <div class="forgot-password-container">
        <a class="forgot-password-link" [routerLink]="['/manage/login']" i18nKey="button.cancel"></a>
      </div>
    </div>
  </div>
</div>