import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { empty, Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { ManageAuthService } from './services/manage-auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public loginRoute = '/login';
  public bsResetPasswordRoute = '/bs/reset_password';
  public requestOTPRoute = '/request_otp';
  public versionRoute = 'src/assets/version.json';

  constructor(private authService: AuthService, private manageAuthService: ManageAuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIgnore(req)) {
      return next.handle(req);
    }

    if (this.manageAuthService.isAuthenticated) {
      return next.handle(req.clone({ setHeaders: { 'x-st-token': this.manageAuthService.token } }));
    }

    return empty();
  }

  public shouldIgnore(req: HttpRequest<any>): boolean {
    return (
      req.url.indexOf(this.loginRoute) !== -1 ||
      req.url.indexOf(this.requestOTPRoute) !== -1 ||
      req.url.indexOf(this.versionRoute) !== -1 ||
      req.url.indexOf(this.bsResetPasswordRoute) !== -1
    );
  }
}
