import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertService } from '@app/core/services/alert.service';
import { AuthService } from '@app/core/services/auth.service';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ErrorHelper } from '@app/shared/models/errors';
import { env } from '@env/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import sweetalert2 from 'sweetalert2';

// tslint:disable: no-console
@Injectable()
export class APIService {
  public responseType: 'arraybuffer' | 'blob' | 'json' | 'text';
  constructor(
    private http: HttpClient,
    private manageAuthService: ManageAuthService,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  public handleErrorForGetRequest = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // tslint:disable-next-line: no-debugger
      if (this.isTokenRelatedError(error)) {
        if (env.allowedManageLogin) {
          this.manageAuthService.forceUserLogout$.next();
        } else {
          this.authService.logoutAuth0();
        }
      }

      const simpleError = ErrorHelper.getSimpleError(error);
      console.error(simpleError);
    }
    return throwError(error);
  };

  public handleErrorForPostRequest = (error: HttpErrorResponse, showError = true) => {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      if (this.isTokenRelatedError(error)) {
        if (env.allowedManageLogin) {
          this.manageAuthService.forceUserLogout$.next();
        } else {
          this.authService.logoutAuth0();
        }
      }

      const simpleError = ErrorHelper.getSimpleError(error);
      const { title, message } = simpleError;

      if (showError) {
        this.alertService.error({ title, html: message, noTranslate: true });
      }

      console.error(error, simpleError);
    }
    return throwError(error);
  };

  public get(
    url: string,
    params: any = {},
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    headers: HttpHeaders = new HttpHeaders(),
  ) {
    return this.http
      .get(url, {
        params,
        headers,
        responseType: responseType as 'json',
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.body.data) {
            return response.body.data;
          }
          return response.body;
        }),
        catchError(this.handleErrorForGetRequest),
      );
  }

  public put(
    url: string,
    params: any = {},
    body?: any,
    headers: HttpHeaders = new HttpHeaders(),
    message = 'Successful!',
    showError = true,
  ) {
    return this.http.put(url, body, { params, headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        sweetalert2({
          text: message,
          type: 'success',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2000,
          backdrop: false,
        });

        switch (response.status) {
          case 200:
          case 201:
            if (response.body.data) {
              return response.body.data;
            }
            return response.body;
          default:
            return;
        }
      }),
      catchError(err => this.handleErrorForPostRequest(err, showError)),
    );
  }

  public post(
    url: string,
    params: any = {},
    body?: any,
    headers: HttpHeaders = new HttpHeaders(),
    message = 'Successful!',
    showError = true,
  ) {
    return this.http.post(url, body, { params, headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (message.length > 0) {
          sweetalert2({
            text: message,
            type: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2500,
            backdrop: false,
          });
        }

        switch (response.status) {
          case 200:
          case 201:
            if (response.body.data) {
              return response.body.data;
            }
            return response.body;
          default:
            return;
        }
      }),
      catchError(err => this.handleErrorForPostRequest(err, showError)),
    );
  }

  public delete(url: string, params: any = {}, headers?: HttpHeaders, message = 'Successful!', showError = true) {
    return this.http.delete(url, { params, headers, observe: 'response' }).pipe(
      map((response: HttpResponse<any>) => {
        if (message.length > 0) {
          sweetalert2({
            text: message,
            type: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2000,
            backdrop: false,
          });
        }

        switch (response.status) {
          case 200:
          case 201:
            if (response.body.data) {
              return response.body.data;
            }
            return response.body;
          default:
            return;
        }
      }),
      catchError(err => this.handleErrorForPostRequest(err, showError)),
    );
  }

  private isTokenRelatedError(error: HttpErrorResponse) {
    const isTokenExpired = error.status === 401;
    const isTokenInvalid = error.status === 403 && error.error?.code === 1007;

    return isTokenExpired || isTokenInvalid;
  }
}
