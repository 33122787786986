import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Location } from '../../shared/models/user';
import { APINoAuthService } from './api-no-auth.service';

declare var moment: any;

@Injectable()
export class UtilityService {
  constructor(private apiNoAuthService: APINoAuthService) {}

  public getLatLon(address: string) {
    // tslint:disable-next-line: max-line-length
    const url = `https://developers.onemap.sg/commonapi/search?searchVal=${address}&returnGeom=Y&getAddrDetails=Y&pageNum=1`;
    return this.apiNoAuthService.get(url).pipe(
      map(response => {
        return response.results;
      }),
    );
  }

  public updateGeocodeLocation(location: Location) {
    this.getLatLon(location.name).subscribe(results => {
      if (results.length > 0) {
        location.lat = parseFloat(results[0].LATITUDE);
        location.lon = parseFloat(results[0].LONGITUDE);
      } else {
        location.lat = 0;
        location.lon = 0;
      }
    });
  }

  // this copies a string to clipboard
  public copyToClipBoard(str: string) {
    const aux = document.createElement('input'); // Create an auxiliary hidden input
    const copyContent = str;
    aux.setAttribute('value', copyContent);
    document.body.appendChild(aux); // Append the aux input to the body
    aux.select(); // Highlight the content
    document.execCommand('copy'); // Execute the copy command
    document.body.removeChild(aux); // Remove the input from the body
  }

  public getTimeLapseInSeconds(targetDate: string): number {
    const now: Date = new Date();
    const target: Date = new Date(targetDate);
    return (now.getTime() - target.getTime()) / 1000;
  }

  // this convert a time lapse from targetDate until NOW into hh:mm:ss
  public formatTimeLapse(targetDate: string): string {
    return this.formatDuration(this.getTimeLapseInSeconds(targetDate));
  }

  // this convert a duration in seconds into hh:mm:ss
  public formatDuration(durationSeconds: number): string {
    const hours = Math.floor(durationSeconds / 3600);
    const remain = durationSeconds - hours * 3600;
    const minutes = Math.floor(remain / 60);
    const seconds = Math.floor(remain - 60 * minutes);

    let timeStr = '';

    if (hours > 0) {
      timeStr = hours.toString() + ':';
    }

    if (minutes < 10) {
      timeStr = timeStr + '0';
    }
    timeStr = timeStr + minutes.toString() + ':';

    if (seconds < 10) {
      timeStr = timeStr + '0';
    }
    timeStr = timeStr + seconds.toString();

    return timeStr;
  }

  public filterZeroTime(dateString: string, formatStr: string): string {
    return dateString === '0001-01-01T00:00:00Z' ? '-' : moment(dateString).format(formatStr);
  }

  public parseDate(date: string | Date): Date {
    // Setup correct Date object for datetime component for start time
    const time = moment(date);
    return new Date(time.year(), time.month(), time.date(), time.hours(), time.minutes(), 0, 0);
  }

  public downloadBlob(blob: Blob, fileName: string) {
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    a.click();
  }
}
