import { EventEmitter, Injectable } from '@angular/core';
import { Auth0Session } from '@app/shared/models/auth0-session';
import { TabPermission } from '@app/shared/models/manage-permission';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  public redirectUrl: string;
  public onAuth0UserLogout = new EventEmitter();

  private jwtHelper: JwtHelperService = new JwtHelperService();
  private tabs: TabPermission[] = [
    { permission: 'm_services', route: ['/services'] },
    { permission: 'm_live', route: ['/services'] },
    { permission: 'm_drivers', route: ['/drivers'] },
    { permission: 'm_passengers', route: ['/users'] },
    { permission: 'm_monthly_passes', route: ['/schedules'] },
    { permission: 'm_incidents', route: ['/incidents'] },
    { permission: 'm_coupons', route: ['/coupons'] },
    { permission: 'm_waypoints', route: ['/waypoints'] },
    { permission: 'm_users', route: ['/access_control'] },
    { permission: 'm_ratings', route: ['/ratings'] },
    { permission: 'm_app_man', route: ['/app', 'versions'] },
  ];

  private sessionContextCache: Auth0Session;
  get session(): Auth0Session {
    if (!this.sessionContextCache) {
      this.sessionContextCache = JSON.parse(localStorage.getItem('auth0_session'));
    }
    return this.sessionContextCache;
  }

  public setAuth0Session(authResult: any) {
    localStorage.setItem('auth0_jwt', authResult.idToken);
  }

  public setLoginSession(loginSession: any) {
    localStorage.setItem('auth0_jwt', loginSession.id_token);
  }

  public setSessionContext(sessionContext: Auth0Session) {
    // Remove manage session
    localStorage.removeItem('session');
    localStorage.setItem('auth0_session', JSON.stringify(sessionContext));
    this.sessionContextCache = sessionContext;
  }

  public logoutAuth0() {
    localStorage.clear();
    this.sessionContextCache = undefined;
    this.onAuth0UserLogout.emit();
  }

  public isAuthenticatedWithAuth0() {
    if (localStorage.getItem('auth0_jwt')) {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem('auth0_jwt'));
    }
    return false;
  }

  public hasSessionContext() {
    return !!this.session;
  }

  public hasUserPermissions(permissions: string[]): boolean {
    // return true;
    if (!this.hasSessionContext() || !permissions) {
      return false;
    }

    if (permissions.length === 0) {
      return true;
    }

    return permissions.every((permission: string) => {
      const found = this.session.permissions.find((p: any) => p.name === permission);
      return found !== undefined;
    });
  }

  public currentUser() {
    if (this.session) {
      return this.session.user;
    }
    return undefined;
  }

  public currentUserName() {
    if (this.hasSessionContext()) {
      return this.session.user.name;
    }
    return '';
  }

  public defaultOrg() {
    if (this.hasSessionContext()) {
      return this.session.organization;
    }
  }

  public getOrgName() {
    if (this.hasSessionContext()) {
      return this.session.organization.name;
    }
    return '';
  }

  public getToken() {
    return this.isAuthenticatedWithAuth0() ? localStorage.getItem('auth0_jwt') : '';
  }

  public firstAccessibleTab(): string[] {
    const tab = this.tabs.find((t: TabPermission) => this.hasUserPermissions([t.permission]));
    return tab ? tab.route : ['/404'];
  }
}
