import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

export type Language = 'en-us' | 'ja-jp' | 'th-th' | 'id-id';
export const DEFAULT_LANGUAGE: Language = 'en-us';

/// This service is store current language, global date/time format based on selected language
@Injectable()
export class LanguageService {
  private language: Language = 'en-us';
  get currentLanguage(): Language {
    return this.language;
  }

  // Angular Time Format
  private fullDateFormats: Record<Language, string> = {
    'en-us': 'EEE, dd MMM yyyy',
    'ja-jp': 'yyyy年MMMdd日(EEE)',
    'th-th': 'EEE, dd MMM yyyy',
    'id-id': 'EEE, dd MMM yyyy',
  };

  // Angular Time Format
  private dateTimeFormats: Record<Language, string> = {
    'en-us': 'dd MMM yyyy, HH:mm',
    'ja-jp': 'yyyy年MMMdd日(EEE), HH:mm',
    'th-th': 'dd MMM yyyy, HH:mm',
    'id-id': 'dd MMM yyyy, HH:mm',
  };

  // Moment Time Format
  private timestampFormats: Record<Language, string> = {
    'en-us': 'DD MMM YYYY, HH:mm',
    'ja-jp': 'YYYY年MM月DD日, HH:mm',
    'th-th': 'DD MMM YYYY, HH:mm',
    'id-id': 'DD MMM YYYY, HH:mm',
  };

  constructor(@Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService) {
    this.language = this.i18NextService.language as Language;
  }

  public fullDateFormat(): string {
    return this.fullDateFormats[this.language];
  }

  public dateTimeFormat(): string {
    return this.dateTimeFormats[this.language];
  }

  public timestampFormat(): string {
    return this.timestampFormats[this.language];
  }

  public getAllSupportedLanguages(): Array<{ name: string; value: Language }> {
    return [
      { name: 'English (United States)', value: 'en-us' },
      { name: '日本語 (Japanese)', value: 'ja-jp' },
      { name: 'ไทย (Thai)', value: 'th-th' },
      { name: 'Bahasa Indonesia (Indonesian)', value: 'id-id' },
    ];
  }
}
