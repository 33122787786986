import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { env } from '../../../../environments/environment';
import { UserService } from '../../../core/services/user.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login.scss'],
})
export class ResetPasswordComponent implements OnInit, AfterViewInit {
  public email: string = null;
  public mobile: string = null;
  public otp: string = null;
  public resetPasswordErrorMessage: string = null;
  public resetPasswordSuccessMessage: string = null;
  public isGeneratingOTP: boolean = false;
  public isResettingPassword: boolean = false;
  public resetPasswordForm: FormGroup;
  public passwordControl: FormControl;
  public repeatPasswordControl: FormControl;
  public timer: NodeJS.Timer = null;
  public otpTimeLeft: number = 0;

  private readonly duration = 120; // 120 seconds
  private minLength = env.userPassword.minLength;

  constructor(private userService: UserService, private formBuilder: FormBuilder) {}

  public ngOnInit() {
    this.setupResetPasswordForm();
  }

  public ngAfterViewInit() {
    this.resetCredentials();
  }

  public ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public setupResetPasswordForm() {
    this.passwordControl = this.formBuilder.control('', [
      Validators.required,
      Validators.minLength(this.minLength),
      this.upperCaseValidator(),
      this.digitValidator(),
      this.lowerValidator(),
    ]);

    this.repeatPasswordControl = this.formBuilder.control('', [Validators.required]);
    this.resetPasswordForm = new FormGroup(
      {
        passwordControl: this.passwordControl,
        repeatPasswordControl: this.repeatPasswordControl,
      },
      this.confirmPasswordValidator(),
    );
  }

  public resetCredentials() {
    this.email = null;
    this.mobile = null;
    this.otp = null;
    this.resetPasswordErrorMessage = null;
    this.resetPasswordSuccessMessage = null;
    this.isGeneratingOTP = false;
    this.isResettingPassword = false;
    this.setupResetPasswordForm();
  }

  public resetPasswordAndOTPFields() {
    this.otp = null;
    this.isResettingPassword = false;
    this.setupResetPasswordForm();
  }

  public upperCaseValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!env.userPassword.requireUpperCase) {
        return null;
      }
      if (!control.value) {
        return { upper: { value: control.value } };
      }
      const valid = !!control.value.match(/^(?=.*[A-Z])/);
      return valid ? null : { upper: { value: control.value } };
    };
  }

  public lowerValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!env.driverPassword.requireLowerCase) {
        return null;
      }
      if (!control.value) {
        return { lower: { value: control.value } };
      }
      const valid = !!control.value.match(/^(?=.*[a-z])/);
      return valid ? null : { lower: { value: control.value } };
    };
  }

  public digitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!env.userPassword.requireDigit) {
        return null;
      }
      if (!control.value) {
        return { digit: { value: control.value } };
      }
      const valid = !!control.value.match(/^(?=.*\d)/);
      return valid ? null : { digit: { value: control.value } };
    };
  }

  public confirmPasswordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password = control.get('passwordControl').value;
      const repeatedPassword = control.get('repeatPasswordControl').value;
      const match = password === repeatedPassword && password !== '' && password !== null;
      return match ? null : { confirm: { value: control.value } };
    };
  }

  public startOTPCountdown() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.otpTimeLeft = this.duration;
    this.timer = setInterval(() => {
      this.otpTimeLeft -= 1;
      if (this.otpTimeLeft <= 0) {
        this.stopOTPCountdown();
      }
    }, 1000);
  }

  public stopOTPCountdown() {
    if (this.timer) {
      clearInterval(this.timer);
      this.otpTimeLeft = 0;
    }
  }

  public requestOTP() {
    this.resetPasswordErrorMessage = null;

    if (!this.email || this.email.length === 0) {
      this.resetPasswordErrorMessage = 'Please enter your email address.';
      return;
    }

    this.isGeneratingOTP = true;

    this.userService.requestOTP(this.email).subscribe(
      (response: any) => {
        this.mobile = response.mobile;
        this.startOTPCountdown();
        this.isGeneratingOTP = false;
      },
      (err: any) => {
        this.resetPasswordErrorMessage = 'Something went wrong. Please try again.';
        this.isGeneratingOTP = false;
      },
    );
  }

  public resetPassword() {
    this.resetPasswordErrorMessage = null;
    if (!this.otp || this.otp.length !== 6) {
      this.resetPasswordErrorMessage = 'Please enter a valid OTP.';
      return;
    }

    this.isResettingPassword = true;
    const password = this.passwordControl.value || '';
    this.userService.resetPassword(this.email, this.otp, password).subscribe(
      (response: any) => {
        this.resetCredentials();
        this.resetPasswordSuccessMessage =
          'Your password has been changed. You can now log in with your new credentials.';
      },
      (err: any) => {
        if (err.code) {
          switch (err.code) {
            case this.userService.OTP_VALIDATION_FAILED_CODE:
              this.resetPasswordErrorMessage =
                'Password Reset Failed: You have entered an invalid OTP. Please try again.';
              break;
            case this.userService.PASSWORD_RECENTLY_USED_CODE:
              this.resetPasswordErrorMessage =
                // tslint:disable-next-line:max-line-length
                'Password Reset Failed: You have recently used this password. Please use a password different from your last 3 passwords.';
              break;
            case this.userService.PASSWORD_TOO_WEAK_CODE:
              const requireLowerCase = env.userPassword.requireLowerCase ? ', have at least 1 lowercase letter' : '';
              const requireUpperCase = env.userPassword.requireUpperCase ? ', have at least 1 uppercase letter' : '';
              const requireDigit = env.userPassword.requireDigit ? ', have at least 1 number' : '';
              this.resetPasswordErrorMessage =
                // tslint:disable-next-line:max-line-length
                `Password Reset Failed: Your password is too weak. Password must meet the requirments: at least ${this.minLength} characters long ${requireLowerCase} ${requireUpperCase} ${requireDigit}`;
              break;
            case this.userService.PASSWORD_CONTAINS_USER_INFO_CODE:
              this.resetPasswordErrorMessage =
                // tslint:disable-next-line:max-line-length
                'Password Reset Failed: Your password contains your user information. Please use a password that does not contain any user information.';
              break;
          }
        } else {
          this.resetPasswordErrorMessage = 'Something went wrong. Please try again.';
        }
        this.resetPasswordAndOTPFields();
      },
    );
  }
}
