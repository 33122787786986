import { Component, OnDestroy, OnInit } from '@angular/core';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { ScopeService } from '@app/core/services/scope.service';
import { PermissionLabel } from '@app/shared/models/manage-permission';
import { env } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  public label = PermissionLabel;
  public params: { projectId?: string; organizationId?: string } = {
    projectId: new URLSearchParams(window.location.search).get('projectId'),
    organizationId: new URLSearchParams(window.location.search).get('organizationId'),
  };
  public sessionInitialized = false;

  private projectChangedSub: Subscription;
  private orgChangedSub: Subscription;

  constructor(public manageAuth: ManageAuthService, private scopeService: ScopeService) {}

  public ngOnDestroy() {
    this.projectChangedSub.unsubscribe();
    this.orgChangedSub.unsubscribe();
  }
  public async ngOnInit() {
    this.projectChangedSub = this.scopeService.projectChanged$.subscribe(project => {
      if (project) {
        this.params.projectId = project.id;
      }
    });

    this.orgChangedSub = this.scopeService.organizationChanged$.subscribe(organization => {
      if (organization) {
        this.params.organizationId = organization.id;
      }
    });
    await this.manageAuth.sessionInitialized$.toPromise();
    this.sessionInitialized = true;
  }

  public getAppName(): string {
    return env.appName;
  }

  public isAuthenticated() {
    return this.manageAuth.isAuthenticated;
  }

  public queryString() {
    return `projectId=${this.params.projectId}&organizationId=${this.params.organizationId}`;
  }
}
