import { Injectable } from '@angular/core';
import { env } from '@env/environment';

// Temporary solution to prevent circular dependencies between interceptors and user.service
@Injectable()
export class ManageService {
  public manageUrl(): string {
    return env.manageURL;
  }
}
