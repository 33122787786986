import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { ManageAuthService } from '@app/core/services/manage-auth.service';
import { PermissionLabel } from '@app/shared/models/manage-permission';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private manageAuth: ManageAuthService) {}

  public canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.checkActivated(route);
  }

  private async checkActivated(route: ActivatedRouteSnapshot) {
    // Manage Permission
    if (!this.manageAuth.isAuthenticated) {
      location.href = `/v2/login?redirect_url=${encodeURIComponent(window.location.href)}`;
      return false;
    }

    await this.manageAuth.sessionInitialized$.toPromise();

    const permissions: string[] = route.data.permissions;

    if (permissions) {
      const canAccess = this.manageAuth.hasPermissions([...permissions, PermissionLabel.SCOPE_ADMIN]);
      if (!canAccess) {
        const tab = this.manageAuth.firstAccessibleTab();
        if (!tab) {
          this.router.navigate(['/404']);
          return;
        }

        const isLocal = location.host.includes('localhost'); // Prevent loop redirect issue in local
        if (tab.v2_route && !isLocal) {
          location.href = `${tab.v2_route}`;
        } else {
          this.router.navigate(tab.route);
        }
      }
    }

    return true;
  }
}
