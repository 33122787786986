import { registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { defaultInterpolationFormat, I18NEXT_SERVICE, I18NextModule, ITranslationService } from 'angular-i18next';
import LanguageDetector, { CustomDetector } from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import localIndo from '@angular/common/locales/id';
import localJap from '@angular/common/locales/ja';
import localThai from '@angular/common/locales/th';
import { SessionV2 } from '@app/shared/models/manage-session';

// MUST READ: Most of the time, you can inject i18NextService
// However, if you want to access i18NextService.language, you need to inject using token
// @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
// If you use i18NextService, it might not be initialized yet so i18NextService.language is empty
// Reason: https://github.com/Romanchuk/angular-i18next/issues/27#issuecomment-471571937

const sessionLanguageDetector: CustomDetector = {
  name: 'session-language',
  lookup() {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (storedLanguage && storedLanguage.length > 0) {
      return storedLanguage;
    }

    const defaultLanguage = 'en-us';
    const encodedSession = localStorage.getItem('session');
    if (!encodedSession) {
      return defaultLanguage;
    }
    const session = JSON.parse(decodeURI(window.atob(encodedSession))) as SessionV2;
    return session.language || defaultLanguage;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(sessionLanguageDetector);

export function appInit(i18next: ITranslationService) {
  registerLocaleData(localJap);
  registerLocaleData(localThai);
  registerLocaleData(localIndo);

  return () =>
    i18next
      .use(XHR)
      .use(languageDetector)
      .init({
        whitelist: ['en-us', 'ja-jp', 'id-id', 'th-th'],
        load: 'currentOnly',
        fallbackLng: 'en-us',
        lowerCaseLng: true,
        debug: false,
        returnEmptyString: false,
        ns: ['translation'],
        interpolation: {
          format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
        },
        backend: {
          loadPath: `src/assets/locales/{{ns}}.{{lng}}.json?q=${Math.random()}`, // Clear cache
        },
        detection: {
          order: ['session-language', 'querystring'],
          lookupQuerystring: 'lng',
          caches: ['localStorage'],
          cookieMinutes: 60 * 24 * 7, // 7 days
        },
      });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
